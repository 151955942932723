@import 'vars';
@import 'typography';
@import 'custom';
@import 'utils';
@import '../assets/plugins/datatables/css/jquery.datatables.min.css';
@import '../assets/plugins/datatables/css/jquery.datatables_themeroller.css';
@import '../assets/plugins/pace-master/themes/blue/pace-theme-flash.css';
@import "../assets/plugins/uniform/css/uniform.default.min.css";
@import "../assets/plugins/fontawesome/css/font-awesome.css";
@import "../assets/plugins/line-icons/simple-line-icons.css";
@import "../assets/plugins/offcanvasmenueffects/css/menu_cornerbox.css";
@import "../assets/plugins/waves/waves.min.css";
@import "../assets/plugins/switchery/switchery.min.css";
@import "../assets/plugins/3d-bold-navigation/css/style.css";
@import "../assets/plugins/slidepushmenus/css/component.css";
@import "../assets/plugins/weather-icons-master/css/weather-icons.min.css";
@import "../assets/plugins/metrojs/MetroJs.min.css";
@import "../assets/plugins/toastr/toastr.min.css";
@import "../assets/plugins/bootstrap/css/bootstrap.min.css";
@import "../assets/plugins/animatecss/animate.css";
@import "themes/blue";
@import 'chartist';
@import 'datepicker';
@import 'user_list';
@import 'styles';
@import 'analyse';
@import 'clients_lists';
@import 'objectifs';

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.puces {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.puce {
    margin-top: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
}

.carre {
    height: 15px;
    width: 15px;
    border-radius: 4px;
    margin-bottom: 2px;
}

.text-pie {
    margin-left: -6px;
    font-size: 9px;
    float: left;
    padding-right: 6px;
}

.ct-series .ct-slice-donut {
    stroke-width: 40px !important;
}

.table-chart {
    position: absolute!important;
    top: 50%;
    left: 36%;
    td {
        text-align: center;
    }
}

.ct-series-a {
    .ct-slice-donut {
        stroke: #2c844a !important;
    }
}

.ct-series-b {
    .ct-slice-donut {
        stroke: #68bbf3 !important;
    }
}

.ct-series-c {
    .ct-slice-donut {
        stroke: #e6922d !important;
    }
}

.ct-series-d {
    .ct-slice-donut {
        stroke: #d17905 !important;
    }
}

.ct-series-e {
    .ct-slice-donut {
        stroke: #453d3f !important;
    }
}

.color-0 {
    background: #2c844a;
}

.color-1 {
    background: #68bbf3;
}

.color-2 {
    background: #e6922d;
}

.color-3 {
    background: #d17905;
}

.color-4 {
    background: #453d3f;
}

.color-5 {
    background: #59922b;
}

.color-6 {
    background: #0544d3;
}

.color-7 {
    background: #6b0392;
}

.color-8 {
    background: #f05b4f;
}

.color-9 {
    background: #dda458;
}

.color-10 {
    background: #eacf7d;
}

.color-11 {
    background: #86797d;
}

.color-12 {
    background: #b2c326;
}

.color-13 {
    background: #6188e2;
}

.color-14 {
    background: #a748ca;
}

.bg-yellow {
    background: yellow;
}

.bg-blue {
    background: blue;
}

.rootings {
    td {
        border: 1px dotted #ccc;
    }
    .table_routings {
        tbody,
        tfoot {
            tr {
                &>td,
                &>th {
                    padding: 0 !important;
                    input {
                        margin-left: auto;
                        margin-right: auto;
                        width: 12px;
                    }
                }
            }
        }
    }
    input[type=checkbox],
    input[type=radio] {
        margin-top: 2px;
        height: 13px;
    }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    padding-right: 4px;
    padding-left: 4px;
}

.panel {
    margin-bottom: 7px;
    border-radius: 8px !important;
    .panel-heading {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.panel-info {
    background: #12afcb;
    .info-box-title {
        color: white !important;
    }
    .info-box-stats p {
        color: white;
        font-weight: bold;
    }
    i {
        color: black !important;
    }
}

.panel-success {
    background: $green;
    color: white;
}

.panel-danger {
    background: $red;
    color: white;
}

.table-analyses {
    width: 100%;
    thead {
        th {
            text-align: center;
            border: 1px solid white;
        }
    }
    tr {
        width: 100%;
        height: 30px;
        line-height: 30px;
        margin-bottom: 3px;
        position: relative;
        td {
            background: #f1f4f9;
            padding: 0 5px;
            border: 2px solid white;
            border-radius: 4px;
            text-align: center;
            &:first-child {
                font-weight: bold;
                text-align: left;
            }
        }
    }
    &.table-kpi {
        //#f1f4f9
        thead {
            tr:first-child {
                th {
                    &:nth-child(2),
                    &:nth-child(3) {
                        background: #f1f4f9;
                    }
                    &:nth-child(3) {
                        color: white;
                        background: rgb(177, 182, 189);
                    }
                }
            }
            th {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22),
                &:nth-child(23) {
                    background: #f1f4f9
                }
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17) {
                    color: white!important;
                    background: rgb(177, 182, 189)!important;
                }
            }
        }
        tr {
            td {
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17) {
                    color: white;
                    background: rgb(177, 182, 189);
                }
            }
        }
    }

    &.table-kpi-analyse-4 {
        //#f1f4f9
        thead {
            tr:first-child {
                th {
                    &:nth-child(2),
                    &:nth-child(3) {
                        background: #f1f4f9;
                    }
                    &:nth-child(3) {
                        color: white;
                        background: rgb(177, 182, 189);
                    }
                }
            }
            th {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20)
                /*&:nth-child(21),
                &:nth-child(22),
                &:nth-child(23)*/ {
                    background: #f1f4f9
                }
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14){
                    color: white!important;
                    background: rgb(177, 182, 189)!important;
                }
            }
        }
        tr {
            td {
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14)
                /*,
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17)*/ {
                    color: white;
                    background: rgb(177, 182, 189);
                }
            }
        }
    }
    &.table-kpi-commande {
        //#f1f4f9
        thead {
            tr:first-child {
                th {
                    &:nth-child(2),
                    &:nth-child(3) {
                        background: #f1f4f9;
                    }
                    &:nth-child(3) {
                        color: white;
                        background: rgb(177, 182, 189);
                    }
                }
            }
            th {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22),
                &:nth-child(23),
                &:nth-child(24),
                &:nth-child(25) {
                    background: #f1f4f9
                }
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17) {
                    color: white;
                    background: rgb(177, 182, 189)
                }
            }
        }
        tr {
            td {
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17) {
                    color: white;
                    background: rgb(177, 182, 189);
                }
            }
        }
    }

    &.table-kpi-commande-rapport {
        //#f1f4f9
        thead {
            tr:first-child {
                th {
                    &:nth-child(2),
                    &:nth-child(3) {
                        background: #f1f4f9;
                    }
                    &:nth-child(3) {
                        color: white;
                        background: rgb(177, 182, 189);
                    }
                }
            }
            th {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),

                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22),
                &:nth-child(23),
                &:nth-child(24),
                &:nth-child(25) {
                    background: #f1f4f9
                }
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14)
                 {
                    color: white;
                    background: rgb(177, 182, 189)
                }
            }
        }
        tr {
            td {
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14) {
                    color: white;
                    background: rgb(177, 182, 189);
                }
            }
        }
    }

    &.table-kpi-gouvernorate {
        //#f1f4f9
        thead {
            tr:first-child {
                th {
                    &:nth-child(2),
                    &:nth-child(4) {
                        background: #f1f4f9;
                    }
                    &:nth-child(3) {
                        color: white;
                        background: rgb(177, 182, 189);
                    }
                }
            }
            th {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16) {
                    background: #f1f4f9
                }
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11) {
                    color: white;
                    background: rgb(177, 182, 189)
                }
            }
        }
        tr {
            td {
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11) {
                    color: white;
                    background: rgb(177, 182, 189);
                }
            }
        }
    }
    &.table-kpi-total-visite {
        //#f1f4f9
        thead {
            tr:first-child {
                th {
                    &:nth-child(2),
                    &:nth-child(4) {
                        background: #f1f4f9;
                    }
                    &:nth-child(3) {
                        background: #f1f4f9;
                    }
                }
            }
            th {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16) {
                    background: #f1f4f9
                }
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11) {
                    background: #f1f4f9;
                }
            }
        }
        tr {
            td {
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11) {
                    background: #f1f4f9;
                }
            }
        }
    }
}

.ct-chart-line .ct-horizontal {
    display: none;
    &.ct-end {
        display: block;
    }
}

.ct-chart-line .ct-line {
    stroke-width: 1px;
}

.ct-chart-line .ct-point {
    stroke-width: 5px;
}

.pos-bottom-right {
    position: absolute;
    bottom: 0;
    right: 10px;
}

.pos-bottom-left {
    position: absolute;
    bottom: 0;
    left: 10px;
}

.text-xs {
    font-size: 11px !important;
}

.navbar {
    border: none !important;
    border-radius: 0 !important;
}

.no-padder {
    padding: 0 !important;
}

.ct-chart .ct-label {
    font-size: 1.2rem;
    fill: white;
}

.jvectormap-container {
    height: 400px !important;
    background: #a3ccff !important;
}

.dashboard {
    .jvectormap-container {
        height: 800px !important;
        background: #f1f4f9 !important;
        path {
            stroke-width: 1px;
            stroke: #2c363e;
        }
    }
}

.box-client {
    position: absolute;
    z-index: 99;
    padding: 40px 15px 15px 15px;
    background: rgba(255, 255, 255, 0.9);
    color: black;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0px;
    overflow: scroll;
    .btn-close {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        background: red;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        font-size: 1.5em;
        color: white;
        cursor: pointer;
    }
}

// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
.jqvmap-label {
    position: absolute;
    display: none;
    border-radius: 3px;
    background: #292929;
    color: white;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px;
    pointer-events: none;
}

.jqvmap-pin {
    pointer-events: none;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
    display: none;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    position: absolute;
    left: 10px;
    border-radius: 3px;
    background: #000000;
    padding: 3px;
    color: white;
    width: 10px;
    height: 10px;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
}

.jqvmap-zoomin {
    top: 10px;
}

.jqvmap-zoomout {
    top: 30px;
}

.jqvmap-region {
    cursor: pointer;
}

.jqvmap-ajax_response {
    width: 100%;
    height: 500px;
}

.slimscroll {
    overflow: scroll;
}


/*::-webkit-scrollbar {
    width: 2px;
}*/

.m {
    margin: 10px;
}

.w-md {
    width: 130px;
}

.page-inner {
    min-height: 100vh;
}

.sidebar-profile-image {
    img {
        background: white;
    }
}

.m-t-n-md {
    margin-top: -8px;
}

.m-b-md {
    margin-bottom: 15px;
}

.modal {
    text-align: center;
    padding: 0!important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-backdrop {
    z-index: 0!important;
}

// map pin
.pin {
    .pin {
        width: 25px;
        height: 25px;
        border-radius: 50% 50% 50% 0;
        background: #12afcb;
        position: absolute;
        transform: rotate(-45deg);
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        &:after {
            content: '';
            width: 14px;
            height: 14px;
            margin: 6px 0 0 5px;
            background: #343f47;
            position: absolute;
            border-radius: 50%;
        }
    }
    &.selected {
        background: #4caf50;
    }
    &.active {
        background: red;
    }
    &:after {}
}

.pinred {
    .pinred {
        width: 25px;
        height: 25px;
        border-radius: 50% 50% 50% 0;
        background: #d23429;
        position: absolute;
        transform: rotate(-45deg);
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        &:after {
            content: '';
            width: 14px;
            height: 14px;
            margin: 6px 0 0 5px;
            background: #343f47;
            position: absolute;
            border-radius: 50%;
        }
    }
    &.selected {
        background: #4caf50;
    }
    &.active {
        background: red;
    }
    &:after {}
}

tr.selected {
    background: #4caf50 !important;
    color: white;
}

.isactif {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: #21cc21;
    margin-left: 19px;
}

.notactif {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: #b82017;
    margin-left: 19px;
}

.markerRed {
    background-color: #f5b2b9 !important;
}

.markerGreen {
    background-color: #baf7e3 !important;
}

.animbounce {
    background: #35844a !important;
    background-color: #35844a !important;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

@-moz-keyframes bounce {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -webkit-transform: translateY(-2000px) rotate(-45deg);
        -moz-transform: translateY(-2000px) rotate(-45deg);
        -o-transform: translateY(-2000px) rotate(-45deg);
        -ms-transform: translateY(-2000px) rotate(-45deg);
        transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
        -webkit-transform: translateY(30px) rotate(-45deg);
        -moz-transform: translateY(30px) rotate(-45deg);
        -o-transform: translateY(30px) rotate(-45deg);
        -ms-transform: translateY(30px) rotate(-45deg);
        transform: translateY(30px) rotate(-45deg);
    }
    80% {
        -webkit-transform: translateY(-10px) rotate(-45deg);
        -moz-transform: translateY(-10px) rotate(-45deg);
        -o-transform: translateY(-10px) rotate(-45deg);
        -ms-transform: translateY(-10px) rotate(-45deg);
        transform: translateY(-10px) rotate(-45deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(-45deg);
        -moz-transform: translateY(0) rotate(-45deg);
        -o-transform: translateY(0) rotate(-45deg);
        -ms-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
    }
}

@-webkit-keyframes bounce {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -webkit-transform: translateY(-2000px) rotate(-45deg);
        -moz-transform: translateY(-2000px) rotate(-45deg);
        -o-transform: translateY(-2000px) rotate(-45deg);
        -ms-transform: translateY(-2000px) rotate(-45deg);
        transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
        -webkit-transform: translateY(30px) rotate(-45deg);
        -moz-transform: translateY(30px) rotate(-45deg);
        -o-transform: translateY(30px) rotate(-45deg);
        -ms-transform: translateY(30px) rotate(-45deg);
        transform: translateY(30px) rotate(-45deg);
    }
    80% {
        -webkit-transform: translateY(-10px) rotate(-45deg);
        -moz-transform: translateY(-10px) rotate(-45deg);
        -o-transform: translateY(-10px) rotate(-45deg);
        -ms-transform: translateY(-10px) rotate(-45deg);
        transform: translateY(-10px) rotate(-45deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(-45deg);
        -moz-transform: translateY(0) rotate(-45deg);
        -o-transform: translateY(0) rotate(-45deg);
        -ms-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
    }
}

@-o-keyframes bounce {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -webkit-transform: translateY(-2000px) rotate(-45deg);
        -moz-transform: translateY(-2000px) rotate(-45deg);
        -o-transform: translateY(-2000px) rotate(-45deg);
        -ms-transform: translateY(-2000px) rotate(-45deg);
        transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
        -webkit-transform: translateY(30px) rotate(-45deg);
        -moz-transform: translateY(30px) rotate(-45deg);
        -o-transform: translateY(30px) rotate(-45deg);
        -ms-transform: translateY(30px) rotate(-45deg);
        transform: translateY(30px) rotate(-45deg);
    }
    80% {
        -webkit-transform: translateY(-10px) rotate(-45deg);
        -moz-transform: translateY(-10px) rotate(-45deg);
        -o-transform: translateY(-10px) rotate(-45deg);
        -ms-transform: translateY(-10px) rotate(-45deg);
        transform: translateY(-10px) rotate(-45deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(-45deg);
        -moz-transform: translateY(0) rotate(-45deg);
        -o-transform: translateY(0) rotate(-45deg);
        -ms-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -webkit-transform: translateY(-2000px) rotate(-45deg);
        -moz-transform: translateY(-2000px) rotate(-45deg);
        -o-transform: translateY(-2000px) rotate(-45deg);
        -ms-transform: translateY(-2000px) rotate(-45deg);
        transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
        -webkit-transform: translateY(30px) rotate(-45deg);
        -moz-transform: translateY(30px) rotate(-45deg);
        -o-transform: translateY(30px) rotate(-45deg);
        -ms-transform: translateY(30px) rotate(-45deg);
        transform: translateY(30px) rotate(-45deg);
    }
    80% {
        -webkit-transform: translateY(-10px) rotate(-45deg);
        -moz-transform: translateY(-10px) rotate(-45deg);
        -o-transform: translateY(-10px) rotate(-45deg);
        -ms-transform: translateY(-10px) rotate(-45deg);
        transform: translateY(-10px) rotate(-45deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(-45deg);
        -moz-transform: translateY(0) rotate(-45deg);
        -o-transform: translateY(0) rotate(-45deg);
        -ms-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
    }
}




/**
 * code mirror
 */

 /* BASICS */

.CodeMirror {
    /* Set height, width, borders, and global font properties here */
    font-family: monospace;
    height: 300px;
    color: black;
    direction: ltr;
    font-size: 15px;
  }
  
  /* PADDING */
  
  .CodeMirror-lines {
    padding: 4px 0; /* Vertical padding around content */
  }
  .CodeMirror pre.CodeMirror-line,
  .CodeMirror pre.CodeMirror-line-like {
    padding: 0 4px; /* Horizontal padding of content */
  }
  
  .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    background-color: white; /* The little square between H and V scrollbars */
  }
  
  /* GUTTER */
  
  .CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap;
  }
  .CodeMirror-linenumbers {}
  .CodeMirror-linenumber {
    padding: 0 3px 0 5px;
    min-width: 20px;
    text-align: right;
    color: #999;
    white-space: nowrap;
  }
  
  .CodeMirror-guttermarker { color: black; }
  .CodeMirror-guttermarker-subtle { color: #999; }
  
  /* CURSOR */
  
  .CodeMirror-cursor {
    border-left: 1px solid black;
    border-right: none;
    width: 0;
  }
  /* Shown when moving in bi-directional text */
  .CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver;
  }
  .cm-fat-cursor .CodeMirror-cursor {
    width: auto;
    border: 0 !important;
    background: #7e7;
  }
  .cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1;
  }
  .cm-fat-cursor-mark {
    background-color: rgba(20, 255, 20, 0.5);
    -webkit-animation: blink 1.06s steps(1) infinite;
    -moz-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
  }
  .cm-animate-fat-cursor {
    width: auto;
    border: 0;
    -webkit-animation: blink 1.06s steps(1) infinite;
    -moz-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
    background-color: #7e7;
  }
  @-moz-keyframes blink {
    0% {}
    50% { background-color: transparent; }
    100% {}
  }
  @-webkit-keyframes blink {
    0% {}
    50% { background-color: transparent; }
    100% {}
  }
  @keyframes blink {
    0% {}
    50% { background-color: transparent; }
    100% {}
  }
  
  /* Can style cursor different in overwrite (non-insert) mode */
  .CodeMirror-overwrite .CodeMirror-cursor {}
  
  .cm-tab { display: inline-block; text-decoration: inherit; }
  
  .CodeMirror-rulers {
    position: absolute;
    left: 0; right: 0; top: -50px; bottom: 0;
    overflow: hidden;
  }
  .CodeMirror-ruler {
    border-left: 1px solid #ccc;
    top: 0; bottom: 0;
    position: absolute;
  }
  
  /* DEFAULT THEME */
  
  .cm-s-default .cm-header {color: blue;}
  .cm-s-default .cm-quote {color: #090;}
  .cm-negative {color: #d44;}
  .cm-positive {color: #292;}
  .cm-header, .cm-strong {font-weight: bold;}
  .cm-em {font-style: italic;}
  .cm-link {text-decoration: underline;}
  .cm-strikethrough {text-decoration: line-through;}
  
  .cm-s-default .cm-keyword {color: #708;}
  .cm-s-default .cm-atom {color: #219;}
  .cm-s-default .cm-number {color: #164;}
  .cm-s-default .cm-def {color: #00f;}
  .cm-s-default .cm-variable,
  .cm-s-default .cm-punctuation,
  .cm-s-default .cm-property,
  .cm-s-default .cm-operator {}
  .cm-s-default .cm-variable-2 {color: #05a;}
  .cm-s-default .cm-variable-3, .cm-s-default .cm-type {color: #085;}
  .cm-s-default .cm-comment {color: #a50;}
  .cm-s-default .cm-string {color: #a11;}
  .cm-s-default .cm-string-2 {color: #f50;}
  .cm-s-default .cm-meta {color: #555;}
  .cm-s-default .cm-qualifier {color: #555;}
  .cm-s-default .cm-builtin {color: #30a;}
  .cm-s-default .cm-bracket {color: #997;}
  .cm-s-default .cm-tag {color: #170;}
  .cm-s-default .cm-attribute {color: #00c;}
  .cm-s-default .cm-hr {color: #999;}
  .cm-s-default .cm-link {color: #00c;}
  
  .cm-s-default .cm-error {color: #f00;}
  .cm-invalidchar {color: #f00;}
  
  .CodeMirror-composing { border-bottom: 2px solid; }
  
  /* Default styles for common addons */
  
  div.CodeMirror span.CodeMirror-matchingbracket {color: #0b0;}
  div.CodeMirror span.CodeMirror-nonmatchingbracket {color: #a22;}
  .CodeMirror-matchingtag { background: rgba(255, 150, 0, .3); }
  .CodeMirror-activeline-background {background: #e8f2ff;}
  
  /* STOP */
  
  /* The rest of this file contains styles related to the mechanics of
     the editor. You probably shouldn't touch them. */
  
  .CodeMirror {
    position: relative;
    overflow: hidden;
    background: white;
  }
  
  .CodeMirror-scroll {
    overflow: scroll !important; /* Things will break if this is overridden */
    /* 50px is the magic margin used to hide the element's real scrollbars */
    /* See overflow: hidden in .CodeMirror */
    margin-bottom: -50px; margin-right: -50px;
    padding-bottom: 50px;
    height: 100%;
    outline: none; /* Prevent dragging from highlighting the element */
    position: relative;
  }
  .CodeMirror-sizer {
    position: relative;
    border-right: 50px solid transparent;
  }
  
  /* The fake, visible scrollbars. Used to force redraw during scrolling
     before actual scrolling happens, thus preventing shaking and
     flickering artifacts. */
  .CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    position: absolute;
    z-index: 6;
    display: none;
  }
  .CodeMirror-vscrollbar {
    right: 0; top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .CodeMirror-hscrollbar {
    bottom: 0; left: 0;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .CodeMirror-scrollbar-filler {
    right: 0; bottom: 0;
  }
  .CodeMirror-gutter-filler {
    left: 0; bottom: 0;
  }
  
  .CodeMirror-gutters {
    position: absolute; left: 0; top: 0;
    min-height: 100%;
    z-index: 3;
  }
  .CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: -50px;
  }
  .CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    background: none !important;
    border: none !important;
  }
  .CodeMirror-gutter-background {
    position: absolute;
    top: 0; bottom: 0;
    z-index: 4;
  }
  .CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
  }
  .CodeMirror-gutter-wrapper ::selection { background-color: transparent }
  .CodeMirror-gutter-wrapper ::-moz-selection { background-color: transparent }
  
  .CodeMirror-lines {
    cursor: text;
    min-height: 1px; /* prevents collapsing before first draw */
  }
  .CodeMirror pre.CodeMirror-line,
  .CodeMirror pre.CodeMirror-line-like {
    /* Reset some styles that the rest of the page might have set */
    -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0;
    border-width: 0;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-variant-ligatures: contextual;
    font-variant-ligatures: contextual;
  }
  .CodeMirror-wrap pre.CodeMirror-line,
  .CodeMirror-wrap pre.CodeMirror-line-like {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
  }
  
  .CodeMirror-linebackground {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    z-index: 0;
  }
  
  .CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    padding: 0.1px; /* Force widget margins to stay inside of the container */
  }
  
  .CodeMirror-widget {}
  
  .CodeMirror-rtl pre { direction: rtl; }
  
  .CodeMirror-code {
    outline: none;
  }
  
  /* Force content-box sizing for the elements where we expect it */
  .CodeMirror-scroll,
  .CodeMirror-sizer,
  .CodeMirror-gutter,
  .CodeMirror-gutters,
  .CodeMirror-linenumber {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  
  .CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  
  .CodeMirror-cursor {
    position: absolute;
    pointer-events: none;
  }
  .CodeMirror-measure pre { position: static; }
  
  div.CodeMirror-cursors {
    visibility: hidden;
    position: relative;
    z-index: 3;
  }
  div.CodeMirror-dragcursors {
    visibility: visible;
  }
  
  .CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible;
  }
  
  .CodeMirror-selected { background: #d9d9d9; }
  .CodeMirror-focused .CodeMirror-selected { background: #d7d4f0; }
  .CodeMirror-crosshair { cursor: crosshair; }
  .CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: #d7d4f0; }
  .CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0; }
  
  .cm-searching {
    background-color: #ffa;
    background-color: rgba(255, 255, 0, .4);
  }
  
  /* Used to force a border model for a node */
  .cm-force-border { padding-right: .1px; }
  
  @media print {
    /* Hide the cursor when printing */
    .CodeMirror div.CodeMirror-cursors {
      visibility: hidden;
    }
  }
  
  /* See issue #2901 */
  .cm-tab-wrap-hack:after { content: ''; }
  
  /* Help users use markselection to safely style text background */
  span.CodeMirror-selectedtext { background: none; }





  /**
  *
  */


  .cm-s-twilight.CodeMirror { background: #141414; color: #f7f7f7; } /**/
.cm-s-twilight div.CodeMirror-selected { background: #323232; } /**/
.cm-s-twilight .CodeMirror-line::selection, .cm-s-twilight .CodeMirror-line > span::selection, .cm-s-twilight .CodeMirror-line > span > span::selection { background: rgba(50, 50, 50, 0.99); }
.cm-s-twilight .CodeMirror-line::-moz-selection, .cm-s-twilight .CodeMirror-line > span::-moz-selection, .cm-s-twilight .CodeMirror-line > span > span::-moz-selection { background: rgba(50, 50, 50, 0.99); }

.cm-s-twilight .CodeMirror-gutters { background: #222; border-right: 1px solid #aaa; }
.cm-s-twilight .CodeMirror-guttermarker { color: white; }
.cm-s-twilight .CodeMirror-guttermarker-subtle { color: #aaa; }
.cm-s-twilight .CodeMirror-linenumber { color: #aaa; }
.cm-s-twilight .CodeMirror-cursor { border-left: 1px solid white; }

.cm-s-twilight .cm-keyword { color: #f9ee98; } /**/
.cm-s-twilight .cm-atom { color: #FC0; }
.cm-s-twilight .cm-number { color:  #ca7841; } /**/
.cm-s-twilight .cm-def { color: #8DA6CE; }
.cm-s-twilight span.cm-variable-2, .cm-s-twilight span.cm-tag { color: #607392; } /**/
.cm-s-twilight span.cm-variable-3, .cm-s-twilight span.cm-def, .cm-s-twilight span.cm-type { color: #607392; } /**/
.cm-s-twilight .cm-operator { color: #cda869; } /**/
.cm-s-twilight .cm-comment { color:#777; font-style:italic; font-weight:normal; } /**/
.cm-s-twilight .cm-string { color:#8f9d6a; font-style:italic; } /**/
.cm-s-twilight .cm-string-2 { color:#bd6b18; } /*?*/
.cm-s-twilight .cm-meta { background-color:#141414; color:#f7f7f7; } /*?*/
.cm-s-twilight .cm-builtin { color: #cda869; } /*?*/
.cm-s-twilight .cm-tag { color: #997643; } /**/
.cm-s-twilight .cm-attribute { color: #d6bb6d; } /*?*/
.cm-s-twilight .cm-header { color: #FF6400; }
.cm-s-twilight .cm-hr { color: #AEAEAE; }
.cm-s-twilight .cm-link { color:#ad9361; font-style:italic; text-decoration:none; } /**/
.cm-s-twilight .cm-error { border-bottom: 1px solid red; }

.cm-s-twilight .CodeMirror-activeline-background { background: #27282E; }
.cm-s-twilight .CodeMirror-matchingbracket { outline:1px solid grey; color:white !important; }


/**
 * dracula
 */

 /*

    Name:       dracula
    Author:     Michael Kaminsky (http://github.com/mkaminsky11)

    Original dracula color scheme by Zeno Rocha (https://github.com/zenorocha/dracula-theme)

*/


.cm-s-dracula.CodeMirror, .cm-s-dracula .CodeMirror-gutters {
    background-color: #282a36 !important;
    color: #f8f8f2 !important;
    border: none;
  }
  .cm-s-dracula .CodeMirror-gutters { color: #282a36; }
  .cm-s-dracula .CodeMirror-cursor { border-left: solid thin #f8f8f0; }
  .cm-s-dracula .CodeMirror-linenumber { color: #6D8A88; }
  .cm-s-dracula .CodeMirror-selected { background: rgba(255, 255, 255, 0.10); }
  .cm-s-dracula .CodeMirror-line::selection, .cm-s-dracula .CodeMirror-line > span::selection, .cm-s-dracula .CodeMirror-line > span > span::selection { background: rgba(255, 255, 255, 0.10); }
  .cm-s-dracula .CodeMirror-line::-moz-selection, .cm-s-dracula .CodeMirror-line > span::-moz-selection, .cm-s-dracula .CodeMirror-line > span > span::-moz-selection { background: rgba(255, 255, 255, 0.10); }
  .cm-s-dracula span.cm-comment { color: #6272a4; }
  .cm-s-dracula span.cm-string, .cm-s-dracula span.cm-string-2 { color: #f1fa8c; }
  .cm-s-dracula span.cm-number { color: #bd93f9; }
  .cm-s-dracula span.cm-variable { color: #50fa7b; }
  .cm-s-dracula span.cm-variable-2 { color: white; }
  .cm-s-dracula span.cm-def { color: #50fa7b; }
  .cm-s-dracula span.cm-operator { color: #ff79c6; }
  .cm-s-dracula span.cm-keyword { color: #ff79c6; }
  .cm-s-dracula span.cm-atom { color: #bd93f9; }
  .cm-s-dracula span.cm-meta { color: #f8f8f2; }
  .cm-s-dracula span.cm-tag { color: #ff79c6; }
  .cm-s-dracula span.cm-attribute { color: #50fa7b; }
  .cm-s-dracula span.cm-qualifier { color: #50fa7b; }
  .cm-s-dracula span.cm-property { color: #66d9ef; }
  .cm-s-dracula span.cm-builtin { color: #50fa7b; }
  .cm-s-dracula span.cm-variable-3, .cm-s-dracula span.cm-type { color: #ffb86c; }
  
  .cm-s-dracula .CodeMirror-activeline-background { background: rgba(255,255,255,0.1); }
  .cm-s-dracula .CodeMirror-matchingbracket { text-decoration: underline; color: white !important; }