.swal2-container {
  z-index: 9999999 !important;
}
.z-index-sec-modal {
  z-index: 999999 !important;
}
.succ-green {
  background: rgb(101, 241, 195) !important;
}

.dang-red {
  background: #f3b4b4 !important;
}

.c-blue {
  color: blue;
}
.c-red {
  color: rgb(209, 20, 20);
}

.c-green {
  color: #22baa0;
}
.bg-red {
  background: rgb(209, 20, 20) !important;
}
.bg-gris {
  background: #f5f4f4;
}
.pointer {
  cursor: pointer;
}
.kpi-refresh {
  color: #d11414;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    color: #08db8a;
  }
}

.relative {
  position: relative;
}

/**
 * switcher button checkbox
 */

.onoffswitch3 {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch3-checkbox {
  display: none;
}

.onoffswitch3-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0px solid #999999;
  border-radius: 0px;
}

.onoffswitch3-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch3-inner > span {
  display: block;
  float: left;
  position: relative;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch3-inner .onoffswitch3-active {
  padding-left: 10px;
  background-color: #eeeeee;
  color: #ffffff;
}

.onoffswitch3-inner .onoffswitch3-inactive {
  padding-right: 10px;
  background-color: #eeeeee;
  color: #ffffff;
  text-align: right;
}

.onoffswitch3-switch {
  display: block;
  width: 40px;
  margin: 0px;
  text-align: center;
  border: 0px solid #999999;
  border-radius: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
}
.onoffswitch3-active .onoffswitch3-switch {
  background: rgb(6, 187, 87);
  left: 0;
}
.onoffswitch3-inactive .onoffswitch3-switch {
  background: rgb(228, 59, 59);
  right: 0;
}

.onoffswitch3-active .onoffswitch3-switch:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 40px;
  border-style: solid;
  border-color: rgb(6, 187, 87) transparent transparent rgb(6, 187, 87);
  border-width: 15px 9px;
}

.onoffswitch3-inactive .onoffswitch3-switch:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 40px;
  border-style: solid;
  border-color: transparent rgb(228, 59, 59) rgb(228, 59, 59) transparent;
  border-width: 15px 9px;
}

.onoffswitch3-checkbox:checked + .onoffswitch3-label .onoffswitch3-inner {
  margin-left: 0;
}

/**
 * end switcher button checkbox
 */
.m-t-7 {
  margin-top: 7px;
}

.absolute {
  position: absolute;
}
.ftr-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.l-h-30 {
  line-height: 30px;
}
i.check_ass {
  color: #41a735;
  font-size: 30px;
}

div[uib-modal-window="modal-window"] {
  background: #000000ad;
}
.swal2-shown {
  padding-right: 0px !important;
}

.header-add-bl-credit {
  width: 90%;
  left: 5%;
  top: 5px;
  display: flex;
}
.header-add-bl-credit > div {
  width: 32%;
  text-align: center;
  float: left;
  font-size: 15px;
}
.header-add-bl-credit > div > span {
  font-size: 19px;
  margin-left: 10px;
  float: right;
}

.bar-for-bl-credit {
  border-right: 2px solid;
  padding-right: 45px;
}

/**
 *
 */
.plafont_credit_caion {
  width: 90%;
  left: 5%;
  top: 25px;
  display: flex;
}
.plafont_credit_caion > div {
  width: 32%;
  text-align: center;
  float: left;
  font-size: 15px;
}
.plafont_credit_caion > div > span {
  font-size: 19px;
  margin-left: 10px;
  float: right;
}

.m-l-45 {
  margin-left: 45px;
}
.p-t-11 {
  padding-top: 11px;
}

.sticky_top0 {
  position: sticky;
  top: 0;
}

.sticky_top29 {
  position: sticky;
  top: 29px;
}

.antiquewhite {
  background: antiquewhite;
}
.z-999 {
  z-index: 999;
}

._720kb-datepicker-open {
  z-index: 99999 !important;
}

.btn_global_filter {
  margin-right: 9.5%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.global_filter {
  width: 75%;
  min-height: 200px;
  background: #fff;
  margin-left: 12.5%;
  border-right: 3px solid #337ab7;
  border-left: 3px solid #337ab7;
  border-bottom: 3px solid #337ab7;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 5px 8px 11px 0px rgba(0, 0, 0, 0.4);

  //animation: transition2 2s linear 2s;
}

@keyframes transition2 {
  from {
    height: 0;
    width: 0;
    opacity: 0.5;
  }
  to {
    opacity: 1;
    width: auto;
    height: auto;
  }
}

.bg_traite {
  background: rgb(64, 230, 189);
}

.bg_non_traite {
  background: rgb(255, 213, 211);
}

.panel_gratuite {
  height: 300px;
  margin: 5px;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
}

.img_prod_bl {
  width: 100%;
  height: 175px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.lg-95 > .modal-lg {
  width: 90% !important;
}
.prod_grat_selected {
  border: 10px solid #32c137 !important;
}
.prod_grat_selected_hors_stock {
  border: 10px solid #fcb2c2 !important;
}
.hors_stock_gratuite {
  background: repeating-linear-gradient(45deg, #fbe3e4, #fbe3e4 20px, #ffced9 20px, #ffced9 40px) !important;
}

.checked_prod {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 26px;
  background: #32c137;
  // border-top-right-radius: 10px;
  // border-top-left-radius: 10px;
}
.checked_prod_hors_stock {
  background: #fcb2c2 !important;
}
.checked_prod > i {
  font-size: 25px;
  color: #fff;
}

.left_21 {
  left: 21%;
}
.left_40 {
  left: 40%;
}

.cart_add_gratuite {
  font-size: 31px;
  position: absolute;
  right: -2px;
  top: 0px;
  background: #4646453b;
  padding: 5px 17px 5px 17px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}

.cart_remove_gratuite {
  position: absolute;
  right: 0px;
  top: 0px;
  color: red !important;
  cursor: pointer;
}

.transtext {
  height: 50px;
  overflow: hidden;
  position: relative;
}
.transtext h4 {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: transtext 15s linear infinite;
  -webkit-animation: transtext 15s linear infinite;
  animation: transtext 15s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes transtext {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes transtext {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes transtext {
  0% {
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
