@import url(../assets/plugins/datatables/css/jquery.datatables.min.css);
@import url(../assets/plugins/datatables/css/jquery.datatables_themeroller.css);
@import url(../assets/plugins/pace-master/themes/blue/pace-theme-flash.css);
@import url(../assets/plugins/uniform/css/uniform.default.min.css);
@import url(../assets/plugins/fontawesome/css/font-awesome.css);
@import url(../assets/plugins/line-icons/simple-line-icons.css);
@import url(../assets/plugins/offcanvasmenueffects/css/menu_cornerbox.css);
@import url(../assets/plugins/waves/waves.min.css);
@import url(../assets/plugins/switchery/switchery.min.css);
@import url(../assets/plugins/3d-bold-navigation/css/style.css);
@import url(../assets/plugins/slidepushmenus/css/component.css);
@import url(../assets/plugins/weather-icons-master/css/weather-icons.min.css);
@import url(../assets/plugins/metrojs/MetroJs.min.css);
@import url(../assets/plugins/toastr/toastr.min.css);
@import url(../assets/plugins/bootstrap/css/bootstrap.min.css);
@import url(../assets/plugins/animatecss/animate.css);
/* line 1, app/styles/_typography.scss */
p {
  margin-bottom: 1em; }

/* line 5, app/styles/_typography.scss */
.heading {
  margin-bottom: 0.618em; }
  /* line 8, app/styles/_typography.scss */
  .heading.-large, h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 30px; }
  /* line 14, app/styles/_typography.scss */
  .heading.-medium, h2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px; }
  /* line 20, app/styles/_typography.scss */
  .heading.-small, h3 {
    font-size: 12px;
    font-weight: bold;
    line-height: 13.33333px; }
  /* line 26, app/styles/_typography.scss */
  .heading.-smallest {
    font-size: 10px;
    font-weight: bold; }

/* Global Styles */
/* line 3, app/styles/_custom.scss */
#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5; }

/* line 10, app/styles/_custom.scss */
#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

/* line 16, app/styles/_custom.scss */
#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555; }

/* line 25, app/styles/_custom.scss */
body {
  position: relative;
  height: 100% !important;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  background: #ccc;
  color: #4E5E6A; }

/* line 35, app/styles/_custom.scss */
html {
  height: 100% !important;
  position: relative; }

/* line 40, app/styles/_custom.scss */
a:hover,
a:active,
a:focus {
  outline: none !important; }

/* Typography */
/* line 49, app/styles/_custom.scss */
p {
  display: block;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal; }

/* line 60, app/styles/_custom.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

/* line 69, app/styles/_custom.scss */
h1 {
  font-size: 30px; }

/* line 73, app/styles/_custom.scss */
h2 {
  font-size: 24px; }

/* line 77, app/styles/_custom.scss */
h3 {
  font-size: 16px; }

/* line 81, app/styles/_custom.scss */
h4 {
  font-size: 14px; }

/* line 85, app/styles/_custom.scss */
h5 {
  font-size: 12px; }

/* line 89, app/styles/_custom.scss */
h6 {
  font-size: 10px; }

/* line 93, app/styles/_custom.scss */
h3,
h4,
h5 {
  font-weight: 600; }

/* line 99, app/styles/_custom.scss */
.f-thin {
  font-weight: 300; }

/* line 103, app/styles/_custom.scss */
.f-normal {
  font-weight: normal; }

/* line 107, app/styles/_custom.scss */
.f-bold {
  font-weight: 600; }

/* line 111, app/styles/_custom.scss */
.f-s-10 {
  font-size: 10px; }

/* line 115, app/styles/_custom.scss */
.f-s-11 {
  font-size: 11px; }

/* line 119, app/styles/_custom.scss */
.f-s-12 {
  font-size: 12px; }

/* line 123, app/styles/_custom.scss */
.f-s-13 {
  font-size: 13px; }

/* line 127, app/styles/_custom.scss */
.f-s-14 {
  font-size: 14px; }

/* line 131, app/styles/_custom.scss */
.f-s-15 {
  font-size: 15px; }

/* line 135, app/styles/_custom.scss */
.f-s-16 {
  font-size: 16px; }

/* line 139, app/styles/_custom.scss */
.f-s-17 {
  font-size: 17px; }

/* line 143, app/styles/_custom.scss */
.f-s-18 {
  font-size: 18px; }

/* line 147, app/styles/_custom.scss */
.f-s-20 {
  font-size: 20px; }

/* line 151, app/styles/_custom.scss */
.f-s-22 {
  font-size: 22px; }

/* line 155, app/styles/_custom.scss */
.f-s-24 {
  font-size: 24px; }

/* line 159, app/styles/_custom.scss */
.f-s-26 {
  font-size: 26px; }

/* line 163, app/styles/_custom.scss */
.f-s-28 {
  font-size: 28px; }

/* line 167, app/styles/_custom.scss */
.f-s-30 {
  font-size: 30px; }

/* line 171, app/styles/_custom.scss */
.f-s-32 {
  font-size: 32px; }

/* line 175, app/styles/_custom.scss */
.text-xs {
  font-size: 10px; }

/* line 179, app/styles/_custom.scss */
.text-sm {
  font-size: 12px; }

/* line 183, app/styles/_custom.scss */
.text-md {
  font-size: 16px; }

/* line 187, app/styles/_custom.scss */
.text-lg {
  font-size: 32px; }

/* line 191, app/styles/_custom.scss */
.text-xl {
  font-size: 52px; }

/* line 195, app/styles/_custom.scss */
.text-xxl {
  font-size: 80px; }

/* line 199, app/styles/_custom.scss */
.f-white {
  color: #fff; }

/* line 203, app/styles/_custom.scss */
.f-gray {
  color: #ccc; }

/* line 207, app/styles/_custom.scss */
.f-gray-dark {
  color: #a2a2a2; }

/* line 211, app/styles/_custom.scss */
.f-black {
  color: #333; }

/* line 215, app/styles/_custom.scss */
.f-green {
  color: #22BAA0; }

/* line 219, app/styles/_custom.scss */
.f-blue {
  color: #127acb; }

/* line 223, app/styles/_custom.scss */
.f-lblue {
  color: #12AFCB; }

/* line 227, app/styles/_custom.scss */
.f-purple {
  color: #7a6fbe; }

/* line 231, app/styles/_custom.scss */
.f-red {
  color: #f25656; }

/* line 235, app/styles/_custom.scss */
.f-yellow {
  color: #f6d433; }

/* line 239, app/styles/_custom.scss */
.f-orange {
  color: #e79b2f; }

/* line 243, app/styles/_custom.scss */
.text-default {
  color: #ccc; }

/* line 247, app/styles/_custom.scss */
.text-primary {
  color: #7a6fbe; }

/* line 251, app/styles/_custom.scss */
.text-success {
  color: #22BAA0; }

/* line 255, app/styles/_custom.scss */
.text-info {
  color: #12AFCB; }

/* line 259, app/styles/_custom.scss */
.text-warning {
  color: #f6d433; }

/* line 263, app/styles/_custom.scss */
.text-danger {
  color: #f25656; }

/* Icons */
/* line 270, app/styles/_custom.scss */
.icon-xs {
  font-size: 12px; }

/* line 274, app/styles/_custom.scss */
.icon-sm {
  font-size: 14px; }

/* line 278, app/styles/_custom.scss */
.icon-md {
  font-size: 16px; }

/* line 282, app/styles/_custom.scss */
.icon-lg {
  font-size: 18px; }

/* line 286, app/styles/_custom.scss */
.icon-state-default {
  color: #ccc; }

/* line 290, app/styles/_custom.scss */
.icon-state-primary {
  color: #7a6fbe; }

/* line 294, app/styles/_custom.scss */
.icon-state-success {
  color: #22BAA0; }

/* line 298, app/styles/_custom.scss */
.icon-state-info {
  color: #12AFCB; }

/* line 302, app/styles/_custom.scss */
.icon-state-warning {
  color: #f6d433; }

/* line 306, app/styles/_custom.scss */
.icon-state-danger {
  color: #f25656; }

/* Alert */
/* line 313, app/styles/_custom.scss */
.alert {
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0; }

/* line 320, app/styles/_custom.scss */
.alert-success {
  background: #e5f3df; }

/* line 324, app/styles/_custom.scss */
.alert-info {
  background: #d3e6f0; }

/* line 328, app/styles/_custom.scss */
.alert-warning {
  background: #f6f2dd; }

/* line 332, app/styles/_custom.scss */
.alert-danger {
  background: #f1d9d9; }

/* Custom Badges */
/* line 338, app/styles/_custom.scss */
.badge {
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  border-radius: 0px;
  font-weight: 300;
  font-size: 11px !important;
  text-align: center;
  vertical-align: middle;
  line-height: 11px;
  height: 18px;
  padding: 3px 6px 3px 6px;
  text-shadow: none !important; }

/* line 353, app/styles/_custom.scss */
.badge.badge-roundless {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important; }

/* line 359, app/styles/_custom.scss */
.badge.badge-default {
  background: #f1f1f1;
  color: #aaa; }

/* line 364, app/styles/_custom.scss */
.badge.badge-primary {
  background: #7a6fbe;
  color: #FFF; }

/* line 369, app/styles/_custom.scss */
.badge.badge-info {
  background: #12AFCB;
  color: #FFF; }

/* line 374, app/styles/_custom.scss */
.badge.badge-success {
  background: #22BAA0;
  color: #FFF; }

/* line 379, app/styles/_custom.scss */
.badge.badge-danger {
  background: #f25656;
  color: #FFF; }

/* line 384, app/styles/_custom.scss */
.badge.badge-warning {
  background: #f6d433;
  color: #FFF; }

/* Switchery */
/* line 392, app/styles/_custom.scss */
.switch-sm .switchery {
  height: 15px;
  width: 25px; }

/* line 397, app/styles/_custom.scss */
.switch-sm .switchery > small {
  height: 15px;
  width: 15px; }

/* line 402, app/styles/_custom.scss */
.switch-md .switchery {
  height: 20px;
  width: 35px; }

/* line 407, app/styles/_custom.scss */
.switch-md .switchery > small {
  height: 20px;
  width: 20px; }

/* line 412, app/styles/_custom.scss */
.switch-lg .switchery {
  height: 25px;
  width: 40px; }

/* line 417, app/styles/_custom.scss */
.switch-lg .switchery > small {
  height: 25px;
  width: 25px; }

/* CSS Utilities */
/* Margins & Paddings */
/* line 426, app/styles/_custom.scss */
.no-s {
  margin: 0px !important;
  padding: 0px !important; }

/* line 431, app/styles/_custom.scss */
.no-m {
  margin: 0; }

/* line 435, app/styles/_custom.scss */
.no-p {
  padding: 0; }

/* line 439, app/styles/_custom.scss */
.m {
  margin: 15px; }

/* line 443, app/styles/_custom.scss */
.m-t-xxs {
  margin-top: 5px; }

/* line 447, app/styles/_custom.scss */
.m-r-xxs {
  margin-right: 5px; }

/* line 451, app/styles/_custom.scss */
.m-b-xxs {
  margin-bottom: 5px; }

/* line 455, app/styles/_custom.scss */
.m-l-xxs {
  margin-left: 5px; }

/* line 459, app/styles/_custom.scss */
.m-t-xs {
  margin-top: 10px; }

/* line 463, app/styles/_custom.scss */
.m-r-xs {
  margin-right: 10px; }

/* line 467, app/styles/_custom.scss */
.m-b-xs {
  margin-bottom: 10px; }

/* line 471, app/styles/_custom.scss */
.m-l-xs {
  margin-left: 10px; }

/* line 475, app/styles/_custom.scss */
.m-t-sm {
  margin-top: 15px; }

/* line 479, app/styles/_custom.scss */
.m-r-sm {
  margin-right: 15px; }

/* line 483, app/styles/_custom.scss */
.m-b-sm {
  margin-bottom: 15px; }

/* line 487, app/styles/_custom.scss */
.m-l-sm {
  margin-left: 15px; }

/* line 491, app/styles/_custom.scss */
.m-t-md {
  margin-top: 20px; }

/* line 495, app/styles/_custom.scss */
.m-r-md {
  margin-right: 20px; }

/* line 499, app/styles/_custom.scss */
.m-b-md {
  margin-bottom: 20px; }

/* line 503, app/styles/_custom.scss */
.m-l-md {
  margin-left: 20px; }

/* line 507, app/styles/_custom.scss */
.m-t-lg {
  margin-top: 25px; }

/* line 511, app/styles/_custom.scss */
.m-r-lg {
  margin-right: 25px; }

/* line 515, app/styles/_custom.scss */
.m-b-lg {
  margin-bottom: 25px; }

/* line 519, app/styles/_custom.scss */
.m-l-lg {
  margin-left: 25px; }

/* line 523, app/styles/_custom.scss */
.m-t-xxl {
  margin-top: 30px; }

/* line 527, app/styles/_custom.scss */
.m-r-xxl {
  margin-right: 30px; }

/* line 531, app/styles/_custom.scss */
.m-b-xxl {
  margin-bottom: 30px; }

/* line 535, app/styles/_custom.scss */
.m-l-xxl {
  margin-left: 30px; }

/* line 539, app/styles/_custom.scss */
.m-t-n-xxs {
  margin-top: -5px; }

/* line 543, app/styles/_custom.scss */
.m-r-n-xxs {
  margin-right: -5px; }

/* line 547, app/styles/_custom.scss */
.m-b-n-xxs {
  margin-bottom: -5px; }

/* line 551, app/styles/_custom.scss */
.m-l-n-xxs {
  margin-left: -5px; }

/* line 555, app/styles/_custom.scss */
.m-t-n-xs {
  margin-top: -10px; }

/* line 559, app/styles/_custom.scss */
.m-r-n-xs {
  margin-right: -10px; }

/* line 563, app/styles/_custom.scss */
.m-b-n-xs {
  margin-bottom: -10px; }

/* line 567, app/styles/_custom.scss */
.m-l-n-xs {
  margin-left: -10px; }

/* line 571, app/styles/_custom.scss */
.m-t-n-sm {
  margin-top: -15px; }

/* line 575, app/styles/_custom.scss */
.m-r-n-sm {
  margin-right: -15px; }

/* line 579, app/styles/_custom.scss */
.m-b-n-sm {
  margin-bottom: -15px; }

/* line 583, app/styles/_custom.scss */
.m-l-n-sm {
  margin-left: -15px; }

/* line 587, app/styles/_custom.scss */
.m-t-n-md {
  margin-top: -20px; }

/* line 591, app/styles/_custom.scss */
.m-r-n-md {
  margin-right: -20px; }

/* line 595, app/styles/_custom.scss */
.m-b-n-md {
  margin-bottom: -20px; }

/* line 599, app/styles/_custom.scss */
.m-l-n-md {
  margin-left: -20px; }

/* line 603, app/styles/_custom.scss */
.m-t-n-lg {
  margin-top: -25px; }

/* line 607, app/styles/_custom.scss */
.m-r-n-lg {
  margin-right: -25px; }

/* line 611, app/styles/_custom.scss */
.m-b-n-lg {
  margin-bottom: -25px; }

/* line 615, app/styles/_custom.scss */
.m-l-n-lg {
  margin-left: -25px; }

/* line 619, app/styles/_custom.scss */
.m-t-n-xxl {
  margin-top: -30px; }

/* line 623, app/styles/_custom.scss */
.m-r-n-xxl {
  margin-right: -30px; }

/* line 627, app/styles/_custom.scss */
.m-b-n-xxl {
  margin-bottom: -30px; }

/* line 631, app/styles/_custom.scss */
.m-l-n-xxl {
  margin-left: -30px; }

/* line 635, app/styles/_custom.scss */
.p {
  padding: 15px; }

/* line 639, app/styles/_custom.scss */
.p-h-xxs {
  padding-left: 5px;
  padding-right: 5px; }

/* line 644, app/styles/_custom.scss */
.p-h-xs {
  padding-left: 10px;
  padding-right: 10px; }

/* line 649, app/styles/_custom.scss */
.p-h-sm {
  padding-left: 15px;
  padding-right: 15px; }

/* line 654, app/styles/_custom.scss */
.no-p-h {
  padding-left: 0;
  padding-right: 0; }

/* line 659, app/styles/_custom.scss */
.no-p-v {
  padding-top: 0;
  padding-bottom: 0; }

/* line 664, app/styles/_custom.scss */
.p-h-md {
  padding-left: 20px;
  padding-right: 20px; }

/* line 669, app/styles/_custom.scss */
.p-h-lg {
  padding-left: 25px;
  padding-right: 25px; }

/* line 674, app/styles/_custom.scss */
.p-h-xxl {
  padding-left: 30px;
  padding-right: 30px; }

/* line 679, app/styles/_custom.scss */
.p-v-xxs {
  padding-top: 5px;
  padding-bottom: 5px; }

/* line 684, app/styles/_custom.scss */
.p-v-xs {
  padding-top: 10px;
  padding-bottom: 10px; }

/* line 689, app/styles/_custom.scss */
.p-v-sm {
  padding-top: 15px;
  padding-bottom: 15px; }

/* line 694, app/styles/_custom.scss */
.p-v-md {
  padding-top: 20px;
  padding-bottom: 20px; }

/* line 699, app/styles/_custom.scss */
.p-v-lg {
  padding-top: 25px;
  padding-bottom: 25px; }

/* line 704, app/styles/_custom.scss */
.p-v-xxl {
  padding-top: 30px;
  padding-bottom: 30px; }

/* Borders */
/* line 712, app/styles/_custom.scss */
.no-b {
  border: 0 !important; }

/* line 716, app/styles/_custom.scss */
.b {
  border: 1px solid #f1f1f1; }

/* line 720, app/styles/_custom.scss */
.b-t {
  border-top: 1px solid #f1f1f1; }

/* line 724, app/styles/_custom.scss */
.b-r {
  border-right: 1px solid #f1f1f1; }

/* line 728, app/styles/_custom.scss */
.b-b {
  border-bottom: 1px solid #f1f1f1; }

/* line 732, app/styles/_custom.scss */
.b-l {
  border-left: 1px solid #f1f1f1; }

/* line 736, app/styles/_custom.scss */
.b-default {
  border-color: #f1f1f1; }

/* line 740, app/styles/_custom.scss */
.b-primary {
  border-color: #7a6fbe; }

/* line 744, app/styles/_custom.scss */
.b-info {
  border-color: #12AFCB; }

/* line 748, app/styles/_custom.scss */
.b-success {
  border-color: #22BAA0; }

/* line 752, app/styles/_custom.scss */
.b-warning {
  border-color: #f6d433; }

/* line 756, app/styles/_custom.scss */
.b-danger {
  border-color: #f25656; }

/* line 760, app/styles/_custom.scss */
.b-2 {
  border-size: 2px; }

/* line 764, app/styles/_custom.scss */
.b-3 {
  border-size: 3px; }

/* line 768, app/styles/_custom.scss */
.b-4 {
  border-size: 4px; }

/* line 772, app/styles/_custom.scss */
.b-n-t {
  border-top: 0px !important; }

/* line 776, app/styles/_custom.scss */
.b-n-r {
  border-right: 0px !important; }

/* line 780, app/styles/_custom.scss */
.b-n-b {
  border-bottom: 0px !important; }

/* line 784, app/styles/_custom.scss */
.b-n-l {
  border-left: 0px !important; }

/* Radius */
/* line 791, app/styles/_custom.scss */
.no-r {
  border-radius: 0px !important; }

/* line 795, app/styles/_custom.scss */
.r {
  border-radius: 2px; }

/* line 799, app/styles/_custom.scss */
.r-t-l {
  border-top-left-radius: 2px; }

/* line 803, app/styles/_custom.scss */
.r-t-r {
  border-top-right-radius: 2px; }

/* line 807, app/styles/_custom.scss */
.r-b-l {
  border-bottom-left-radius: 2px; }

/* line 811, app/styles/_custom.scss */
.r-b-r {
  border-bottom-right-radius: 2px; }

/* line 815, app/styles/_custom.scss */
.no-r-t-l {
  border-top-left-radius: 0 !important; }

/* line 819, app/styles/_custom.scss */
.no-r-t-r {
  border-top-right-radius: 0 !important; }

/* line 823, app/styles/_custom.scss */
.no-r-b-l {
  border-bottom-left-radius: 0 !important; }

/* line 827, app/styles/_custom.scss */
.no-r-b-r {
  border-bottom-right-radius: 0 !important; }

/* Backgrounds */
/* line 833, app/styles/_custom.scss */
.bg-n {
  background: transparent !important; }

/* line 837, app/styles/_custom.scss */
.bg-primary {
  background-color: #7a6fbe;
  color: #fff; }

/* line 842, app/styles/_custom.scss */
.bg-primary.dark {
  background-color: #6e63b4;
  color: #fff; }

/* line 847, app/styles/_custom.scss */
.bg-primary.light {
  background-color: #877cc7;
  color: #fff; }

/* line 852, app/styles/_custom.scss */
.bg-success {
  background-color: #22BAA0;
  color: #fff; }

/* line 857, app/styles/_custom.scss */
.bg-success.dark {
  background-color: #1da890;
  color: #fff; }

/* line 862, app/styles/_custom.scss */
.bg-success.light {
  background-color: #32c6ad;
  color: #fff; }

/* line 867, app/styles/_custom.scss */
.bg-info {
  background-color: #12AFCB;
  color: #fff; }

/* line 872, app/styles/_custom.scss */
.bg-info.dark {
  background-color: #0ba0bb;
  color: #fff; }

/* line 877, app/styles/_custom.scss */
.bg-info.light {
  background-color: #20beda;
  color: #fff; }

/* line 882, app/styles/_custom.scss */
.bg-warning {
  background-color: #f6d433;
  color: #fff; }

/* line 887, app/styles/_custom.scss */
.bg-warning.dark {
  background-color: #f1ca11;
  color: #fff; }

/* line 892, app/styles/_custom.scss */
.bg-warning.light {
  background-color: #fada44;
  color: #fff; }

/* line 897, app/styles/_custom.scss */
.bg-danger {
  background-color: #f25656;
  color: #fff; }

/* line 902, app/styles/_custom.scss */
.bg-danger.dark {
  background-color: #e54f4f;
  color: #fff; }

/* line 907, app/styles/_custom.scss */
.bg-danger.light {
  background-color: #f66d6d;
  color: #fff; }

/* line 912, app/styles/_custom.scss */
.bg-dark {
  background-color: #333;
  color: #fff; }

/* line 917, app/styles/_custom.scss */
.bg-dark.dark {
  background-color: #2b2b2b;
  color: #fff; }

/* line 922, app/styles/_custom.scss */
.bg-dark.light {
  background-color: #3b3b3b;
  color: #fff; }

/* line 927, app/styles/_custom.scss */
.bg-light {
  background-color: #f1f1f1; }

/* line 931, app/styles/_custom.scss */
.bg-light.dark {
  background-color: #e5e5e5; }

/* line 935, app/styles/_custom.scss */
.bg-light.light {
  background-color: #f9f9f9; }

/* line 939, app/styles/_custom.scss */
.bg-gray {
  background-color: #ccc; }

/* line 943, app/styles/_custom.scss */
.bg-gray.dark {
  background-color: #b9b9b9; }

/* line 947, app/styles/_custom.scss */
.bg-gray.light {
  background-color: #d7d7d7; }

/* Buttons */
/* line 954, app/styles/_custom.scss */
.btn {
  font-size: 13px;
  border: 1px solid transparent;
  border-radius: 0;
  outline: none !important; }

/* line 961, app/styles/_custom.scss */
.btn-addon i {
  position: relative;
  float: left;
  width: 35px;
  height: 35px;
  margin: -7px -13px;
  margin-right: 12px;
  line-height: 34px;
  text-align: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: rgba(0, 0, 0, 0.1); }

/* line 975, app/styles/_custom.scss */
.btn-rounded.btn-addon i {
  margin: -7px -16px;
  margin-right: 15px;
  border-radius: 50px; }

/* line 981, app/styles/_custom.scss */
.btn-rounded.btn-addon.btn-sm i {
  margin: -7px -16px;
  margin-right: 10px;
  border-radius: 50px; }

/* line 987, app/styles/_custom.scss */
.btn-lg {
  line-height: inherit; }

/* line 991, app/styles/_custom.scss */
.btn-lg.btn-addon i {
  width: 45px;
  height: 45px;
  margin: -11px -17px;
  margin-right: 16px;
  line-height: 45px; }

/* line 999, app/styles/_custom.scss */
.btn-sm.btn-addon i {
  width: 30px;
  height: 30px;
  margin: -6px -11px;
  margin-right: 10px;
  line-height: 30px; }

/* line 1007, app/styles/_custom.scss */
.btn-xs.btn-addon i {
  width: 22px;
  height: 22px;
  margin: -2px -6px;
  margin-right: 5px;
  line-height: 22px;
  font-size: 10px; }

/* line 1016, app/styles/_custom.scss */
.btn-default {
  background-color: #fff;
  color: #333;
  border: 1px solid #e5e5e5; }

/* line 1022, app/styles/_custom.scss */
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-group.open .btn-default.dropdown-toggle,
.btn.btn-default.active {
  background-color: #f1f1f1;
  border: 1px solid #e5e5e5; }

/* line 1031, app/styles/_custom.scss */
.btn-primary {
  background-color: #7a6fbe;
  color: #fff;
  border: 1px solid transparent; }

/* line 1037, app/styles/_custom.scss */
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-group.open .btn-primary.dropdown-toggle,
.btn.btn-primary.active {
  background-color: #6a5fac;
  border: 1px solid transparent; }

/* line 1046, app/styles/_custom.scss */
.btn-success {
  background-color: #22BAA0;
  color: #fff;
  border: 1px solid transparent; }

/* line 1052, app/styles/_custom.scss */
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-group.open .btn-success.dropdown-toggle,
.btn.btn-success.active {
  background-color: #1DB198;
  border: 1px solid transparent; }

/* line 1061, app/styles/_custom.scss */
.btn-danger {
  background-color: #f25656;
  color: #fff;
  border: 1px solid transparent; }

/* line 1067, app/styles/_custom.scss */
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-group.open .btn-danger.dropdown-toggle,
.btn.btn-danger.active {
  background-color: #e14444;
  border: 1px solid transparent; }

/* line 1076, app/styles/_custom.scss */
.btn-info {
  background-color: #12AFCB;
  color: #fff;
  border: 1px solid transparent; }

/* line 1082, app/styles/_custom.scss */
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-group.open .btn-info.dropdown-toggle,
.btn.btn-info.active {
  background-color: #08A7C3;
  border: 1px solid transparent; }

/* line 1091, app/styles/_custom.scss */
.btn-warning {
  background-color: #f2ca4c;
  color: #fff;
  border: 1px solid transparent; }

/* line 1097, app/styles/_custom.scss */
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-group.open .btn-warning.dropdown-toggle,
.btn.btn-warning.active {
  background-color: #e8bf40;
  border: 1px solid transparent; }

/* line 1106, app/styles/_custom.scss */
.btn-rounded {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50px; }

/* line 1112, app/styles/_custom.scss */
.btn-group.open .dropdown-toggle {
  box-shadow: none; }

/* Box Shadow */
/* line 1119, app/styles/_custom.scss */
.bs-n {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

/* line 1125, app/styles/_custom.scss */
.bs-dark {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6); }

/* line 1131, app/styles/_custom.scss */
.bs-light {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }

/* line 1137, app/styles/_custom.scss */
.bs-t {
  -webkit-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05); }

/* line 1143, app/styles/_custom.scss */
.bs-r {
  -webkit-box-shadow: 1px 0 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.05); }

/* line 1149, app/styles/_custom.scss */
.bs-b {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

/* line 1155, app/styles/_custom.scss */
.bs-l {
  -webkit-box-shadow: -1px 0 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -1px 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: -1px 0 1px rgba(0, 0, 0, 0.05); }

/* Width & Height */
/* line 1165, app/styles/_custom.scss */
.full-height {
  height: 100% !important; }

/* line 1169, app/styles/_custom.scss */
.full-width {
  width: 100% !important; }

/* line 1173, app/styles/_custom.scss */
.w-xxs {
  width: 50px; }

/* line 1177, app/styles/_custom.scss */
.w-xs {
  width: 90px; }

/* line 1181, app/styles/_custom.scss */
.w-sm {
  width: 140px; }

/* line 1185, app/styles/_custom.scss */
.w {
  width: 200px; }

/* line 1189, app/styles/_custom.scss */
.w-md {
  width: 240px; }

/* line 1193, app/styles/_custom.scss */
.w-lg {
  width: 280px; }

/* line 1197, app/styles/_custom.scss */
.w-xl {
  width: 330px; }

/* line 1201, app/styles/_custom.scss */
.w-xxl {
  width: 370px; }

/* line 1205, app/styles/_custom.scss */
.w-auto {
  width: auto; }

/* line 1209, app/styles/_custom.scss */
.h-auto {
  height: auto; }

/* Tooltip */
/* line 1216, app/styles/_custom.scss */
.tooltip-inner {
  border-radius: 0; }

/* Panel */
/* line 1223, app/styles/_custom.scss */
.panel {
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  border: 0 !important;
  margin-bottom: 25px;
  border-radius: 0; }

@media (min-width: 768px) {
  /* line 1233, app/styles/_custom.scss */
  .panel > .row > div:first-child {
    padding-left: 15px;
    padding-right: 0; }
  /* line 1238, app/styles/_custom.scss */
  .panel > .row > div:last-child {
    padding-left: 0;
    padding-right: 15px; } }

/* line 1244, app/styles/_custom.scss */
.panel .panel-heading {
  padding: 20px 20px 20px 20px;
  overflow: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0 !important;
  height: 55px;
  font-size: 14px;
  font-weight: 600; }

/* line 1255, app/styles/_custom.scss */
.panel .panel-heading .panel-title {
  font-size: 14px;
  float: left;
  margin: 0px;
  padding: 0px;
  font-weight: 600; }

/* line 1263, app/styles/_custom.scss */
.panel .panel-heading .panel-title small {
  font-size: 80%; }

/* line 1267, app/styles/_custom.scss */
.panel .panel-heading .panel-control {
  float: right;
  height: auto; }

/* line 1272, app/styles/_custom.scss */
.panel .panel-heading .panel-control a {
  text-decoration: none;
  color: #ccc;
  margin-left: 5px;
  font-size: 14px;
  float: left; }

/* line 1280, app/styles/_custom.scss */
.panel .panel-heading .panel-control a:hover {
  color: #a2a2a2; }

/* line 1284, app/styles/_custom.scss */
.panel .panel-body {
  position: relative;
  padding: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1291, app/styles/_custom.scss */
.panel .panel-heading + .panel-body {
  padding: 0 20px 20px 20px; }

/* line 1295, app/styles/_custom.scss */
.panel-default .panel-heading {
  background: #f1f1f1;
  border-color: #e7e7e7; }

/* line 1300, app/styles/_custom.scss */
.panel-default .panel-heading .panel-control a {
  color: #bbb; }

/* line 1304, app/styles/_custom.scss */
.panel-default .panel-heading .panel-control a:hover {
  color: #a2a2a2; }

/* line 1308, app/styles/_custom.scss */
.panel-primary .panel-heading {
  background: #7a6fbe;
  color: #fff; }

/* line 1313, app/styles/_custom.scss */
.panel-primary .panel-heading .panel-control a {
  color: #fff; }

/* line 1317, app/styles/_custom.scss */
.panel-primary .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1321, app/styles/_custom.scss */
.panel-success .panel-heading {
  background: #22BAA0;
  color: #fff; }

/* line 1326, app/styles/_custom.scss */
.panel-success .panel-heading .panel-control a {
  color: #fff; }

/* line 1330, app/styles/_custom.scss */
.panel-success .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1334, app/styles/_custom.scss */
.panel-info .panel-heading {
  background: #12AFCB;
  color: #fff; }

/* line 1339, app/styles/_custom.scss */
.panel-info .panel-heading .panel-control a {
  color: #fff; }

/* line 1343, app/styles/_custom.scss */
.panel-info .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1347, app/styles/_custom.scss */
.panel-warning .panel-heading {
  background: #f6d433;
  color: #fff; }

/* line 1352, app/styles/_custom.scss */
.panel-warning .panel-heading .panel-control a {
  color: #fff; }

/* line 1356, app/styles/_custom.scss */
.panel-warning .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1360, app/styles/_custom.scss */
.panel-danger .panel-heading {
  background: #f25656;
  color: #fff; }

/* line 1365, app/styles/_custom.scss */
.panel-danger .panel-heading .panel-control a {
  color: #fff; }

/* line 1369, app/styles/_custom.scss */
.panel-danger .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1373, app/styles/_custom.scss */
.panel-transparent {
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

/* line 1380, app/styles/_custom.scss */
.panel-transparent .panel-heading .panel-control a {
  color: #ccc; }

/* line 1384, app/styles/_custom.scss */
.panel-transparent .panel-heading .panel-control a:hover {
  color: #a2a2a2; }

/* line 1388, app/styles/_custom.scss */
.panel-white {
  background: #fff; }

/* line 1392, app/styles/_custom.scss */
.panel-white .panel-heading .panel-control a {
  color: #ccc; }

/* line 1396, app/styles/_custom.scss */
.panel-white .panel-heading .panel-control a:hover {
  color: #a2a2a2; }

/* line 1400, app/styles/_custom.scss */
.panel-purple {
  background: #7a6fbe; }

/* line 1404, app/styles/_custom.scss */
.panel-purple .panel-heading {
  color: #fff; }

/* line 1408, app/styles/_custom.scss */
.panel-purple .panel-body {
  color: #fff; }

/* line 1412, app/styles/_custom.scss */
.panel-purple .panel-heading .panel-control a {
  color: #fff; }

/* line 1416, app/styles/_custom.scss */
.panel-purple .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1420, app/styles/_custom.scss */
.panel-green {
  background: #22BAA0; }

/* line 1424, app/styles/_custom.scss */
.panel-green .panel-heading {
  color: #fff; }

/* line 1428, app/styles/_custom.scss */
.panel-green .panel-body {
  color: #fff; }

/* line 1432, app/styles/_custom.scss */
.panel-green .panel-heading .panel-control a {
  color: #fff; }

/* line 1436, app/styles/_custom.scss */
.panel-green .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1440, app/styles/_custom.scss */
.panel-blue {
  background: #12AFCB; }

/* line 1444, app/styles/_custom.scss */
.panel-blue .panel-heading {
  color: #fff; }

/* line 1448, app/styles/_custom.scss */
.panel-blue .panel-body {
  color: #fff; }

/* line 1452, app/styles/_custom.scss */
.panel-blue .panel-heading .panel-control a {
  color: #fff; }

/* line 1456, app/styles/_custom.scss */
.panel-blue .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1460, app/styles/_custom.scss */
.panel-yellow {
  background: #f6d433; }

/* line 1464, app/styles/_custom.scss */
.panel-yellow .panel-heading {
  color: #fff; }

/* line 1468, app/styles/_custom.scss */
.panel-yellow .panel-body {
  color: #fff; }

/* line 1472, app/styles/_custom.scss */
.panel-yellow .panel-heading .panel-control a {
  color: #fff; }

/* line 1476, app/styles/_custom.scss */
.panel-yellow .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1480, app/styles/_custom.scss */
.panel-red {
  background: #f25656; }

/* line 1484, app/styles/_custom.scss */
.panel-red .panel-heading {
  color: #fff; }

/* line 1488, app/styles/_custom.scss */
.panel-red .panel-body {
  color: #fff; }

/* line 1492, app/styles/_custom.scss */
.panel-red .panel-heading .panel-control a {
  color: #fff; }

/* line 1496, app/styles/_custom.scss */
.panel-red .panel-heading .panel-control a:hover {
  color: #f1f1f1; }

/* line 1500, app/styles/_custom.scss */
.panel-placeholder {
  border: 2px dashed #ddd;
  margin-bottom: 20px; }

/* Wells */
/* line 1508, app/styles/_custom.scss */
.well {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #F6F6F6;
  border: 0; }

/* Modals */
/* line 1520, app/styles/_custom.scss */
.modal {
  z-index: 9999; }

/* line 1524, app/styles/_custom.scss */
.modal-content {
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0; }

/* line 1532, app/styles/_custom.scss */
.modal-header {
  padding: 20px 20px 20px 20px;
  overflow: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0 !important;
  height: 55px;
  font-size: 14px;
  font-weight: 600; }

/* line 1543, app/styles/_custom.scss */
.modal-body {
  position: relative;
  padding: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1550, app/styles/_custom.scss */
.modal-footer {
  position: relative;
  padding: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 0; }

/* line 1558, app/styles/_custom.scss */
.modal-header + .modal-body {
  padding: 0 20px 20px 20px; }

/* line 1562, app/styles/_custom.scss */
.modal-body + .modal-footer {
  padding: 0 20px 20px 20px; }

/* line 1566, app/styles/_custom.scss */
.modal-open {
  overflow-y: auto !important; }

/* line 1570, app/styles/_custom.scss */
.modal-open {
  padding: 0 !important; }

/* line 1574, app/styles/_custom.scss */
.modal-backdrop {
  z-index: 999999; }

/* Pagination */
/* line 1580, app/styles/_custom.scss */
.pagination > li > a {
  color: #5B5B5B; }

/* line 1584, app/styles/_custom.scss */
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: #22BAA0;
  border-color: #22BAA0; }

/* Progress Bars */
/* line 1597, app/styles/_custom.scss */
.progress {
  border-radius: 2px; }

/* line 1601, app/styles/_custom.scss */
.progress-squared {
  border-radius: 0; }

/* line 1605, app/styles/_custom.scss */
.progress-xs {
  height: 5px; }

/* line 1609, app/styles/_custom.scss */
.progress-sm {
  height: 10px; }

/* line 1613, app/styles/_custom.scss */
.progress-md {
  height: 15px; }

/* line 1617, app/styles/_custom.scss */
.progress-lg {
  height: 25px; }

/* line 1621, app/styles/_custom.scss */
.progress-bar {
  background-color: #7a6fbe; }

/* line 1625, app/styles/_custom.scss */
.progress-bar-success {
  background-color: #22BAA0; }

/* line 1629, app/styles/_custom.scss */
.progress-bar-info {
  background-color: #12AFCB; }

/* line 1633, app/styles/_custom.scss */
.progress-bar-warning {
  background-color: #f6d433; }

/* line 1637, app/styles/_custom.scss */
.progress-bar-danger {
  background-color: #f25656; }

/* Tabs */
/* line 1644, app/styles/_custom.scss */
.tab-content {
  padding: 15px;
  background: #fff; }

/* line 1650, app/styles/_custom.scss */
.nav-tabs > li > a {
  border-radius: 0 !important;
  color: #777;
  border-bottom: 1px solid #DDDDDD; }

/* line 1656, app/styles/_custom.scss */
.nav-tabs > li > a:hover {
  background: #f1f1f1; }

/* line 1660, app/styles/_custom.scss */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border-bottom-color: #fff; }

/* line 1669, app/styles/_custom.scss */
.nav-pills > li > a {
  color: #777;
  border-radius: 0 !important; }

/* line 1674, app/styles/_custom.scss */
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #7a6fbe; }

/* line 1681, app/styles/_custom.scss */
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0; }

/* line 1687, app/styles/_custom.scss */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none; }

/* line 1692, app/styles/_custom.scss */
.tab-content > .active,
.pill-content > .active {
  display: block; }

/* line 1697, app/styles/_custom.scss */
.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd; }

/* line 1701, app/styles/_custom.scss */
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0; }

/* line 1706, app/styles/_custom.scss */
.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

/* line 1712, app/styles/_custom.scss */
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent; }

/* line 1718, app/styles/_custom.scss */
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: #fff #ddd #ddd #ddd; }

/* line 1724, app/styles/_custom.scss */
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none; }

/* line 1729, app/styles/_custom.scss */
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px; }

/* line 1736, app/styles/_custom.scss */
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd; }

/* line 1742, app/styles/_custom.scss */
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

/* line 1749, app/styles/_custom.scss */
.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee; }

/* line 1754, app/styles/_custom.scss */
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  border-right-color: #ffffff; }

/* line 1761, app/styles/_custom.scss */
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd; }

/* line 1767, app/styles/_custom.scss */
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

/* line 1774, app/styles/_custom.scss */
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd; }

/* line 1779, app/styles/_custom.scss */
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  border-left-color: #ffffff; }

/* Accordions */
/* line 1789, app/styles/_custom.scss */
.panel-group .panel-heading .panel-title > a {
  font-size: 14px; }

/* line 1793, app/styles/_custom.scss */
.panel-group .panel-heading {
  font-size: 14px; }

/* line 1797, app/styles/_custom.scss */
.panel-group .panel-heading .panel-title > a {
  text-decoration: none; }

/* line 1801, app/styles/_custom.scss */
.panel-group .panel-heading {
  border-bottom: 0 !important; }

/* line 1805, app/styles/_custom.scss */
.panel > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: transparent; }

/* line 1809, app/styles/_custom.scss */
.panel-group .panel-heading .panel-title {
  position: relative;
  width: 100%; }

/* line 1814, app/styles/_custom.scss */
.panel-group .panel-heading .panel-title > a:after {
  font-family: 'FontAwesome';
  content: "\f067";
  position: absolute;
  right: 0;
  color: #777;
  height: 15px;
  top: 50%;
  margin-top: -7.5px; }

/* line 1825, app/styles/_custom.scss */
.panel-group .panel-heading .panel-title > a.collapsed:after {
  content: "\f068"; }

/* line 1829, app/styles/_custom.scss */
.panel-group .panel-primary .panel-heading .panel-title > a:after,
.panel-group .panel-success .panel-heading .panel-title > a:after,
.panel-group .panel-info .panel-heading .panel-title > a:after,
.panel-group .panel-danger .panel-heading .panel-title > a:after,
.panel-group .panel-warning .panel-heading .panel-title > a:after {
  color: #fff; }

/* jsTree */
/* line 1840, app/styles/_custom.scss */
.jstree-default .jstree-clicked {
  background: #f1f1f1;
  border-radius: 0;
  box-shadow: inset 0 0 1px #999; }

/* line 1846, app/styles/_custom.scss */
.jstree-default li a:hover {
  background: #f1f1f1;
  border-radius: 0;
  box-shadow: inset 0 0 1px #999; }

/* Labels */
/* line 1856, app/styles/_custom.scss */
.label.label-default {
  background: #fff;
  border: 1px solid #e5e5e5; }

/* line 1861, app/styles/_custom.scss */
.label.label-success {
  background: #22BAA0; }

/* line 1865, app/styles/_custom.scss */
.label.label-primary {
  background: #7a6fbe; }

/* line 1869, app/styles/_custom.scss */
.label.label-info {
  background: #12AFCB; }

/* line 1873, app/styles/_custom.scss */
.label.label-danger {
  background: #f25656; }

/* line 1877, app/styles/_custom.scss */
.label.label-warning {
  background: #f6d433; }

/* Forms */
/* line 1884, app/styles/_custom.scss */
label {
  font-size: 13px;
  font-weight: normal; }

/* line 1889, app/styles/_custom.scss */
.controls label {
  display: block; }

/* line 1893, app/styles/_custom.scss */
.form-control {
  background: #fff;
  border-radius: 0;
  border: 1px solid #dce1e4;
  box-shadow: none !important;
  font-size: 13px;
  padding: 6px 10px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

/* line 1906, app/styles/_custom.scss */
input[disabled] {
  background: #f7f7f7;
  border-color: #d2d7db; }

/* line 1911, app/styles/_custom.scss */
.form-control:focus {
  border: 1px solid #bdc5c9;
  background: #fff; }

/* line 1916, app/styles/_custom.scss */
.help-block {
  margin-bottom: 5px; }

/* line 1920, app/styles/_custom.scss */
.form-group.has-success .form-control,
.form-group.has-warning .form-control,
.form-group.has-error .form-control {
  box-shadow: none; }

/* line 1926, app/styles/_custom.scss */
.form-group.has-success .form-control:focus {
  border: 1px solid #22BAA0;
  background: #fff; }

/* line 1931, app/styles/_custom.scss */
.form-group.has-warning .form-control:focus {
  border: 1px solid #f6d433;
  background: #fff; }

/* line 1936, app/styles/_custom.scss */
.form-group.has-error .form-control:focus {
  border: 1px solid #f25656;
  background: #fff; }

/* line 1941, app/styles/_custom.scss */
.form-group.has-success label {
  color: #22BAA0; }

/* line 1945, app/styles/_custom.scss */
.form-group.has-warning label {
  color: #f6d433; }

/* line 1949, app/styles/_custom.scss */
.form-group.has-error label {
  color: #f25656; }

/* line 1953, app/styles/_custom.scss */
label.error {
  color: #a94442;
  font-weight: bold;
  margin-top: 5px;
  float: left;
  font-size: 12px; }

/* line 1961, app/styles/_custom.scss */
input.form-control.input-rounded {
  border-radius: 30px; }

/* line 1965, app/styles/_custom.scss */
.input-group .btn {
  padding: 7px 12px; }

/* line 1969, app/styles/_custom.scss */
ol.linenums {
  margin: 0; }

/*
.select2-selection {
    border-radius: 0!important;
    border: 1px solid #dce1e4!important;
    box-shadow: none!important;
    font-size: 13px!important;
    padding: 6px 10px!important;
    height: 34px!important;
    -webkit-transition: all 0.2s ease-in-out!important;
    -moz-transition: all 0.2s ease-in-out!important;
    -o-transition: all 0.2s ease-in-out!important;
    transition: all 0.2s ease-in-out!important;
}

.select2-dropdown {
    border-radius: 0;
    border: 1px solid #dce1e4;
    border-top: 0;
    box-shadow: none!important;
    font-size: 13px;
    padding: 6px 10px!important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    z-index: 9999;
}

.select2-dropdown.select2-dropdown--above {
    border: 1px solid #dce1e4;
    border-bottom: 0;
}

.select2-selection--multiple .select2-selection__choice {
    background: #f1f1f1!important;
    border-radius: 0!important;
    border: 1px solid #ccc!important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    line-height: inherit!important;
    padding: 0!important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 0!important;
}
*/
/* line 2022, app/styles/_custom.scss */
.popover {
  border: 1px solid #ebebeb;
  border-radius: 0;
  box-shadow: none;
  padding: 0; }

/* line 2029, app/styles/_custom.scss */
.popover-title {
  border-radius: 0; }

/* line 2033, app/styles/_custom.scss */
.popover.top > .arrow {
  border-top-color: #ebebeb; }

/* line 2037, app/styles/_custom.scss */
.popover.left > .arrow {
  border-left-color: #ebebeb; }

/* line 2041, app/styles/_custom.scss */
.popover.right > .arrow {
  border-right-color: #ebebeb; }

/* line 2045, app/styles/_custom.scss */
.popover.bottom > .arrow {
  border-bottom-color: #ebebeb; }

/* Tables */
/*
.table > tbody > tr > td, 
.table > tbody > tr > th,
.table > tfoot > tr > td, 
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th,
.table td {
    padding: 15px!important;
}
*/
/* line 2062, app/styles/_custom.scss */
.dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_length select {
  background: #fff;
  border-radius: 0;
  border: 1px solid #dce1e4;
  box-shadow: none !important;
  font-size: 13px;
  padding: 6px 10px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

/* line 2076, app/styles/_custom.scss */
.dataTables_wrapper .dataTables_filter input:focus {
  border: 1px solid #bdc5c9;
  background: #fff;
  outline: 0; }

/* line 2082, app/styles/_custom.scss */
.dataTables_wrapper .dataTables_length select:focus {
  outline: 0; }

/* line 2086, app/styles/_custom.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #5f5f5f !important;
  border: 1px solid #ebebeb;
  background: #f1f1f1 !important; }

/* line 2093, app/styles/_custom.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
  color: #5f5f5f !important;
  border: 1px solid transparent;
  background: #f7f7f7 !important;
  box-shadow: none; }

/* line 2102, app/styles/_custom.scss */
tr.group,
tr.group:hover {
  background-color: #ddd !important; }

/* Wysiwyg */
/* line 2110, app/styles/_custom.scss */
.note-editor {
  border: 1px solid #DCE1E4;
  border-radius: 0; }

/* line 2115, app/styles/_custom.scss */
.note-dialog .note-modal-form {
  margin: 0 15px 0 15px; }

/* line 2119, app/styles/_custom.scss */
.note-editor .note-toolbar {
  border-bottom: 0;
  background: #fff;
  padding: 10px; }

/* line 2125, app/styles/_custom.scss */
.note-popover .popover .popover-content > .btn-group, .note-toolbar > .btn-group {
  margin-top: 0; }

/* line 2129, app/styles/_custom.scss */
.note-editor .note-editable {
  padding: 15px;
  padding-top: 10px;
  overflow: auto;
  outline: 0; }

/* line 2136, app/styles/_custom.scss */
.note-editor .note-statusbar {
  background: transparent; }

/* line 2140, app/styles/_custom.scss */
.note-editor .note-statusbar .note-resizebar {
  border: 0; }

/* Center */
/* line 2147, app/styles/_custom.scss */
.center {
  margin: 0 auto;
  float: none !important;
  display: block; }

/* Display */
/* line 2156, app/styles/_custom.scss */
.display-block {
  display: block; }

/* line 2160, app/styles/_custom.scss */
.display-inline {
  display: inline-block; }

/* Middle Box */
/* line 2167, app/styles/_custom.scss */
.box-middle {
  position: absolute;
  height: 400px;
  width: 400px;
  top: 50%;
  left: 50%;
  margin-left: -200px !important;
  margin-top: -200px !important; }

/* Date Picker */
/* line 2180, app/styles/_custom.scss */
.datepicker {
  padding: 10px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0 !important;
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1) !important; }

/* line 2189, app/styles/_custom.scss */
.datepicker table tr td,
.datepicker table tr th,
.datepicker table tr td span {
  border-radius: 0 !important; }

/* line 2195, app/styles/_custom.scss */
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
  color: #ffffff;
  background-color: #7a6fbe;
  border: 0; }

/* line 2220, app/styles/_custom.scss */
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  color: #ffffff;
  background-color: #7a6fbe;
  border: 0; }

/* Colorpicker */
/* line 2248, app/styles/_custom.scss */
.colorpicker {
  padding: 10px !important; }

/* Dropzone */
/* line 2255, app/styles/_custom.scss */
.dropzone {
  border: 1px solid #f1f1f1;
  border-radius: 0;
  background: #f9f9f9; }

/* Image Cropper */
/* line 2264, app/styles/_custom.scss */
.img-preview {
  height: 150px;
  width: 220px;
  overflow: hidden;
  text-align: center; }

/* Sparkline Charts */
/* line 2274, app/styles/_custom.scss */
.jqstooltip {
  box-sizing: content-box; }

/* Flot Chart */
/* line 2281, app/styles/_custom.scss */
#flot1 {
  width: 100%;
  height: 250px; }

/* line 2286, app/styles/_custom.scss */
#flot2 {
  width: 100%;
  height: 250px; }

/* line 2291, app/styles/_custom.scss */
#flot3 {
  margin: 0 auto;
  width: 250px;
  height: 250px; }

/* line 2297, app/styles/_custom.scss */
#flot3 .pie-chart-legend {
  padding: 5px; }

/* line 2301, app/styles/_custom.scss */
#flot4 {
  width: 100%;
  height: 250px; }

/* line 2306, app/styles/_custom.scss */
#flotTip {
  border-radius: 0;
  background: rgba(51, 51, 51, 0.8);
  color: #fff;
  padding: 5px;
  font-size: 13px !important; }

/* Vector Maps */
/* line 2317, app/styles/_custom.scss */
.jqvmap-zoomin,
.jqvmap-zoomout {
  background-color: #7a6fbe;
  color: #fff;
  border: 1px solid transparent;
  font-size: 13px;
  border-radius: 0;
  outline: none !important;
  padding: 3px 5px;
  height: auto;
  width: auto;
  margin-left: 7px; }

/* line 2331, app/styles/_custom.scss */
.jqvmap-zoomin:hover,
.jqvmap-zoomin:focus,
.jqvmap-zoomin:active,
.jqvmap-zoomout:hover,
.jqvmap-zoomout:focus,
.jqvmap-zoomout:active {
  background-color: #6a5fac;
  border: 1px solid transparent; }

/* Gritter Notifications */
/* line 2344, app/styles/_custom.scss */
.gritter-item {
  font-size: 13px;
  background: rgba(0, 0, 0, 0.7);
  background-image: none !important;
  padding: 15px; }

/* line 2351, app/styles/_custom.scss */
.gritter-top {
  background: none !important; }

/* line 2355, app/styles/_custom.scss */
.gritter-bottom {
  background: none !important; }

/* line 2359, app/styles/_custom.scss */
.gritter-close {
  top: 15px;
  left: auto;
  right: 5px; }

/* line 2365, app/styles/_custom.scss */
.gritter-image {
  width: 50px;
  height: 50px;
  border-radius: 100%; }

/* line 2371, app/styles/_custom.scss */
.gritter-with-image {
  padding-left: 10px; }

/* Calendar */
/* line 2378, app/styles/_custom.scss */
.fc button {
  height: auto;
  padding: 6px 12px;
  font-size: 13px; }

/* line 2385, app/styles/_custom.scss */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0; }

/* line 2390, app/styles/_custom.scss */
.fc-state-default {
  border: 1px solid; }

/* line 2394, app/styles/_custom.scss */
.fc-state-default.fc-corner-left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

/* line 2399, app/styles/_custom.scss */
.fc-state-default.fc-corner-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

/* line 2404, app/styles/_custom.scss */
.fc-state-default {
  background-color: #fff;
  background-image: none;
  border-color: #e5e5e5;
  color: #333;
  text-shadow: 0;
  box-shadow: 0;
  outline: 0 !important; }

/* line 2414, app/styles/_custom.scss */
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6; }

/* line 2422, app/styles/_custom.scss */
.fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none; }

/* line 2432, app/styles/_custom.scss */
.fc-state-down,
.fc-state-active {
  background-color: #f1f1f1;
  border: 1px solid #e5e5e5;
  box-shadow: 0 !important; }

/* line 2439, app/styles/_custom.scss */
.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none; }

/* line 2447, app/styles/_custom.scss */
.fc-day-grid-event {
  padding: 5px;
  background: #22BAA0;
  border: 0; }

/* List Groups */
/* line 2456, app/styles/_custom.scss */
.list-group-item.active {
  background: #f1f1f1;
  border-color: #DDDDDD;
  color: #333; }

/* line 2462, app/styles/_custom.scss */
.list-group-item.active:hover {
  background: #e7e7e7;
  border-color: #DDDDDD;
  color: #333; }

/* Header */
/* line 2471, app/styles/_custom.scss */
.navbar {
  border: none;
  background: #FFF;
  padding: 0px;
  height: 60px;
  margin: 0px;
  z-index: 999;
  border-radius: 0px;
  border: none;
  margin: auto;
  position: relative;
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1); }

/* line 2487, app/styles/_custom.scss */
.page-header-fixed .navbar {
  position: fixed;
  right: 0;
  left: 0; }

@media (min-width: 768px) {
  /* line 2494, app/styles/_custom.scss */
  .page-content.container .navbar {
    width: 750px; } }

@media (min-width: 992px) {
  /* line 2500, app/styles/_custom.scss */
  .page-content.container .navbar {
    width: 970px; } }

@media (min-width: 1200px) {
  /* line 2506, app/styles/_custom.scss */
  .page-content.container .navbar {
    width: 1170px; } }

/* line 2511, app/styles/_custom.scss */
.topmenu-outer {
  height: 60px;
  background: #fff;
  margin-left: 160px; }

/* line 2517, app/styles/_custom.scss */
.small-sidebar .topmenu-outer {
  margin-left: 65px; }

/* line 2521, app/styles/_custom.scss */
.pace .pace-progress {
  background: #22BAA0; }

/* line 2525, app/styles/_custom.scss */
.pace .pace-progress-inner {
  box-shadow: 0 0 10px #22BAA0, 0 0 5px #22BAA0; }

/* line 2529, app/styles/_custom.scss */
.pace .pace-activity {
  border-top-color: #22BAA0;
  border-left-color: #22BAA0;
  margin-top: 60px; }

/* logo */
/* line 2538, app/styles/_custom.scss */
.navbar .logo-box {
  position: relative;
  width: 160px;
  height: 60px;
  float: left;
  display: table; }

/* line 2546, app/styles/_custom.scss */
.navbar .logo-box .logo-text {
  font-size: 20px;
  font-weight: bold;
  line-height: 60px;
  padding: 0 15px 0 15px;
  vertical-align: middle;
  display: table-cell;
  text-align: center; }

/* line 2556, app/styles/_custom.scss */
.navbar .logo-box a:hover,
.navbar .logo-box a:focus {
  text-decoration: none; }

/* line 2561, app/styles/_custom.scss */
.navbar .logo-box a {
  margin: 0 auto; }

/* line 2565, app/styles/_custom.scss */
.small-sidebar .navbar .logo-box {
  width: 65px; }

/* Top Menu */
/* line 2572, app/styles/_custom.scss */
.navbar-nav.navbar-right {
  margin-right: 0; }

/* line 2576, app/styles/_custom.scss */
.top-menu .navbar-nav > li {
  height: 49px; }

/* line 2580, app/styles/_custom.scss */
.top-menu .navbar-nav > li > a {
  display: block;
  padding: 20px 18px;
  color: #5f5f5f;
  border: none;
  position: relative;
  line-height: 20px; }

/* line 2589, app/styles/_custom.scss */
.top-menu .nav > li > a:hover,
.top-menu .nav > li > a:focus,
.top-menu .nav > li > a:active,
.top-menu .nav .open > a,
.top-menu .nav .open > a:hover,
.top-menu .nav .open > a:focus {
  background: transparent; }

/* line 2598, app/styles/_custom.scss */
.top-menu .nav .open > a,
.top-menu .nav .open > a:hover,
.top-menu .nav .open > a:focus {
  background: transparent;
  border-color: none; }

/* line 2605, app/styles/_custom.scss */
.top-menu .navbar-nav > li > a > i {
  font-size: 14px; }

/* line 2609, app/styles/_custom.scss */
.top-menu .navbar-nav > li > a > .badge {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 12px;
  right: 7px;
  padding: 3px 6px;
  font-size: 11px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3); }

/* line 2619, app/styles/_custom.scss */
.top-menu .navbar-nav > li > a > .user-name {
  padding: 0 10px 0 0;
  line-height: 20px; }

/* line 2624, app/styles/_custom.scss */
.top-menu .navbar-nav > li > a > .user-name {
  padding: 0 10px 0 0;
  line-height: 20px; }

@media (max-width: 991px) {
  /* line 2630, app/styles/_custom.scss */
  .top-menu .navbar-nav > li > a > .user-name {
    display: none; } }

/* line 2635, app/styles/_custom.scss */
.top-menu .navbar-nav > li > a > .user-name > i {
  margin-left: 5px; }

/* line 2639, app/styles/_custom.scss */
.avatar {
  padding: 0px;
  margin: -19px 0 -15px 0;
  position: relative;
  display: inline-block; }

/* Dropdowns */
/* line 2649, app/styles/_custom.scss */
.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  background-color: #fffff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #d8dee4;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1); }

/* line 2674, app/styles/_custom.scss */
.dropdown-menu li {
  font-size: 13px; }

/* line 2678, app/styles/_custom.scss */
.dropdown-menu li a:hover {
  background: #f7f7f7; }

/* line 2682, app/styles/_custom.scss */
.navbar-nav > li > .dropdown-menu {
  margin-top: 25px !important; }

/* line 2686, app/styles/_custom.scss */
.dropdown-menu:before {
  position: absolute;
  top: -8px;
  left: 9px;
  right: auto;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #d8dee4;
  border-left: 8px solid transparent;
  content: ''; }

/* line 2698, app/styles/_custom.scss */
.dropdown-menu:after {
  position: absolute;
  top: -7px;
  left: 10px;
  right: auto;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: ''; }

/* line 2710, app/styles/_custom.scss */
.dropdown.dropup > .dropdown-menu:after,
.dropdown.dropup > .dropdown-menu:before,
.btn-group.dropup > .dropdown-menu:after,
.btn-group.dropup > .dropdown-menu:before,
.dropdown-toggle.dropup > .dropdown-menu:after,
.dropdown-toggle.dropup > .dropdown-menu:before {
  display: none !important; }

/* line 2719, app/styles/_custom.scss */
.navbar-left .dropdown-menu:before,
.dropdown-menu.dropdown-menu-left:before {
  left: 16px;
  right: auto; }

/* line 2725, app/styles/_custom.scss */
.navbar-left .dropdown-menu:after,
.dropdown-menu.dropdown-menu-left:after {
  left: 17px;
  right: auto; }

/* line 2731, app/styles/_custom.scss */
.navbar-right .dropdown-menu:before,
.dropdown-menu.dropdown-menu-right:before {
  right: 16px;
  left: auto; }

/* line 2737, app/styles/_custom.scss */
.navbar-right .dropdown-menu:after,
.dropdown-menu.dropdown-menu-right:after {
  right: 17px;
  left: auto; }

/* line 2743, app/styles/_custom.scss */
.dropdown-menu.title-caret:after {
  border-bottom: 7px solid #fff; }

/* line 2747, app/styles/_custom.scss */
.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus,
.dropdown-menu > li.active > a:active,
.dropdown-menu > li.active > a,
.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus {
  background: #F7F7F7;
  color: #333; }

/* line 2757, app/styles/_custom.scss */
.drop-title {
  display: block;
  border-bottom: 1px solid #F3F3F3;
  font-size: 14px;
  background: #fff;
  padding: 12px;
  color: #6a6a6a;
  margin: 0px; }

/* line 2767, app/styles/_custom.scss */
.dropdown-menu li.drop-all a {
  padding: 10px;
  color: #707070;
  background: #FFF;
  font-size: 14px; }

/* line 2774, app/styles/_custom.scss */
.dropdown-menu li.drop-all a:hover {
  background: #f7f7f7; }

/* line 2778, app/styles/_custom.scss */
.dropdown-sm {
  width: 190px; }

/* line 2782, app/styles/_custom.scss */
.dropdown-md {
  width: 230px; }

/* line 2786, app/styles/_custom.scss */
.dropdown-lg {
  width: 280px; }

/* line 2790, app/styles/_custom.scss */
.dropdown-menu li a {
  padding: 7px 10px 7px 10px;
  color: #5f5f5f;
  font-size: 13px; }

/* line 2796, app/styles/_custom.scss */
.dropdown-menu li a i {
  margin-right: 10px; }

/* line 2800, app/styles/_custom.scss */
.dropdown-menu li.no-link {
  padding: 7px 10px 7px 10px; }

/* line 2804, app/styles/_custom.scss */
.dropdown-menu li.li-group {
  border-bottom: 1px solid #f1f1f1;
  padding: 5px 0px 5px 0px; }

/* line 2809, app/styles/_custom.scss */
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eee;
  border-color: transparent; }

/* line 2816, app/styles/_custom.scss */
.navbar .dropdown .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  margin-top: 40px !important; }

/* line 2828, app/styles/_custom.scss */
.navbar .open .dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 25px !important; }

/* line 2834, app/styles/_custom.scss */
.navbar .dropdown {
  display: inline-block; }

/* Messages */
/* line 2841, app/styles/_custom.scss */
.messages {
  max-height: 300px; }

/* line 2845, app/styles/_custom.scss */
.messages li a {
  color: #bababa;
  font-size: 13px;
  padding-left: 15px;
  text-decoration: none;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative; }

/* line 2856, app/styles/_custom.scss */
.messages li a:hover {
  background: #f7f7f7; }

/* line 2860, app/styles/_custom.scss */
.messages li {
  border-bottom: 1px solid #f3f3f3; }

/* line 2864, app/styles/_custom.scss */
.messages li a .msg-img {
  height: 100%;
  display: block;
  float: left;
  position: relative; }

/* line 2871, app/styles/_custom.scss */
.online {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #FFF;
  background: #ccc; }

/* line 2883, app/styles/_custom.scss */
.online.on {
  background: #44AEA1; }

/* line 2887, app/styles/_custom.scss */
.online.off {
  background: #f3565d; }

/* line 2891, app/styles/_custom.scss */
.messages li a img {
  width: 40px; }

/* line 2895, app/styles/_custom.scss */
.messages li a .msg-name {
  display: block;
  font-size: 14px;
  color: #707070;
  padding: 0 0 0 10px;
  margin: 0px;
  float: left; }

/* line 2904, app/styles/_custom.scss */
.messages li a .msg-text {
  display: block;
  width: 210px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #a0a0a0;
  margin: 0px;
  float: left;
  padding: 0 0 0 10px; }

/* line 2915, app/styles/_custom.scss */
.messages li a .msg-time {
  font-size: 11px;
  display: block;
  width: 210px;
  padding: 0 4px 0 8px;
  margin: 0px;
  float: right; }

/* Tasks */
/* line 2927, app/styles/_custom.scss */
.tasks {
  max-height: 300px; }

/* line 2931, app/styles/_custom.scss */
.tasks li a {
  color: #bababa;
  font-size: 13px;
  padding-left: 15px;
  text-decoration: none;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative; }

/* line 2942, app/styles/_custom.scss */
.tasks li {
  border-bottom: 1px solid #f3f3f3; }

/* line 2946, app/styles/_custom.scss */
.tasks li a:hover {
  background: #f7f7f7; }

/* line 2950, app/styles/_custom.scss */
.task-icon.badge {
  height: 25px;
  width: 25px;
  display: block;
  float: left;
  position: relative;
  margin-right: 10px;
  padding-top: 6px; }

/* line 2960, app/styles/_custom.scss */
.task-icon i {
  text-align: center;
  font-size: 12px; }

/* line 2965, app/styles/_custom.scss */
.tasks li a .task-details {
  display: block;
  font-size: 13px;
  color: #868686;
  padding: 3px 0 0 0;
  margin: 0px;
  float: left;
  width: 148px; }

/* line 2975, app/styles/_custom.scss */
.tasks li a .badge {
  margin: 0px;
  margin-right: 5px;
  max-width: 85px; }

/* Page Content */
/* line 2984, app/styles/_custom.scss */
.page-content {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  background: #f7f8f8;
  min-height: 100%; }

/* line 2992, app/styles/_custom.scss */
.page-content.container {
  padding-left: 0px;
  padding-right: 0px; }

/* Sidebar */
/* line 3000, app/styles/_custom.scss */
.page-sidebar {
  width: 160px;
  display: block;
  z-index: 998;
  float: left;
  height: auto !important;
  overflow: visible !important; }

/* line 3009, app/styles/_custom.scss */
.sidebar .sidebar-title {
  display: block;
  margin: 0 0 10px 0; }

/* line 3014, app/styles/_custom.scss */
.page-sidebar-fixed:not(.page-header-fixed) .logo-box {
  position: fixed;
  display: block; }

/* line 3019, app/styles/_custom.scss */
.page-sidebar-fixed:not(.page-header-fixed) .topmenu-outer {
  margin-left: 160px; }

/* line 3023, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-header-fixed) .topmenu-outer {
  margin-left: 65px; }

/* line 3027, app/styles/_custom.scss */
.page-header-fixed .page-sidebar {
  padding-top: 60px; }

/* line 3032, app/styles/_custom.scss */
.page-sidebar-fixed .sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 60px; }

/* line 3039, app/styles/_custom.scss */
.page-sidebar-fixed .page-sidebar-inner {
  height: 100%; }

/* line 3043, app/styles/_custom.scss */
.page-sidebar .page-sidebar-inner {
  height: 100% !important; }

/* line 3047, app/styles/_custom.scss */
.small-sidebar .page-sidebar {
  width: 65px;
  height: 715px !important;
  position: fixed; }
  /* line 3051, app/styles/_custom.scss */
  .small-sidebar .page-sidebar:hover {
    overflow-y: scroll !important;
    overflow-x: hidden !important; }

/* line 3058, app/styles/_custom.scss */
.small-sidebar .page-sidebar-inner {
  overflow: visible !important; }

/* line 3062, app/styles/_custom.scss */
.sidebar-header {
  overflow: hidden;
  clear: both;
  position: relative;
  padding: 20px; }

/* line 3069, app/styles/_custom.scss */
.sidebar-profile-image {
  display: block; }

/* line 3073, app/styles/_custom.scss */
.sidebar-profile {
  overflow: hidden;
  position: relative;
  display: block; }

/* line 3079, app/styles/_custom.scss */
.sidebar-profile a {
  text-decoration: none; }

/* line 3083, app/styles/_custom.scss */
.sidebar-profile img {
  width: 60px;
  display: block;
  margin: 0 auto; }

/* line 3089, app/styles/_custom.scss */
.sidebar-profile span {
  color: #fff;
  font-weight: 600;
  display: block;
  padding: 8px 0 0 0;
  text-align: center; }

/* line 3097, app/styles/_custom.scss */
.sidebar-profile span small {
  color: #90999c;
  padding: 5px 0; }

/* line 3102, app/styles/_custom.scss */
.small-sidebar .sidebar-header,
.page-horizontal-bar .sidebar-header {
  display: none; }

/* Horizontal Bar */
/* line 3110, app/styles/_custom.scss */
.horizontal-bar {
  width: 100%;
  display: block;
  z-index: 9998;
  float: none;
  height: auto !important;
  left: 0;
  right: 0;
  bottom: 0;
  height: 74px; }

@media (min-width: 768px) {
  /* line 3124, app/styles/_custom.scss */
  .page-sidebar-fixed.page-horizontal-bar .sidebar {
    position: fixed;
    top: 0;
    bottom: auto;
    padding-top: 60px; } }

/* line 3132, app/styles/_custom.scss */
.small-sidebar .horizontal-bar .slimScrollDiv {
  overflow: visible !important; }

/* line 3136, app/styles/_custom.scss */
.page-horizontal-bar.page-header-fixed .horizontal-bar {
  padding-top: 60px; }

/* line 3140, app/styles/_custom.scss */
.page-horizontal-bar.page-sidebar-fixed .navbar {
  z-index: 9999; }

@media (min-width: 768px) {
  /* line 3145, app/styles/_custom.scss */
  .page-horizontal-bar.page-sidebar-fixed .page-content.container .horizontal-bar {
    width: 750px;
    margin: 0 auto; } }

@media (min-width: 992px) {
  /* line 3152, app/styles/_custom.scss */
  .page-horizontal-bar.page-sidebar-fixed .page-content.container .horizontal-bar {
    width: 970px;
    margin: 0 auto; } }

@media (min-width: 1200px) {
  /* line 3159, app/styles/_custom.scss */
  .page-horizontal-bar.page-sidebar-fixed .page-content.container .horizontal-bar {
    width: 1170px;
    margin: 0 auto; } }

@media (max-width: 1200px) {
  /* line 3166, app/styles/_custom.scss */
  .horizontal-bar .accordion-menu > li > a > p {
    display: none; }
  /* line 3170, app/styles/_custom.scss */
  .compact-menu .horizontal-bar .accordion-menu > li > a > p {
    display: none !important; }
  /* line 3174, app/styles/_custom.scss */
  body:not(.small-sidebar) .horizontal-bar .accordion-menu > li > a > .menu-icon {
    float: none !important;
    margin: 0 !important; }
  /* line 3179, app/styles/_custom.scss */
  .horizontal-bar .accordion-menu ul {
    top: 50px !important; } }

/* line 3185, app/styles/_custom.scss */
.horizontal-bar .page-sidebar-inner {
  overflow: visible !important; }

/* line 3189, app/styles/_custom.scss */
.horizontal-bar .accordion-menu {
  text-align: center;
  font-size: 0;
  width: 100% !important;
  overflow: visible !important; }

/* line 3196, app/styles/_custom.scss */
.horizontal-bar .accordion-menu li {
  display: inline-block;
  margin: 0px !important;
  padding: 0px !important;
  position: relative; }

/* line 3203, app/styles/_custom.scss */
.small-sidebar.page-horizontal-bar .horizontal-bar .accordion-menu > li > a,
.horizontal-bar .accordion-menu > li > a {
  padding: 16px !important; }

/* line 3208, app/styles/_custom.scss */
.small-sidebar .horizontal-bar .accordion-menu li a span.menu-icon {
  font-size: 15px; }

/* line 3212, app/styles/_custom.scss */
.horizontal-bar .accordion-menu > li > a > p {
  font-size: 13px; }

/* line 3216, app/styles/_custom.scss */
.compact-menu .horizontal-bar .accordion-menu li a p {
  display: inline; }

/* line 3220, app/styles/_custom.scss */
.horizontal-bar .accordion-menu .sub-menu li a {
  font-size: 13px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px; }

/* line 3227, app/styles/_custom.scss */
.page-horizontal-bar.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > a {
  width: auto; }

/* line 3231, app/styles/_custom.scss */
.horizontal-bar .accordion-menu > li > a > span.arrow:before {
  display: none; }

/* line 3235, app/styles/_custom.scss */
body:not(.small-sidebar) .horizontal-bar .menu.accordion-menu > li > a > .menu-icon {
  font-size: 14px; }

/* line 3239, app/styles/_custom.scss */
.small-sidebar.page-horizontal-bar .menu.accordion-menu > li > a > span.menu-icon {
  margin: 0 !important; }

/* line 3243, app/styles/_custom.scss */
.horizontal-bar .accordion-menu > li > ul {
  left: 0 !important;
  top: 74px;
  width: 200px;
  position: absolute; }

/* line 3250, app/styles/_custom.scss */
.horizontal-bar .accordion-menu ul li {
  display: block;
  text-align: left; }

/* line 3255, app/styles/_custom.scss */
.horizontal-bar .accordion-menu li.menu-title {
  display: none; }

/* line 3259, app/styles/_custom.scss */
.small-sidebar .horizontal-bar .accordion-menu > li > a > p {
  display: none !important; }

/* line 3263, app/styles/_custom.scss */
.small-sidebar .horizontal-bar .accordion-menu > li > ul {
  top: 50px; }

/* line 3267, app/styles/_custom.scss */
.small-sidebar.page-horizontal-bar.page-sidebar-fixed .page-inner {
  padding-left: 0px; }

/* line 3271, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed.page-header-fixed.page-horizontal-bar .page-inner {
  padding: 110px 0 50px 0; }

/* line 3275, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed.page-header-fixed .page-inner {
  padding: 60px 0 50px 65px; }

/* line 3279, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-header-fixed) .page-inner {
  padding: 0 0 50px 65px; }

/* line 3283, app/styles/_custom.scss */
.small-sidebar.page-header-fixed.page-horizontal-bar:not(.page-sidebar-fixed) .page-inner {
  padding: 0 0 50px 0; }

/* Search */
/* line 3290, app/styles/_custom.scss */
.search-form {
  display: block;
  width: 100%;
  height: 60px;
  z-index: 99999;
  position: fixed;
  background: #fff;
  margin-top: -60px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease; }

/* line 3305, app/styles/_custom.scss */
.search-form .input-group {
  height: 60px; }

/* line 3309, app/styles/_custom.scss */
.search-form .input-group input {
  color: #5f5f5f;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  background: none;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  padding: 0 20px !important; }

/* line 3321, app/styles/_custom.scss */
.search-form .input-group button,
.search-form .input-group button:hover,
.search-form .input-group button:focus {
  background: none;
  box-shadow: none;
  border: none;
  outline: none;
  color: #5f5f5f;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px; }

/* Menu */
/* line 3337, app/styles/_custom.scss */
.menu li a .badge {
  margin-left: 10px; }

/* line 3341, app/styles/_custom.scss */
.menu.accordion-menu {
  padding: 0px;
  margin: 0px;
  width: 160px;
  color: white;
  position: relative;
  display: block; }

/* line 3350, app/styles/_custom.scss */
.menu.accordion-menu a {
  display: block;
  padding: 15px;
  padding-left: 18px;
  padding-right: 18px;
  background: transparent;
  text-decoration: none;
  font-size: 13px; }

/* line 3360, app/styles/_custom.scss */
.menu.accordion-menu > li > a {
  text-align: center; }

/* line 3364, app/styles/_custom.scss */
body:not(.page-horizontal-bar):not(.small-sidebar) .menu.accordion-menu a {
  text-align: center; }

/* line 3368, app/styles/_custom.scss */
.menu.accordion-menu a p {
  line-height: 1.42857 !important;
  margin: 0; }

/* line 3373, app/styles/_custom.scss */
.menu.accordion-menu a span.menu-icon {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 10px; }

/* line 3379, app/styles/_custom.scss */
.menu.accordion-menu li > a > .arrow:before {
  width: 10px;
  float: right;
  display: block;
  margin-top: -20px;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 300; }

/* line 3391, app/styles/_custom.scss */
.menu.accordion-menu > li > a > .arrow:before {
  margin-top: -39px; }

/* line 3395, app/styles/_custom.scss */
.menu.accordion-menu li.open > a > .arrow:before {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }

/* line 3403, app/styles/_custom.scss */
.menu.accordion-menu li {
  list-style-type: none; }

/* line 3407, app/styles/_custom.scss */
.menu.accordion-menu li a .badge {
  float: right;
  margin-top: 1px;
  margin-right: 0px;
  margin-left: 0px;
  display: block; }

/* line 3415, app/styles/_custom.scss */
.menu.accordion-menu ul {
  z-index: 9999; }

/* line 3419, app/styles/_custom.scss */
.menu.accordion-menu ul li a {
  display: block;
  padding: 10px;
  background: transparent;
  text-decoration: none;
  font-size: 13px; }

/* line 3427, app/styles/_custom.scss */
.menu.accordion-menu ul li ul {
  padding: 2px 0 2px 0; }

/* line 3431, app/styles/_custom.scss */
.compact-menu .menu.accordion-menu .sub-menu li .sub-menu li a,
.hover-menu .menu.accordion-menu .sub-menu li .sub-menu li a,
.page-horizontal-bar .menu.accordion-menu .sub-menu li .sub-menu li a {
  padding-left: 26px; }

/* line 3437, app/styles/_custom.scss */
.compact-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li a,
.hover-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li a,
.page-horizontal-bar .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li a {
  padding-left: 31px; }

/* line 3443, app/styles/_custom.scss */
.compact-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li .sub-menu li a,
.hover-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li .sub-menu li a,
.page-horizontal-bar .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li .sub-menu li a {
  padding-left: 36px; }

/* line 3449, app/styles/_custom.scss */
.menu ul {
  padding: 10px;
  z-index: 99; }

/* line 3454, app/styles/_custom.scss */
.compact-menu:not(.small-sidebar) .menu.accordion-menu li a {
  text-align: left !important; }

/* line 3458, app/styles/_custom.scss */
.compact-menu:not(.page-horizontal-bar) .menu.accordion-menu > li > a > p {
  display: inline !important; }

/* line 3462, app/styles/_custom.scss */
.compact-menu .menu.accordion-menu a span.menu-icon {
  display: inline-block !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
  margin-right: 10px !important; }

/* line 3469, app/styles/_custom.scss */
.compact-menu .menu.accordion-menu li a .arrow:before {
  margin-top: -2px; }

/* line 3473, app/styles/_custom.scss */
.page-horizontal-bar.compact-menu .menu.accordion-menu ul {
  top: 50px; }

/* line 3477, app/styles/_custom.scss */
.page-horizontal-bar.compact-menu.small-sidebar .menu.accordion-menu ul {
  top: 46px !important; }

/* line 3481, app/styles/_custom.scss */
.hover-menu:not(.page-horizontal-bar) .menu.accordion-menu > li > a > span.arrow {
  display: none; }

/* line 3485, app/styles/_custom.scss */
.hover-menu .menu.accordion-menu > li:hover > ul,
.hover-menu .menu.accordion-menu > li.open:hover > ul {
  display: inline-block !important; }

/* line 3490, app/styles/_custom.scss */
.hover-menu .accordion-menu li {
  position: relative; }

/* line 3494, app/styles/_custom.scss */
.hover-menu .accordion-menu > li > ul {
  position: absolute;
  left: 160px;
  width: 200px; }

/* line 3500, app/styles/_custom.scss */
.hover-menu:not(.page-horizontal-bar) .accordion-menu > li > ul {
  position: absolute;
  top: 0;
  left: 160px;
  width: 200px; }

/* line 3507, app/styles/_custom.scss */
.hover-menu .sidebar,
.hover-menu .sidebar .page-sidebar-inner,
.hover-menu .sidebar .menu,
.hover-menu .sidebar .slimScrollDiv {
  overflow: visible !important; }

/* line 3514, app/styles/_custom.scss */
.hover-menu .accordion-menu ul li a {
  text-align: left !important; }

/* line 3518, app/styles/_custom.scss */
.compact-menu .accordion-menu li a p {
  display: inline; }

/* line 3522, app/styles/_custom.scss */
.hover-menu .sidebar .slimScrollBar,
.hover-menu .slimScrollBar {
  display: none !important; }

/* Small Menu */
/* line 3530, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu {
  width: 65px; }

/* line 3534, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .page-sidebar-inner {
  overflow: hidden !important; }

/* line 3538, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu {
  width: 160px !important; }

/* line 3543, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu > li > a {
  padding: 15px;
  text-align: center; }

/* line 3548, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu > li > a {
  padding: 15px;
  padding-left: 18px;
  padding-right: 18px; }

/* line 3554, app/styles/_custom.scss */
.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu > li > a {
  text-align: left; }

/* line 3558, app/styles/_custom.scss */
.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu > li > a > .menu-icon {
  margin-right: 10px !important; }

/* line 3562, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu ul li a {
  text-align: center; }

/* line 3566, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a .menu-icon {
  float: none;
  margin: 0 !important;
  font-size: 14px; }

/* line 3572, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a .menu-icon {
  font-size: 20px; }

/* line 3576, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a p,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a .arrow,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li.menu-title,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a .badge {
  display: none !important; }

/* line 3583, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a p {
  margin: 0; }

/* line 3587, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li.open .sub-menu {
  visibility: hidden;
  height: 0 !important;
  padding: 0; }

/* line 3593, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .sidebar-widget,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .sidebar-header {
  display: block !important; }

/* line 3598, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a p,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li.menu-title {
  display: block !important; }

/* line 3603, app/styles/_custom.scss */
.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a p,
.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li.menu-title {
  display: inline !important; }

/* line 3608, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li.open .sub-menu {
  visibility: visible;
  height: auto !important;
  padding: 10px; }

/* line 3614, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a .arrow {
  display: block !important;
  float: right; }

/* line 3619, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .sidebar,
.small-sidebar:not(.page-sidebar-fixed) .sidebar > *,
.horizontal-bar,
.horizontal-bar > * {
  overflow: visible !important; }

/* line 3626, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .sidebar .slimScrollBar,
.horizontal-bar .slimScrollBar {
  display: none !important; }

/* line 3631, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .menu.accordion-menu {
  width: auto;
  oveflow: visible !important; }

/* line 3636, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu,
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li {
  padding: 0;
  margin: 0; }

/* line 3642, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li {
  list-style: none;
  position: relative;
  width: auto; }

/* line 3648, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a {
  text-decoration: none;
  display: block; }

/* line 3653, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > a {
  padding: 15px 25px 15px 25px;
  text-align: center;
  position: relative;
  width: 265px; }

/* line 3660, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a span.menu-icon {
  font-size: 14px;
  margin: 0 50px 0 0 !important;
  float: left; }

/* line 3666, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a p {
  margin: 0;
  float: left; }

/* line 3671, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a .arrow:before {
  width: 10px;
  float: right;
  margin-left: 15px;
  margin-top: -2px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: 18px;
  content: "\f104";
  font-weight: 300; }

/* line 3684, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li:hover > a {
  z-index: 999; }

/* line 3688, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > ul {
  position: absolute;
  left: 65px;
  top: 44px;
  width: 200px; }

/* line 3695, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu ul li a {
  display: block;
  padding: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  background: transparent;
  font-size: 13px; }

/* line 3704, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu ul li span.menu-icon {
  margin-right: 15px; }

/* line 3708, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > a > span.arrow {
  display: none; }

/* line 3712, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a p {
  display: block;
  text-align: left; }

/* line 3717, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li.menu-title {
  display: none; }

/* line 3721, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li:hover > ul,
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li.open:hover > ul {
  display: inline-block !important; }

/* line 3726, app/styles/_custom.scss */
.page-horizontal-bar .accordion-menu > li:hover > ul,
.page-horizontal-bar .accordion-menu > li.open:hover > ul {
  display: inline-block !important; }

/* line 3731, app/styles/_custom.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li.open > ul {
  display: none !important; }

/* line 3735, app/styles/_custom.scss */
.page-horizontal-bar .accordion-menu > li.open > ul {
  display: none !important; }

/* Page Inner */
/* line 3742, app/styles/_custom.scss */
.page-inner {
  padding: 0 0 50px 0;
  background: #F1F4F9;
  position: relative; }

/* line 3748, app/styles/_custom.scss */
.page-header-fixed:not(.page-sidebar-fixed):not(.page-horizontal-bar) .page-inner {
  padding: 60px 0 50px 0; }

/* line 3752, app/styles/_custom.scss */
.page-sidebar-fixed.page-header-fixed .page-inner {
  padding: 60px 0 50px 160px; }

/* line 3756, app/styles/_custom.scss */
.page-horizontal-bar.page-sidebar-fixed.page-header-fixed .page-inner {
  padding: 134px 0 50px 0; }

/* line 3760, app/styles/_custom.scss */
.page-horizontal-bar.page-sidebar-fixed.page-header-fixed.compact-menu .page-inner {
  padding: 111px 0 50px 0; }

/* line 3764, app/styles/_custom.scss */
.page-sidebar-fixed:not(.page-header-fixed) .page-inner {
  padding: 0px 0 50px 160px; }

/* line 3768, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed.page-header-fixed .page-inner {
  padding: 60px 0 50px 65px; }

/* line 3772, app/styles/_custom.scss */
.small-sidebar.page-sidebar-fixed:not(.page-header-fixed) .page-inner {
  padding: 0 0 50px 65px; }

/* line 3776, app/styles/_custom.scss */
.small-sidebar.page-header-fixed:not(.page-sidebar-fixed) .page-inner {
  padding: 60px 0 50px 0; }

/* Page Title */
/* line 3783, app/styles/_custom.scss */
.page-title {
  padding: 20px;
  background: #e9edf2;
  border-bottom: 1px solid #dee2e8; }

/* line 3789, app/styles/_custom.scss */
.page-title h3 {
  font-size: 24px;
  font-weight: 300;
  color: #74767d;
  padding: 0px;
  margin: 0 0 4px 0; }

/* Page Settings */
/* line 3800, app/styles/_custom.scss */
.page-breadcrumb .breadcrumb {
  float: left;
  background: transparent;
  padding: 0px;
  margin-top: 30px; }

/* line 3807, app/styles/_custom.scss */
.page-breadcrumb ol.breadcrumb {
  margin: 0px; }

/* line 3811, app/styles/_custom.scss */
.page-breadcrumb ol.breadcrumb li a {
  font-size: 13px;
  color: #90999c; }

/* line 3816, app/styles/_custom.scss */
.page-breadcrumb ol.breadcrumb li.active {
  font-size: 13px;
  color: #90999c; }

/* line 3821, app/styles/_custom.scss */
.page-breadcrumb ul {
  margin: 0px; }

/* line 3825, app/styles/_custom.scss */
.page-breadcrumb ul li {
  float: left;
  display: inline; }

/* line 3830, app/styles/_custom.scss */
.page-breadcrumb ul li a {
  font-size: 13px;
  color: #90999c; }

/* Main Wrapper */
/* line 3838, app/styles/_custom.scss */
#main-wrapper {
  margin: 20px;
  padding: 0px; }

/* Footer */
/* line 3846, app/styles/_custom.scss */
.page-footer {
  background: #fff;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 19px;
  padding-bottom: 19px; }

/* Sidebar Pusher */
/* line 3861, app/styles/_custom.scss */
.sidebar-pusher {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999;
  display: none; }

/* line 3870, app/styles/_custom.scss */
.push-sidebar {
  display: inline-block !important;
  padding: 20px 18px;
  color: #5F5F5F;
  border: none;
  position: relative;
  line-height: 20px;
  float: left;
  font-size: 17px;
  text-decoration: none; }

/* line 3882, app/styles/_custom.scss */
.push-sidebar:hover {
  color: #5F5F5F; }

/* Search Button */
/* line 3888, app/styles/_custom.scss */
.search-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999;
  display: none;
  right: 0; }

/* line 3898, app/styles/_custom.scss */
.search-button a {
  display: inline-block !important;
  padding: 20px 18px;
  color: #5F5F5F;
  border: none;
  position: relative;
  line-height: 20px;
  float: right;
  font-size: 17px;
  text-decoration: none; }

/* Icons */
/* FontAwesome */
/* line 3914, app/styles/_custom.scss */
.fontawesome-icon-list .fa-item {
  padding: 10px 20px 10px 20px;
  color: #333; }

/* line 3919, app/styles/_custom.scss */
.fontawesome-icon-list .fa-item i {
  margin-right: 5px; }

/* line 3923, app/styles/_custom.scss */
.fontawesome-icon-list .fa-item:hover {
  background: #f1f1f1; }

/* line 3927, app/styles/_custom.scss */
.line-icons .line-icon-item {
  padding: 10px 20px 10px 20px;
  color: #333;
  display: block; }

/* line 3933, app/styles/_custom.scss */
.line-icons .line-icon-item:hover {
  background: #f1f1f1; }

/* line 3937, app/styles/_custom.scss */
.bs-glyphicons-list li span.glyphicon {
  margin: 5px 0 10px 0;
  float: none;
  font-size: 24px; }

/* line 3943, app/styles/_custom.scss */
.bs-glyphicons-list li span {
  text-align: center;
  display: block; }

/* line 3948, app/styles/_custom.scss */
.bs-glyphicons-list li {
  display: block;
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #fff;
  list-style-type: none; }

@media (min-width: 768px) {
  /* line 3963, app/styles/_custom.scss */
  .bs-glyphicons-list li {
    width: 12.5%;
    font-size: 13px; } }

@media (min-width: 768px) {
  /* line 3970, app/styles/_custom.scss */
  .bs-glyphicons-list li {
    width: 12.5%;
    font-size: 13px; } }

/* Calendar */
/* line 3979, app/styles/_custom.scss */
.calendar-event {
  padding: 7px 10px;
  color: #5B5B5B;
  background: #FCF8E3;
  margin-bottom: 5px;
  overflow: hidden; }

/* line 3987, app/styles/_custom.scss */
.calendar-event p {
  float: left;
  margin: 0; }

/* line 3992, app/styles/_custom.scss */
.calendar-event .remove-calendar-event {
  float: right;
  color: #5B5B5B; }

/* Dashboard */
/* line 4000, app/styles/_custom.scss */
.info-box .progress {
  margin: 0; }

/* line 4004, app/styles/_custom.scss */
.info-box .counter {
  z-index: 9;
  position: relative; }

/* line 4008, app/styles/_custom.scss */
.info-box .info-box-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 0; }

/* line 4015, app/styles/_custom.scss */
.info-box .info-box-icon i {
  font-size: 31px;
  color: #B0B0B0; }

/* line 4020, app/styles/_custom.scss */
.info-box .info-box-stats {
  float: left;
  margin-top: 5px; }

/* line 4025, app/styles/_custom.scss */
.info-box .info-box-stats p {
  font-size: 18px;
  margin-bottom: 14px;
  color: #374051;
  font-weight: 300; }

/* line 4032, app/styles/_custom.scss */
.info-box .info-box-stats span.info-box-title {
  display: block;
  font-size: 13px;
  margin-bottom: 10px;
  color: #B0B0B0;
  position: absolute !important;
  top: 68px !important; }

/* line 4041, app/styles/_custom.scss */
.info-box .info-box-progress {
  clear: both; }

@media (min-width: 768px) {
  /* line 4046, app/styles/_custom.scss */
  .visitors-chart {
    border-right: 1px solid #eee; } }

/* line 4051, app/styles/_custom.scss */
.stats-info ul {
  margin: 0; }

/* line 4055, app/styles/_custom.scss */
.stats-info ul li {
  border-bottom: 1px solid #eee;
  padding: 12px 0; }

/* line 4060, app/styles/_custom.scss */
.stats-info ul li:last-child {
  border-bottom: 0; }

/* line 4064, app/styles/_custom.scss */
.stats-info ul li i {
  margin-left: 5px; }

/* line 4068, app/styles/_custom.scss */
#flotchart1 {
  height: 340px;
  width: 100%; }

/* line 4073, app/styles/_custom.scss */
#flotchart2 {
  height: 278px;
  width: 100%; }

/* line 4078, app/styles/_custom.scss */
#flotchart3 {
  height: 200px;
  width: 100%; }

/* line 4083, app/styles/_custom.scss */
.server-load > .server-stat {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 20px; }

/* line 4089, app/styles/_custom.scss */
.server-load .server-stat span {
  font-size: 11px;
  margin-bottom: 3px; }

/* line 4094, app/styles/_custom.scss */
.server-load .server-stat p {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px; }

/* line 4100, app/styles/_custom.scss */
.weather-current {
  overflow: hidden; }

/* line 4104, app/styles/_custom.scss */
.weather-current p {
  font-size: 12px;
  margin: 5px 0 0 0;
  float: left;
  color: #8e8e8e; }

/* line 4111, app/styles/_custom.scss */
.weather-current p span {
  font-size: 42px;
  clear: both;
  margin-top: 15px;
  display: block;
  color: #4E5E6A;
  font-weight: 300; }

/* line 4120, app/styles/_custom.scss */
.weather-current p span sup {
  font-size: 25px; }

/* line 4124, app/styles/_custom.scss */
.weather-current .weather-icon {
  font-size: 40px;
  display: block;
  float: left;
  margin: 15px 15px 0 0; }

/* line 4131, app/styles/_custom.scss */
.weather-day {
  margin: 0;
  font-weight: 600;
  text-align: right;
  font-size: 22px; }

/* line 4138, app/styles/_custom.scss */
.weather-day small {
  font-size: 11px;
  color: #8e8e8e;
  margin: 0; }

/* line 4144, app/styles/_custom.scss */
.weather-info {
  width: 100%;
  display: block;
  overflow: hidden; }

/* line 4150, app/styles/_custom.scss */
.weather-info li {
  padding: 8px 0; }

/* line 4154, app/styles/_custom.scss */
.weather-top {
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
  padding: 0 0 15px 0;
  margin: 0 0 15px 0; }

/* line 4161, app/styles/_custom.scss */
.weather-days {
  margin: 15px 0 0 0;
  padding: 15px 0 0 0;
  border-top: 1px solid #f1f1f1; }

/* line 4167, app/styles/_custom.scss */
.weather-days li span {
  display: block;
  text-align: center; }

/* line 4172, app/styles/_custom.scss */
.weather-days li i {
  display: block;
  font-size: 22px;
  text-align: center;
  margin: 10px 0 10px 0; }

@media (max-width: 768px) {
  /* line 4180, app/styles/_custom.scss */
  .weather-days li {
    margin-bottom: 15px; } }

/* line 4185, app/styles/_custom.scss */
.live-tile {
  width: 100%;
  margin: 0;
  height: 155.5px; }

/* line 4191, app/styles/_custom.scss */
.twitter-box {
  background: #5EA9DD; }

/* line 4195, app/styles/_custom.scss */
.facebook-box {
  background: #3B5998; }

/* line 4199, app/styles/_custom.scss */
.twitter-box i,
.facebook-box i {
  position: absolute;
  right: 10px;
  bottom: 0;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.3); }

/* line 4208, app/styles/_custom.scss */
.tile-date {
  display: block;
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.7); }

/* line 4214, app/styles/_custom.scss */
.inbox-widget {
  height: 341px !important; }

/* line 4218, app/styles/_custom.scss */
.inbox-widget a {
  display: block;
  overflow: hidden;
  text-decoration: none; }

/* line 4224, app/styles/_custom.scss */
.inbox-widget .inbox-item {
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
  position: relative; }

/* line 4231, app/styles/_custom.scss */
.inbox-widget a:last-child > .inbox-item {
  border-bottom: 0; }

/* line 4235, app/styles/_custom.scss */
.inbox-widget .inbox-item .inbox-item-img {
  float: left;
  width: 40px;
  display: block;
  margin-right: 15px; }

/* line 4242, app/styles/_custom.scss */
.inbox-widget .inbox-item img {
  width: 40px; }

/* line 4246, app/styles/_custom.scss */
.inbox-widget .inbox-item .inbox-item-author {
  color: #333;
  font-weight: 600;
  display: block;
  margin: 0 0 0 0; }

/* line 4253, app/styles/_custom.scss */
.inbox-widget .inbox-item .inbox-item-text {
  color: #a0a0a0;
  display: block;
  margin: 0;
  font-size: 11px; }

/* line 4260, app/styles/_custom.scss */
.inbox-widget .inbox-item .inbox-item-date {
  position: absolute;
  top: 2px;
  right: 7px;
  color: #a9a9a9;
  font-size: 11px; }

/* line 4268, app/styles/_custom.scss */
#rickshaw-chart {
  margin: 0 -22px -25px -20px;
  height: 100px; }

/* line 4273, app/styles/_custom.scss */
.project-stats .progress {
  margin: 3px 0 0 0; }

/* Profile */
/* line 4280, app/styles/_custom.scss */
.profile-cover {
  background: url("../images/profile-cover.png");
  background-size: cover;
  width: 100%;
  height: 300px;
  position: relative; }

/* line 4288, app/styles/_custom.scss */
.profile-image {
  position: absolute;
  margin: 200px 20px 20px 20px; }

/* line 4293, app/styles/_custom.scss */
.profile-image img {
  display: block;
  margin: 0 auto;
  width: 150px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 5px #fff;
  -moz-box-shadow: 0 0 0 5px #fff;
  -o-box-shadow: 0 0 0 5px #fff;
  box-shadow: 0 0 0 5px #fff; }

/* line 4304, app/styles/_custom.scss */
.profile-info {
  position: absolute;
  bottom: 10px;
  right: 20px;
  padding: 0;
  margin: 0;
  text-align: right; }

/* line 4313, app/styles/_custom.scss */
.profile-info-value {
  color: #fff;
  display: inline-block;
  margin-left: 40px; }

/* line 4319, app/styles/_custom.scss */
.user-profile {
  margin-top: 60px; }

/* line 4323, app/styles/_custom.scss */
.team .team-member {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
  float: left;
  position: relative; }

/* line 4331, app/styles/_custom.scss */
.team .team-member .online {
  top: 5px;
  right: 5px; }

/* line 4336, app/styles/_custom.scss */
.team .team-member img {
  width: 50px;
  float: left;
  border-radius: 50%;
  margin: 0 5px 0 5px; }

/* line 4343, app/styles/_custom.scss */
.team .more-members a {
  display: block;
  text-align: center; }

/* line 4348, app/styles/_custom.scss */
.profile-timeline ul li .timeline-item-header {
  width: 100%;
  overflow: hidden; }

/* line 4353, app/styles/_custom.scss */
.profile-timeline ul li .timeline-item-header img {
  width: 50px;
  float: left;
  margin-right: 10px;
  border-radius: 50%; }

/* line 4360, app/styles/_custom.scss */
.profile-timeline ul li .timeline-item-header p {
  margin: 0;
  color: #000;
  font-weight: bold; }

/* line 4366, app/styles/_custom.scss */
.profile-timeline ul li .timeline-item-header p span {
  margin: 0;
  color: #8E8E8E;
  font-weight: normal; }

/* line 4372, app/styles/_custom.scss */
.profile-timeline ul li .timeline-item-header small {
  margin: 0;
  color: #8E8E8E; }

/* line 4377, app/styles/_custom.scss */
.profile-timeline ul li .timeline-item-post {
  padding: 20px 0 0 0;
  position: relative; }

/* line 4382, app/styles/_custom.scss */
.profile-timeline ul li .timeline-item-post > img {
  width: 100%; }

/* line 4386, app/styles/_custom.scss */
.timeline-options {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding: 10px 0 10px 0; }

/* line 4394, app/styles/_custom.scss */
.timeline-options a {
  display: block;
  margin-right: 20px;
  float: left;
  color: #333;
  text-decoration: none; }

/* line 4402, app/styles/_custom.scss */
.timeline-options a i {
  margin-right: 3px; }

/* line 4406, app/styles/_custom.scss */
.timeline-options a:hover {
  color: #22BAA0; }

/* line 4410, app/styles/_custom.scss */
.timeline-comment {
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px; }

/* line 4418, app/styles/_custom.scss */
.timeline-comment .timeline-comment-header {
  overflow: hidden; }

/* line 4422, app/styles/_custom.scss */
.timeline-comment .timeline-comment-header img {
  width: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 10px; }

/* line 4429, app/styles/_custom.scss */
.timeline-comment .timeline-comment-header p {
  color: #000;
  float: left;
  margin: 0;
  font-weight: bold; }

/* line 4436, app/styles/_custom.scss */
.timeline-comment .timeline-comment-header small {
  font-weight: normal;
  color: #8E8E8E; }

/* line 4441, app/styles/_custom.scss */
.timeline-comment p.timeline-comment-text {
  display: block;
  color: #333;
  font-size: 12px;
  padding-left: 40px; }

/* line 4448, app/styles/_custom.scss */
.post-options {
  overflow: hidden;
  margin-top: 15px;
  margin-left: 15px; }

/* line 4454, app/styles/_custom.scss */
.post-options a {
  display: block;
  margin-top: 5px;
  margin-right: 20px;
  float: left;
  color: #333;
  text-decoration: none;
  font-size: 17px; }

/* line 4464, app/styles/_custom.scss */
.post-options a:hover {
  color: #22BAA0; }

/* line 4468, app/styles/_custom.scss */
#gallery .mix {
  display: none; }

/* Mailbox */
/* line 4475, app/styles/_custom.scss */
.mailbox-nav li a {
  display: block;
  padding: 10px 7px;
  color: #999;
  text-decoration: none;
  border-bottom: 1px solid #E7EAEC;
  overflow: hidden; }

/* line 4484, app/styles/_custom.scss */
.mailbox-nav li a:hover,
.mailbox-nav .active a {
  color: #333; }

/* line 4489, app/styles/_custom.scss */
.mailbox-nav li a i {
  margin-right: 5px; }

/* line 4493, app/styles/_custom.scss */
.mailbox-content {
  background: #fff;
  padding: 15px; }

/* line 4498, app/styles/_custom.scss */
.mailbox-header {
  margin-bottom: 20px; }

/* line 4502, app/styles/_custom.scss */
.mailbox-header h2 {
  margin: 3px 0; }

/* line 4506, app/styles/_custom.scss */
.mailbox-content {
  overflow: hidden; }

/* line 4510, app/styles/_custom.scss */
.mailbox-content table thead tr th {
  border-bottom: 1px solid #ddd; }

/* line 4514, app/styles/_custom.scss */
.mailbox-content table tbody tr td {
  cursor: pointer; }

/* line 4518, app/styles/_custom.scss */
.mailbox-content table tbody tr:hover {
  background: #FAFAFA; }

/* line 4522, app/styles/_custom.scss */
.mailbox-content table tbody tr.read {
  background: #F9F9F9;
  color: #7F7F7F; }

/* line 4527, app/styles/_custom.scss */
.mailbox-content table tbody tr.checked {
  background: #FFFFCC; }

/* line 4531, app/styles/_custom.scss */
.mail-hidden-options {
  display: none; }

/* line 4535, app/styles/_custom.scss */
.message-header {
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
  padding: 5px 0 15px 0;
  margin-bottom: 10px; }

/* line 4542, app/styles/_custom.scss */
.message-header h3 {
  float: left;
  font-size: 18px;
  margin: 0;
  color: #666; }

/* line 4549, app/styles/_custom.scss */
.message-header p {
  float: right;
  margin: 0; }

/* line 4554, app/styles/_custom.scss */
.message-sender {
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 0 5px 0;
  margin-bottom: 10px; }

/* line 4561, app/styles/_custom.scss */
.message-sender img {
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

/* line 4568, app/styles/_custom.scss */
.message-sender p {
  display: inline-block; }

/* line 4572, app/styles/_custom.scss */
.message-sender span {
  color: #7F7F7F; }

/* line 4576, app/styles/_custom.scss */
.message-content {
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 0 5px 0;
  margin-bottom: 10px; }

/* line 4583, app/styles/_custom.scss */
.message-attachments {
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 0 15px 0;
  margin-bottom: 10px; }

/* line 4590, app/styles/_custom.scss */
.message-attachment {
  position: relative;
  width: 200px;
  float: left;
  margin-right: 10px;
  border: 1px solid #f1f1f1; }

/* line 4598, app/styles/_custom.scss */
.message-attachment a {
  text-decoration: none; }

/* line 4602, app/styles/_custom.scss */
.message-attachment img {
  display: block;
  width: 100%; }

/* line 4607, app/styles/_custom.scss */
.attachment-info {
  background: #f9f9f9;
  padding: 10px; }

/* line 4612, app/styles/_custom.scss */
.attachment-info p {
  margin: 0; }

/* line 4616, app/styles/_custom.scss */
.attachment-info span {
  font-size: 11px;
  color: #aaa; }

/* line 4621, app/styles/_custom.scss */
.compose-message {
  overflow: hidden;
  padding: 0 0 10px 0; }

/* line 4626, app/styles/_custom.scss */
.compose-options {
  width: 100%;
  overflow: hidden;
  display: block; }

/* Notifications */
/* line 4635, app/styles/_custom.scss */
.box-notifications {
  max-height: 312px;
  overflow-y: scroll; }

/* line 4640, app/styles/_custom.scss */
.box-notifications ul li {
  display: block;
  padding: 11px 0 11px 0;
  border-bottom: 1px solid #f1f1f1; }

/* line 4646, app/styles/_custom.scss */
.box-notifications ul li:first-child {
  padding: 0 0 11px 0; }

/* line 4650, app/styles/_custom.scss */
.box-notifications ul li:last-child {
  padding: 11px 0 0 0;
  border-bottom: 0; }

/* line 4655, app/styles/_custom.scss */
.box-notifications ul li p {
  margin: 0; }

/* Extra */
/* line 4662, app/styles/_custom.scss */
.page-error #main-wrapper {
  margin: 5%; }

/* line 4666, app/styles/_custom.scss */
.page-error .details {
  margin-bottom: 20px;
  text-align: center; }

/* line 4671, app/styles/_custom.scss */
.coming-soon .page-inner {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../images/coming-soon.jpg");
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../images/coming-soon.jpg");
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../images/coming-soon.jpg");
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../images/coming-soon.jpg");
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url("../images/coming-soon.jpg");
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../images/coming-soon.jpg");
  background-size: cover; }

/* Login */
/* line 4691, app/styles/_custom.scss */
.login-box {
  margin: auto;
  max-width: 400px; }

/* line 4696, app/styles/_custom.scss */
.login-box .logo-name {
  display: block;
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  color: #6c6c6c; }

/* line 4704, app/styles/_custom.scss */
.login-box .user-box {
  display: block;
  clear: both;
  overflow: hidden; }

/* line 4710, app/styles/_custom.scss */
.login-box .user-box img {
  width: 100px;
  display: block;
  margin: 0 auto; }

/* line 4716, app/styles/_custom.scss */
.login-box .user-box form {
  overflow: hidden; }

/* Search Results */
/* line 4723, app/styles/_custom.scss */
.search-item {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1; }

/* line 4729, app/styles/_custom.scss */
.search-item p {
  margin: 0; }

/* line 4733, app/styles/_custom.scss */
.search-item a {
  text-decoration: none; }

/* line 4737, app/styles/_custom.scss */
.search-item a.search-link {
  color: #22BAA0; }

/* Chat */
/* line 4744, app/styles/_custom.scss */
.chat {
  position: relative;
  padding-bottom: 100px; }

/* line 4749, app/styles/_custom.scss */
.chat .chat-item {
  display: block;
  padding: 10px 15px;
  width: 100%;
  overflow: hidden; }

/* line 4756, app/styles/_custom.scss */
.chat .chat-item-right .chat-message {
  float: right; }

/* line 4760, app/styles/_custom.scss */
.chat .chat-item .chat-image {
  float: left;
  display: inline-block; }

/* line 4765, app/styles/_custom.scss */
.chat-item-left .chat-message {
  margin-top: 5px; }

/* line 4769, app/styles/_custom.scss */
.chat .chat-item .chat-message {
  position: relative;
  border-radius: 12px;
  padding: 4px 12px;
  max-width: 75%;
  background: #f1f1f1;
  display: inline-block; }

/* line 4778, app/styles/_custom.scss */
.chat .chat-item img {
  display: block;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px; }

/* line 4785, app/styles/_custom.scss */
.chat .chat-item-right .chat-message {
  background: #55BAA0;
  float: right;
  color: #fff; }

/* line 4791, app/styles/_custom.scss */
.chat-write {
  padding: 5px;
  background: #fff;
  position: absolute;
  bottom: 0;
  width: 240px; }

/* line 4799, app/styles/_custom.scss */
.chat-write input {
  width: 100%; }

/* Transitions */
/* line 4806, app/styles/_custom.scss */
.top-menu .navbar-nav > li > a > .badge,
.menu li a .arrow:before,
.menu li.open > a > .arrow:before {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

/* Overflow */
/* line 4818, app/styles/_custom.scss */
.navbar .logo-box,
.navbar .logo-box a,
.top-menu .navbar-nav > li > a > img,
.info,
.messages li a,
.messages li a .msg-img,
.messages li a .msg-text,
.messages li a .msg-time,
.tasks li a,
.tasks li a .task-icon,
.search-form,
body:not(.small-sidebar):not(.page-horizontal-bar) .menu.accordion-menu,
.page-inner,
.small-sidebar .page-sidebar-fixed .page-inner,
.page-breadcrumb,
.page-breadcrumb .breadcrumb,
.page-breadcrumb ul,
.info-box,
.text-box,
.small-sidebar .accordion-menu li a,
.page-footer,
.color-switcher {
  overflow: hidden; }

/* Settings */
/* line 4846, app/styles/_custom.scss */
.color-switcher {
  display: block;
  width: 100%;
  margin: 10px 0 5px 0; }

/* line 4852, app/styles/_custom.scss */
.color-switcher .colorbox {
  dipslay: block;
  width: 28px;
  height: 28px;
  margin: 0 5px 0 0;
  padding: 0px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  float: left;
  border-radius: 50%; }

/* line 4864, app/styles/_custom.scss */
.color-switcher .colorbox:hover {
  border: 2px solid #dedede; }

/* Blue */
/* line 4870, app/styles/_custom.scss */
.color-switcher .colorbox.color-blue {
  background-color: #12afcb; }

/* Red */
/* line 4876, app/styles/_custom.scss */
.color-switcher .colorbox.color-red {
  background-color: #F25656; }

/* Green  */
/* line 4882, app/styles/_custom.scss */
.color-switcher .colorbox.color-green {
  background-color: #22baa0; }

/* Purple  */
/* line 4888, app/styles/_custom.scss */
.color-switcher .colorbox.color-purple {
  background-color: #7a6fbe; }

/* Black  */
/* line 4894, app/styles/_custom.scss */
.color-switcher .colorbox.color-dark {
  background-color: #34425A; }

/* White  */
/* line 4900, app/styles/_custom.scss */
.color-switcher .colorbox.color-white {
  background-color: #fff; }

/* Media Queries */
@media (max-width: 991px) {
  /* line 4908, app/styles/_custom.scss */
  .profile-image {
    right: 0;
    left: 0; }
  /* line 4913, app/styles/_custom.scss */
  .profile-info {
    top: 10px;
    right: 0;
    left: 0;
    bottom: auto;
    text-align: center; }
  /* line 4921, app/styles/_custom.scss */
  .profile-info-value {
    color: #fff;
    float: none;
    margin: 0 10px 0 10px;
    display: inline-block; }
  /* line 4928, app/styles/_custom.scss */
  .mailbox-header h2 {
    margin: 20px 0;
    text-align: center; } }

@media (max-width: 767px) {
  /* line 4936, app/styles/_custom.scss */
  .sidebar {
    opacity: 0;
    visibility: hidden;
    display: none;
    width: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
  /* line 4947, app/styles/_custom.scss */
  body:not(.page-header-fixed) .sidebar {
    padding-top: 0 !important; }
  /* line 4951, app/styles/_custom.scss */
  .small-sidebar .sidebar.visible,
  .page-horizontal-bar .sidebar.visible {
    margin-right: -65px;
    opacity: 1;
    visibility: visible;
    width: 65px;
    display: block;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
  /* line 4964, app/styles/_custom.scss */
  body:not(.small-sidebar) .sidebar.visible {
    margin-right: -160px;
    opacity: 1;
    visibility: visible;
    display: block;
    width: 160px;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
  /* line 4976, app/styles/_custom.scss */
  .small-sidebar .sidebar,
  .horizontal-bar {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
  /* line 4984, app/styles/_custom.scss */
  .navbar .logo-box {
    margin: 0 auto;
    background: transparent !important;
    text-align: center;
    width: 100% !important;
    border: 0 !important; }
  /* line 4992, app/styles/_custom.scss */
  .navbar .logo-box a {
    color: #5F5F5F !important; }
  /* line 4996, app/styles/_custom.scss */
  .navbar .logo-box .logo-text {
    width: 100%;
    display: inline-block !important; }
  /* line 5001, app/styles/_custom.scss */
  .page-sidebar-fixed:not(.page-header-fixed) .logo-box {
    position: relative;
    display: block; }
  /* line 5006, app/styles/_custom.scss */
  .small-sidebar .navbar .logo-box a span {
    display: inline-block; }
  /* line 5010, app/styles/_custom.scss */
  .sidebar-pusher {
    display: block; }
  /* line 5014, app/styles/_custom.scss */
  .search-button {
    display: block; }
  /* line 5018, app/styles/_custom.scss */
  .topmenu-outer,
  .small-sidebar .topmenu-outer,
  .page-sidebar-fixed:not(.page-header-fixed) .topmenu-outer {
    margin: 0; }
  /* line 5024, app/styles/_custom.scss */
  .top-menu {
    display: none; }
  /* line 5028, app/styles/_custom.scss */
  .page-inner {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out; }
  /* line 5035, app/styles/_custom.scss */
  .page-inner.sidebar-visible {
    -webkit-transform: translate3d(160px, 0, 0);
    transform: translate3d(160px, 0, 0); }
  /* line 5040, app/styles/_custom.scss */
  .small-sidebar .page-inner.sidebar-visible,
  .page-horizontal-bar .page-inner.sidebar-visible {
    -webkit-transform: translate3d(65px, 0, 0);
    transform: translate3d(65px, 0, 0); }
  /* line 5046, app/styles/_custom.scss */
  .page-sidebar-fixed.page-header-fixed .page-inner {
    padding: 60px 0 50px 0 !important; }
  /* line 5050, app/styles/_custom.scss */
  .horizontal-bar {
    float: left;
    height: auto !important;
    display: block;
    z-index: 1029;
    overflow: visible !important; }
  /* line 5058, app/styles/_custom.scss */
  .small-sidebar .horizontal-bar {
    height: auto !important; }
  /* line 5062, app/styles/_custom.scss */
  .horizontal-bar .menu li {
    float: none;
    display: block; }
  /* line 5067, app/styles/_custom.scss */
  .horizontal-bar .accordion-menu li > ul,
  .small-sidebar .horizontal-bar .accordion-menu li > ul {
    left: 65px !important;
    top: 0; }
  /* line 5073, app/styles/_custom.scss */
  .small-sidebar.page-horizontal-bar .accordion-menu > li > a,
  .small-sidebar.page-horizontal-bar:not(.page-sidebar-fixed) .accordion-menu > li > a {
    padding: 15px !important;
    text-align: center;
    width: 65px; }
  /* line 5080, app/styles/_custom.scss */
  .horizontal-bar .menu li a p {
    display: none; }
  /* line 5084, app/styles/_custom.scss */
  .small-sidebar.page-horizontal-bar .horizontal-bar .accordion-menu > li > a > .menu-icon {
    margin-right: 0 !important;
    font-size: 16px;
    float: none !important; }
  /* line 5090, app/styles/_custom.scss */
  .horizontal-bar .accordion-menu > li > a {
    padding: 15px;
    text-align: center;
    width: 65px; }
  /* line 5096, app/styles/_custom.scss */
  .page-horizontal-bar .page-inner {
    padding: 60px 0 50px 0; }
  /* line 5100, app/styles/_custom.scss */
  .page-horizontal-bar.page-sidebar-fixed.page-header-fixed .page-inner {
    padding: 60px 0 50px 0; }
  /* line 5104, app/styles/_custom.scss */
  .small-sidebar.page-sidebar-fixed.page-header-fixed.page-horizontal-bar .page-inner {
    padding: 60px 0 50px 0; }
  /* line 5108, app/styles/_custom.scss */
  .small-sidebar.page-header-fixed.page-horizontal-bar:not(.page-sidebar-fixed) .page-inner {
    padding: 60px 0 50px 0; }
  /* line 5112, app/styles/_custom.scss */
  body:not(.page-header-fixed) .page-inner {
    padding: 0 0 50px 0; } }

/* line 5117, app/styles/_custom.scss */
.overlay {
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none; }

/* line 5128, app/styles/_custom.scss */
.show-menu .overlay {
  display: block; }

/* line 5132, app/styles/_custom.scss */
._720kb-datepicker-calendar-day._720kb-datepicker-today {
  background: red;
  color: white; }

/* analyses */
/* line 5137, app/styles/_custom.scss */
.scrollup {
  height: 50px;
  width: 50px;
  background-color: #3f964a;
  position: fixed;
  bottom: 25px;
  right: 40px;
  border-radius: 50%;
  cursor: pointer; }

/* line 5147, app/styles/_custom.scss */
.scrollupicon {
  position: absolute;
  top: 13px;
  left: 12px;
  color: #fff;
  font-size: 25px; }

/* checkbox credit client */
/* line 5157, app/styles/_custom.scss */
.material-switch {
  width: 146px !important;
  margin-top: 6px !important; }

/* line 5161, app/styles/_custom.scss */
.spanforcredit {
  /* this my spanforcredit */
  position: relative;
  left: 43px;
  font-size: 15px; }

/* line 5167, app/styles/_custom.scss */
.material-switch > input[type="checkbox"] {
  display: none; }

/* line 5171, app/styles/_custom.scss */
.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  /*width: 40px;*/ }

/* line 5178, app/styles/_custom.scss */
.material-switch > label::before {
  background: black;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px; }

/* line 5190, app/styles/_custom.scss */
.material-switch > label::after {
  background: white;
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px; }

/* line 5203, app/styles/_custom.scss */
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5; }

/* line 5207, app/styles/_custom.scss */
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px; }

/*   position client map */
/* line 5214, app/styles/_custom.scss */
.pinupdated {
  background: #e6154f !important; }

/* line 5218, app/styles/_custom.scss */
.pinnumber {
  /* Safari */
  -webkit-transform: rotate(45deg);
  /* Firefox */
  -moz-transform: rotate(45deg);
  /* IE */
  -ms-transform: rotate(45deg);
  /* Opera */
  -o-transform: rotate(45deg);
  /* Internet Explorer */
  /* normal style*/
  position: absolute;
  color: #fff;
  left: 9px;
  z-index: 999999999;
  top: 7px; }

/**
 *  table objectifs
 */
/* line 5242, app/styles/_custom.scss */
.objectif {
  background-color: #e3e6e0; }

/* line 5246, app/styles/_custom.scss */
.prime {
  background-color: #c3cfd8; }

/* line 5250, app/styles/_custom.scss */
input[required] {
  background-color: #dedcdc !important; }

/* line 5253, app/styles/_custom.scss */
input[required=""] {
  background-color: #dedcdc !important; }

/* line 5256, app/styles/_custom.scss */
input[required="required"] {
  background-color: #dedcdc !important; }

/* line 5260, app/styles/_custom.scss */
select[required] {
  background-color: #dedcdc !important; }

/* line 5263, app/styles/_custom.scss */
select[required=""] {
  background-color: #dedcdc !important; }

/* line 5266, app/styles/_custom.scss */
select[required="required"] {
  background-color: #dedcdc !important; }

/* line 5270, app/styles/_custom.scss */
textarea[required="required"] {
  background-color: #dedcdc !important; }

/* line 5273, app/styles/_custom.scss */
textarea[required=""] {
  background-color: #dedcdc !important; }

/* line 5276, app/styles/_custom.scss */
textarea[required] {
  background-color: #dedcdc !important; }

/* line 5280, app/styles/_custom.scss */
div[required] {
  background-color: #dedcdc !important; }

/* line 5283, app/styles/_custom.scss */
div[required=""] {
  background-color: #dedcdc !important; }

/* line 5286, app/styles/_custom.scss */
div[required="required"] {
  background-color: #dedcdc !important; }

/* line 5290, app/styles/_custom.scss */
select.green_bg {
  background: #24c43f !important; }

/* line 5291, app/styles/_custom.scss */
input.green_bg {
  background: #24c43f !important; }

/* line 5293, app/styles/_custom.scss */
.flex-content-objectif {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between; }

/* line 5299, app/styles/_custom.scss */
.flex-content-objectif > div:first-child {
  width: 130px; }

/* line 5303, app/styles/_custom.scss */
.flex-content-objectif > div:not(:first-child) {
  width: 80px; }

/* line 5306, app/styles/_custom.scss */
.flex-content-objectif > div {
  margin-bottom: 2px; }

/* line 5309, app/styles/_custom.scss */
.green_bg {
  background: #24c43f !important; }

/* line 5312, app/styles/_custom.scss */
.w-80 {
  width: 80px; }

/* line 5315, app/styles/_custom.scss */
.bold {
  font-weight: bold; }

/**
routings ->  route ->table
*/
/* line 5320, app/styles/_custom.scss */
.routes > tr > td {
  padding: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important; }

/* line 5327, app/styles/_custom.scss */
.posmarker {
  height: 12px;
  width: 12px;
  border-radius: 50%; }

/* line 5333, app/styles/_custom.scss */
.reduirbtn {
  width: 20px;
  height: 20px; }

/* line 5337, app/styles/_custom.scss */
.reduiricon {
  font-size: 9px; }

/* line 5342, app/styles/_custom.scss */
.amcharts-chart-div a {
  display: none !important; }

/**
 * client table index
 */
/* line 5350, app/styles/_custom.scss */
.client_table_index {
  padding: 0px !important; }

/* line 5354, app/styles/_custom.scss */
.sp_actif {
  height: 10px;
  width: 10px;
  background-color: #46c778;
  border-radius: 100px; }

/* line 5360, app/styles/_custom.scss */
.sp_not_actif {
  height: 10px;
  width: 10px;
  background-color: #ef5b5b;
  border-radius: 100px; }

/*
 #getSumByActivite > .amcharts-main-div{
        margin-left: -60px;
 }
 #getSumByCategory > .amcharts-main-div{
        margin-left: -101px;
 }
*/
/* line 5374, app/styles/_custom.scss */
.progress-bar-vertical {
  width: 32px;
  min-height: 474px;
  margin-bottom: 0px;
  display: flex;
  align-items: flex-end; }

/* line 5382, app/styles/_custom.scss */
.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease; }

/* line 5390, app/styles/_custom.scss */
#progressbar_clients {
  display: flex;
  justify-content: space-between; }

/* line 5395, app/styles/_custom.scss */
.value_taux_pr {
  position: inherit !important; }

/* line 5399, app/styles/_custom.scss */
.rootingscommand th {
  border: 0px !important;
  padding-left: 10px;
  padding-right: 10px; }

/* line 5404, app/styles/_custom.scss */
.rootingscommand td {
  border: 0px !important;
  padding-left: 10px;
  padding-right: 10px; }

/* line 5409, app/styles/_custom.scss */
.rootingscommand td.client {
  border: 1px !important;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 2px; }

/* line 5416, app/styles/_custom.scss */
.carrered,
.carreyellow,
.carregreen,
.carreblue,
.carreblueciel {
  cursor: pointer; }

/* line 5424, app/styles/_custom.scss */
.carrered {
  height: 11px;
  width: 11px;
  background-color: red; }

/* line 5429, app/styles/_custom.scss */
.carreyellow {
  height: 11px;
  width: 11px;
  background-color: yellow; }

/* line 5434, app/styles/_custom.scss */
.carregreen {
  height: 11px;
  width: 11px;
  background-color: green; }

/* line 5439, app/styles/_custom.scss */
.carreblue {
  height: 11px;
  width: 11px;
  background-color: blue; }

/* line 5444, app/styles/_custom.scss */
.carreblueciel {
  height: 11px;
  width: 11px;
  background-color: #4cd4f1; }

@media print {
  /* line 5452, app/styles/_custom.scss */
  .noprint {
    display: none; }
  /* line 5453, app/styles/_custom.scss */
  .imprime-center {
    text-align: center !important; }
  /* line 5454, app/styles/_custom.scss */
  .page-inner {
    page-break-after: always; } }

@page {
  size: auto;
  /* auto is the initial value */
  margin-bottom: 0mm;
  /* this affects the margin in the printer settings */ }

/* line 5468, app/styles/_custom.scss */
.amcharts-chart-div > svg {
  height: 100% !important; }

/* line 1, app/styles/_utils.scss */
.bg-white {
  background: white; }

/* line 5, app/styles/_utils.scss */
.pos-rlt {
  position: relative; }

/* line 9, app/styles/_utils.scss */
.pos-stc {
  position: static !important; }

/* line 13, app/styles/_utils.scss */
.pos-abt {
  position: absolute; }

/* line 17, app/styles/_utils.scss */
.pos-fix {
  position: fixed; }

/* line 21, app/styles/_utils.scss */
.pos-r0 {
  right: 0; }

/* line 25, app/styles/_utils.scss */
.show {
  visibility: visible; }

/* line 29, app/styles/_utils.scss */
.pointer {
  cursor: pointer; }

/* line 33, app/styles/_utils.scss */
.no-pointer {
  cursor: default; }

/* line 37, app/styles/_utils.scss */
.line {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden; }

/* line 45, app/styles/_utils.scss */
.line-xs {
  margin: 0; }

/* line 49, app/styles/_utils.scss */
.line-lg {
  margin-top: 15px;
  margin-bottom: 15px; }

/* line 54, app/styles/_utils.scss */
.line-dashed {
  border-style: dashed !important;
  background-color: transparent;
  border-width: 0; }

/* line 60, app/styles/_utils.scss */
.no-line {
  border-width: 0; }

/* line 64, app/styles/_utils.scss */
.no-border,
.no-borders {
  border-color: transparent;
  border-width: 0; }

/* line 70, app/styles/_utils.scss */
.no-radius {
  border-radius: 0; }

/* line 74, app/styles/_utils.scss */
.block {
  display: block; }

/* line 78, app/styles/_utils.scss */
.block.hide {
  display: none; }

/* line 82, app/styles/_utils.scss */
.inline {
  display: inline-block !important; }

/* line 86, app/styles/_utils.scss */
.none {
  display: none; }

/* line 90, app/styles/_utils.scss */
.pull-none {
  float: none; }

/* line 94, app/styles/_utils.scss */
.rounded {
  border-radius: 500px; }

/* line 98, app/styles/_utils.scss */
.clear {
  display: block;
  overflow: hidden; }

/* line 103, app/styles/_utils.scss */
.no-bg {
  background-color: transparent;
  color: inherit; }

/* line 108, app/styles/_utils.scss */
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 117, app/styles/_utils.scss */
.l-h-0x {
  line-height: 0; }

/* line 121, app/styles/_utils.scss */
.l-h-1x {
  line-height: 1.2; }

/* line 125, app/styles/_utils.scss */
.l-h-2x {
  line-height: 2em; }

/* line 129, app/styles/_utils.scss */
.l-s-1x {
  letter-spacing: 1; }

/* line 133, app/styles/_utils.scss */
.l-s-2x {
  letter-spacing: 2; }

/* line 137, app/styles/_utils.scss */
.l-s-3x {
  letter-spacing: 3; }

/* line 141, app/styles/_utils.scss */
.font-normal {
  font-weight: normal; }

/* line 145, app/styles/_utils.scss */
.font-thin {
  font-weight: 300; }

/* line 149, app/styles/_utils.scss */
.font-bold {
  font-weight: 700; }

/* line 152, app/styles/_utils.scss */
.text-white {
  color: white; }

/* line 155, app/styles/_utils.scss */
.text-3x {
  font-size: 3em; }

/* line 159, app/styles/_utils.scss */
.text-2x {
  font-size: 2em; }

/* line 163, app/styles/_utils.scss */
.text-sm {
  font-size: 14px; }

/* line 167, app/styles/_utils.scss */
.text-xs {
  font-size: 13px; }

/* line 171, app/styles/_utils.scss */
.text-xxs {
  text-indent: -9999px; }

/* line 175, app/styles/_utils.scss */
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* line 182, app/styles/_utils.scss */
.text-cap:first-letter {
  text-transform: uppercase; }

/* line 186, app/styles/_utils.scss */
.text-u-c {
  text-transform: uppercase; }

/* line 190, app/styles/_utils.scss */
.text-l-t {
  text-decoration: line-through; }

/* line 194, app/styles/_utils.scss */
.text-u-l {
  text-decoration: underline; }

/* line 198, app/styles/_utils.scss */
.text-active,
.active > .text,
.active > .auto .text {
  display: none !important; }

/* line 204, app/styles/_utils.scss */
.active > .text-active,
.active > .auto .text-active {
  display: inline-block !important; }

/* line 209, app/styles/_utils.scss */
.box-shadow {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05); }

/* line 213, app/styles/_utils.scss */
.box-shadow-lg {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05); }

/* line 217, app/styles/_utils.scss */
.box-shadow-inset-lg {
  box-shadow: inset 0px 16px 15px -15px rgba(0, 0, 0, 0.2); }

/* line 221, app/styles/_utils.scss */
.text-shadow {
  font-size: 170px;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #e6e6e6, 0 5px 10px rgba(0, 0, 0, 0.125), 0 10px 20px rgba(0, 0, 0, 0.2); }

/* line 226, app/styles/_utils.scss */
.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

/* line 231, app/styles/_utils.scss */
.wrapper-xs {
  padding: 5px; }

/* line 235, app/styles/_utils.scss */
.wrapper-sm {
  padding: 10px; }

/* line 239, app/styles/_utils.scss */
.wrapper {
  padding: 15px; }

/* line 243, app/styles/_utils.scss */
.wrapper-md {
  padding: 20px; }

/* line 247, app/styles/_utils.scss */
.wrapper-lg {
  padding: 30px; }

/* line 251, app/styles/_utils.scss */
.wrapper-xl {
  padding: 50px; }

/* line 255, app/styles/_utils.scss */
.padder-lg {
  padding-left: 30px;
  padding-right: 30px; }

/* line 260, app/styles/_utils.scss */
.padder-md {
  padding-left: 20px;
  padding-right: 20px; }

/* line 265, app/styles/_utils.scss */
.padder {
  padding-left: 15px;
  padding-right: 15px; }

/* line 270, app/styles/_utils.scss */
.padder-v {
  padding-top: 15px;
  padding-bottom: 15px; }

/* line 275, app/styles/_utils.scss */
.padder-v-md {
  padding-top: 20px;
  padding-bottom: 20px; }

/* line 280, app/styles/_utils.scss */
.padder-v-lg {
  padding-top: 30px;
  padding-bottom: 30px; }

/* line 285, app/styles/_utils.scss */
.padder-v-xl {
  padding-top: 50px;
  padding-bottom: 50px; }

/* line 290, app/styles/_utils.scss */
.no-padder {
  padding: 0 !important; }

/* line 294, app/styles/_utils.scss */
.p-l-n {
  padding-left: 0; }

/* line 298, app/styles/_utils.scss */
.pull-in {
  margin-left: -15px;
  margin-right: -15px; }

/* line 303, app/styles/_utils.scss */
.pull-out {
  margin: -10px -15px; }

/* line 307, app/styles/_utils.scss */
.b {
  border: 1px solid rgba(0, 0, 0, 0.05); }

/* line 312, app/styles/_utils.scss */
.b-light {
  border-color: #eee; }

/* line 317, app/styles/_utils.scss */
.b-black {
  border-color: #000; }

/* line 323, app/styles/_utils.scss */
.b-white {
  border-color: #fff; }

/* line 327, app/styles/_utils.scss */
.b-dashed {
  border-style: dashed !important; }

/* line 339, app/styles/_utils.scss */
.b-l-white {
  border-left-color: #fff; }

/* line 343, app/styles/_utils.scss */
.b-l-2x {
  border-left-width: 2px; }

/* line 347, app/styles/_utils.scss */
.b-l-3x {
  border-left-width: 3px; }

/* line 351, app/styles/_utils.scss */
.b-l-4x {
  border-left-width: 4px; }

/* line 355, app/styles/_utils.scss */
.b-l-5x {
  border-left-width: 5px; }

/* line 359, app/styles/_utils.scss */
.b-2x {
  border-width: 2px; }

/* line 363, app/styles/_utils.scss */
.b-3x {
  border-width: 3px; }

/* line 367, app/styles/_utils.scss */
.b-4x {
  border-width: 4px; }

/* line 371, app/styles/_utils.scss */
.b-5x {
  border-width: 5px; }

/* line 375, app/styles/_utils.scss */
.m-xxs {
  margin: 2px 4px; }

/* line 379, app/styles/_utils.scss */
.m-xs {
  margin: 5px; }

/* line 383, app/styles/_utils.scss */
.m-sm {
  margin: 10px; }

/* line 387, app/styles/_utils.scss */
.m {
  margin: 15px; }

/* line 391, app/styles/_utils.scss */
.m-md {
  margin: 20px; }

/* line 395, app/styles/_utils.scss */
.m-lg {
  margin: 30px; }

/* line 399, app/styles/_utils.scss */
.m-xl {
  margin: 50px; }

/* line 403, app/styles/_utils.scss */
.m-n {
  margin: 0 !important; }

/* line 407, app/styles/_utils.scss */
.m-l-none {
  margin-left: 0 !important; }

/* line 411, app/styles/_utils.scss */
.m-l-xs {
  margin-left: 5px; }

/* line 415, app/styles/_utils.scss */
.m-l-sm {
  margin-left: 10px; }

/* line 419, app/styles/_utils.scss */
.m-l {
  margin-left: 15px; }

/* line 423, app/styles/_utils.scss */
.m-l-md {
  margin-left: 20px; }

/* line 427, app/styles/_utils.scss */
.m-l-lg {
  margin-left: 30px; }

/* line 431, app/styles/_utils.scss */
.m-l-xl {
  margin-left: 40px; }

/* line 435, app/styles/_utils.scss */
.m-l-xxl {
  margin-left: 50px; }

/* line 439, app/styles/_utils.scss */
.m-l-n-xxs {
  margin-left: -1px; }

/* line 468, app/styles/_utils.scss */
.block-with-text {
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: 4.5em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em; }
  /* line 451, app/styles/_utils.scss */
  .block-with-text:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0; }
  /* line 457, app/styles/_utils.scss */
  .block-with-text:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

/* line 472, app/styles/_utils.scss */
.m-l-n-xs {
  margin-left: -5px; }

/* line 476, app/styles/_utils.scss */
.m-l-n-sm {
  margin-left: -10px; }

/* line 480, app/styles/_utils.scss */
.m-l-n {
  margin-left: -15px; }

/* line 484, app/styles/_utils.scss */
.m-l-n-md {
  margin-left: -20px; }

/* line 488, app/styles/_utils.scss */
.m-l-n-lg {
  margin-left: -30px; }

/* line 492, app/styles/_utils.scss */
.m-l-n-xl {
  margin-left: -40px; }

/* line 496, app/styles/_utils.scss */
.m-l-n-xxl {
  margin-left: -50px; }

/* line 500, app/styles/_utils.scss */
.m-t-none {
  margin-top: 0 !important; }

/* line 504, app/styles/_utils.scss */
.m-t-xxs {
  margin-top: 1px; }

/* line 508, app/styles/_utils.scss */
.m-t-xs {
  margin-top: 5px; }

/* line 512, app/styles/_utils.scss */
.m-t-sm {
  margin-top: 10px; }

/* line 516, app/styles/_utils.scss */
.m-t {
  margin-top: 15px; }

/* line 520, app/styles/_utils.scss */
.m-t-md {
  margin-top: 20px; }

/* line 524, app/styles/_utils.scss */
.m-t-lg {
  margin-top: 30px; }

/* line 528, app/styles/_utils.scss */
.m-t-xl {
  margin-top: 40px; }

/* line 532, app/styles/_utils.scss */
.m-t-xxl {
  margin-top: 50px; }

/* line 536, app/styles/_utils.scss */
.p-t-none {
  padding-top: 0 !important; }

/* line 540, app/styles/_utils.scss */
.p-t-xxs {
  padding-top: 1px; }

/* line 544, app/styles/_utils.scss */
.p-t-xs {
  padding-top: 5px; }

/* line 548, app/styles/_utils.scss */
.p-t-sm {
  padding-top: 10px; }

/* line 552, app/styles/_utils.scss */
.p-t {
  padding-top: 15px; }

/* line 556, app/styles/_utils.scss */
.p-t-md {
  padding-top: 20px; }

/* line 560, app/styles/_utils.scss */
.p-t-lg {
  padding-top: 30px; }

/* line 564, app/styles/_utils.scss */
.p-t-xl {
  padding-top: 40px; }

/* line 568, app/styles/_utils.scss */
.p-t-xxl {
  padding-top: 50px; }

/* line 572, app/styles/_utils.scss */
.m-t-n-xxs {
  margin-top: -1px; }

/* line 576, app/styles/_utils.scss */
.m-t-n-xs {
  margin-top: -5px; }

/* line 580, app/styles/_utils.scss */
.m-t-n-sm {
  margin-top: -10px; }

/* line 584, app/styles/_utils.scss */
.m-t-n {
  margin-top: -15px; }

/* line 588, app/styles/_utils.scss */
.m-t-n-md {
  margin-top: -20px; }

/* line 592, app/styles/_utils.scss */
.m-t-n-lg {
  margin-top: -30px; }

/* line 596, app/styles/_utils.scss */
.m-t-n-xl {
  margin-top: -40px; }

/* line 600, app/styles/_utils.scss */
.m-t-n-xxl {
  margin-top: -50px; }

/* line 604, app/styles/_utils.scss */
.m-r-none {
  margin-right: 0 !important; }

/* line 608, app/styles/_utils.scss */
.m-r-xxs {
  margin-right: 1px; }

/* line 612, app/styles/_utils.scss */
.m-r-xs {
  margin-right: 5px; }

/* line 616, app/styles/_utils.scss */
.m-r-sm {
  margin-right: 10px; }

/* line 620, app/styles/_utils.scss */
.m-r {
  margin-right: 15px; }

/* line 624, app/styles/_utils.scss */
.m-r-md {
  margin-right: 20px; }

/* line 628, app/styles/_utils.scss */
.m-r-lg {
  margin-right: 30px; }

/* line 632, app/styles/_utils.scss */
.m-r-xl {
  margin-right: 40px; }

/* line 636, app/styles/_utils.scss */
.m-r-xxl {
  margin-right: 50px; }

/* line 640, app/styles/_utils.scss */
.m-r-n-xxs {
  margin-right: -1px; }

/* line 644, app/styles/_utils.scss */
.m-r-n-xs {
  margin-right: -5px; }

/* line 648, app/styles/_utils.scss */
.m-r-n-sm {
  margin-right: -10px; }

/* line 652, app/styles/_utils.scss */
.m-r-n {
  margin-right: -15px; }

/* line 656, app/styles/_utils.scss */
.m-r-n-md {
  margin-right: -20px; }

/* line 660, app/styles/_utils.scss */
.m-r-n-lg {
  margin-right: -30px; }

/* line 664, app/styles/_utils.scss */
.m-r-n-xl {
  margin-right: -40px; }

/* line 668, app/styles/_utils.scss */
.m-r-n-xxl {
  margin-right: -50px; }

/* line 672, app/styles/_utils.scss */
.m-b-none {
  margin-bottom: 0 !important; }

/* line 676, app/styles/_utils.scss */
.m-b-xxs {
  margin-bottom: 1px; }

/* line 680, app/styles/_utils.scss */
.m-b-xs {
  margin-bottom: 5px; }

/* line 684, app/styles/_utils.scss */
.m-b-sm {
  margin-bottom: 10px; }

/* line 688, app/styles/_utils.scss */
.m-b {
  margin-bottom: 15px; }

/* line 692, app/styles/_utils.scss */
.m-b-md {
  margin-bottom: 20px; }

/* line 696, app/styles/_utils.scss */
.m-b-lg {
  margin-bottom: 30px; }

/* line 700, app/styles/_utils.scss */
.m-b-xl {
  margin-bottom: 40px; }

/* line 704, app/styles/_utils.scss */
.m-b-xxl {
  margin-bottom: 50px; }

/* line 708, app/styles/_utils.scss */
.m-b-n-xxs {
  margin-bottom: -1px; }

/* line 712, app/styles/_utils.scss */
.m-b-n-xs {
  margin-bottom: -5px; }

/* line 716, app/styles/_utils.scss */
.m-b-n-sm {
  margin-bottom: -10px; }

/* line 720, app/styles/_utils.scss */
.m-b-n {
  margin-bottom: -15px; }

/* line 724, app/styles/_utils.scss */
.m-b-n-md {
  margin-bottom: -20px; }

/* line 728, app/styles/_utils.scss */
.m-b-n-lg {
  margin-bottom: -30px; }

/* line 732, app/styles/_utils.scss */
.m-b-n-xl {
  margin-bottom: -40px; }

/* line 736, app/styles/_utils.scss */
.m-b-n-xxl {
  margin-bottom: -50px; }

/* line 740, app/styles/_utils.scss */
.w-1x {
  width: 1em; }

/* line 744, app/styles/_utils.scss */
.w-2x {
  width: 2em; }

/* line 748, app/styles/_utils.scss */
.w-3x {
  width: 3em; }

/* line 752, app/styles/_utils.scss */
.w-xxs {
  width: 60px; }

/* line 756, app/styles/_utils.scss */
.w-xs {
  width: 90px; }

/* line 760, app/styles/_utils.scss */
.w-sm {
  width: 150px; }

/* line 764, app/styles/_utils.scss */
.w {
  width: 200px; }

/* line 768, app/styles/_utils.scss */
.w-md {
  width: 240px; }

/* line 772, app/styles/_utils.scss */
.w-lg {
  width: 280px; }

/* line 776, app/styles/_utils.scss */
.w-xl {
  width: 320px; }

/* line 780, app/styles/_utils.scss */
.w-xxl {
  width: 360px; }

@media screen and (max-width: 360px) {
  /* line 785, app/styles/_utils.scss */
  .w-xxl {
    width: 90%; } }

/* line 790, app/styles/_utils.scss */
.w-full {
  width: 100%; }

/* line 794, app/styles/_utils.scss */
.w-auto {
  width: auto; }

/* line 798, app/styles/_utils.scss */
.h-auto {
  height: auto; }

/* line 802, app/styles/_utils.scss */
.h-full {
  height: 100%; }

/* line 806, app/styles/_utils.scss */
.thumb-xxl {
  width: 150px;
  display: inline-block; }

/* line 811, app/styles/_utils.scss */
.thumb-xl {
  width: 128px;
  display: inline-block; }

/* line 816, app/styles/_utils.scss */
.thumb-lg {
  width: 96px;
  display: inline-block; }

/* line 821, app/styles/_utils.scss */
.thumb-md {
  width: 64px;
  display: inline-block; }

/* line 826, app/styles/_utils.scss */
.thumb {
  width: 50px;
  display: inline-block; }

/* line 831, app/styles/_utils.scss */
.thumb-sm {
  width: 40px;
  display: inline-block; }

/* line 836, app/styles/_utils.scss */
.thumb-xs {
  width: 34px;
  display: inline-block; }

/* line 841, app/styles/_utils.scss */
.thumb-xxs {
  width: 30px;
  display: inline-block; }

/* line 852, app/styles/_utils.scss */
.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img,
.thumb-btn img {
  height: auto;
  max-width: 100%;
  vertical-align: middle; }

/* line 859, app/styles/_utils.scss */
.img-full {
  width: 100%; }
  /* line 861, app/styles/_utils.scss */
  .img-full img {
    width: 100%; }

/* line 866, app/styles/_utils.scss */
.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  /* line 870, app/styles/_utils.scss */
  .scrollable.hover {
    overflow-y: hidden !important; }
    /* line 872, app/styles/_utils.scss */
    .scrollable.hover:hover {
      overflow: visible !important;
      overflow-y: auto !important; }
  /* line 877, app/styles/_utils.scss */
  .smart .scrollable {
    overflow-y: auto !important; }

/* line 882, app/styles/_utils.scss */
.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

/* line 888, app/styles/_utils.scss */
.scroll-y {
  overflow-y: auto; }

/* line 892, app/styles/_utils.scss */
.scroll-x {
  overflow-x: auto; }

/* line 896, app/styles/_utils.scss */
.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050; }
  /* line 903, app/styles/_utils.scss */
  .backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0); }
  /* line 907, app/styles/_utils.scss */
  .backdrop.in {
    opacity: 0.8;
    filter: alpha(opacity=80); }

/*desktop*/
@media screen and (min-width: 992px) {
  /* line 917, app/styles/_utils.scss */
  .col-lg-2-4 {
    width: 20.000%;
    float: left; } }

@media (min-width: 768px) and (max-width: 991px) {
  /* line 925, app/styles/_utils.scss */
  .hidden-sm.show {
    display: inherit !important; }
  /* line 928, app/styles/_utils.scss */
  .no-m-sm {
    margin: 0 !important; } }

/*phone*/
@media (max-width: 767px) {
  /* line 937, app/styles/_utils.scss */
  .w-auto-xs {
    width: auto; }
  /* line 940, app/styles/_utils.scss */
  .shift {
    display: none !important; }
  /* line 943, app/styles/_utils.scss */
  .shift.in {
    display: block !important; }
  /* line 946, app/styles/_utils.scss */
  .row-2 [class*="col"] {
    width: 50%;
    float: left; }
  /* line 950, app/styles/_utils.scss */
  .row-2 .col-0 {
    clear: none; }
  /* line 953, app/styles/_utils.scss */
  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0; }
  /* line 957, app/styles/_utils.scss */
  .text-center-xs {
    text-align: center; }
  /* line 960, app/styles/_utils.scss */
  .text-left-xs {
    text-align: left; }
  /* line 963, app/styles/_utils.scss */
  .text-right-xs {
    text-align: right; }
  /* line 966, app/styles/_utils.scss */
  .no-border-xs {
    border-width: 0; }
  /* line 969, app/styles/_utils.scss */
  .pull-none-xs {
    float: none !important; }
  /* line 972, app/styles/_utils.scss */
  .pull-right-xs {
    float: right !important; }
  /* line 975, app/styles/_utils.scss */
  .pull-left-xs {
    float: left !important; }
  /* line 978, app/styles/_utils.scss */
  .dropdown-menu.pull-none-xs {
    left: 0; }
  /* line 981, app/styles/_utils.scss */
  .hidden-xs.show {
    display: inherit !important; }
  /* line 984, app/styles/_utils.scss */
  .wrapper-lg,
  .wrapper-md {
    padding: 15px; }
  /* line 988, app/styles/_utils.scss */
  .padder-lg,
  .padder-md {
    padding-left: 15px;
    padding-right: 15px; }
  /* line 993, app/styles/_utils.scss */
  .no-m-xs {
    margin: 0 !important; } }

/* line 1, app/styles/themes/_blue.scss */
.page-content {
  background: #343f47; }

/* line 5, app/styles/themes/_blue.scss */
.navbar .logo-box {
  background: #12afcb; }

/* line 9, app/styles/themes/_blue.scss */
.navbar .logo-box a {
  color: #fff; }

/* line 13, app/styles/themes/_blue.scss */
.sidebar {
  background: #343f47; }

/* line 17, app/styles/themes/_blue.scss */
.sidebar-header {
  background: #343f47; }

/* line 21, app/styles/themes/_blue.scss */
.sidebar-profile span small {
  color: #cedadd; }

/* line 25, app/styles/themes/_blue.scss */
.menu {
  background: #343f47; }

/* line 29, app/styles/themes/_blue.scss */
.menu.accordion-menu a {
  color: #cedadd; }

/* line 33, app/styles/themes/_blue.scss */
.menu.accordion-menu > li > a:hover {
  background: #2c363e;
  color: #fff; }

/* line 38, app/styles/themes/_blue.scss */
.menu.accordion-menu > li.active > a,
.menu.accordion-menu > li.active.open > a {
  background: #2c363e;
  color: #fff; }

/* line 44, app/styles/themes/_blue.scss */
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li.droplink:hover > a,
.horizontal-bar .accordion-menu > li.droplink:hover > a,
.menu.accordion-menu ul,
.menu.accordion-menu li.open > a,
.hover-menu .accordion-menu > li.droplink:hover > a {
  background: #2c363e;
  color: #fff; }

/* line 53, app/styles/themes/_blue.scss */
.menu.accordion-menu ul li a:hover,
.menu.accordion-menu ul li.active a {
  background: #343f47;
  color: #fff; }

/* line 59, app/styles/themes/_blue.scss */
.menu.accordion-menu ul li a {
  color: #cedadd; }

/* line 1, app/styles/_chartist.scss */
.ct-double-octave:after, .ct-major-eleventh:after, .ct-major-second:after, .ct-major-seventh:after, .ct-major-sixth:after, .ct-major-tenth:after, .ct-major-third:after, .ct-major-twelfth:after, .ct-minor-second:after, .ct-minor-seventh:after, .ct-minor-sixth:after, .ct-minor-third:after, .ct-octave:after, .ct-perfect-fifth:after, .ct-perfect-fourth:after, .ct-square:after {
  content: "";
  clear: both; }

/* line 1, app/styles/_chartist.scss */
.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: .75rem;
  line-height: 1; }

/* line 1, app/styles/_chartist.scss */
.ct-grid-background, .ct-line {
  fill: none; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-bar .ct-label, .ct-chart-line .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-donut .ct-label, .ct-chart-pie .ct-label {
  dominant-baseline: central; }

/* line 1, app/styles/_chartist.scss */
.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

/* line 1, app/styles/_chartist.scss */
.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

/* line 1, app/styles/_chartist.scss */
.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

/* line 1, app/styles/_chartist.scss */
.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

/* line 1, app/styles/_chartist.scss */
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

/* line 1, app/styles/_chartist.scss */
.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

/* line 1, app/styles/_chartist.scss */
.ct-point {
  stroke-width: 10px;
  stroke-linecap: round; }

/* line 1, app/styles/_chartist.scss */
.ct-line {
  stroke-width: 4px; }

/* line 1, app/styles/_chartist.scss */
.ct-area {
  stroke: none;
  fill-opacity: .1; }

/* line 1, app/styles/_chartist.scss */
.ct-bar {
  fill: none;
  stroke-width: 10px; }

/* line 1, app/styles/_chartist.scss */
.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

/* line 1, app/styles/_chartist.scss */
.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
  stroke: #d70206; }

/* line 1, app/styles/_chartist.scss */
.ct-series-a .ct-area, .ct-series-a .ct-slice-pie {
  fill: #d70206; }

/* line 1, app/styles/_chartist.scss */
.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut {
  stroke: #93f241; }

/* line 1, app/styles/_chartist.scss */
.ct-series-b .ct-area, .ct-series-b .ct-slice-pie {
  fill: #f05b4f; }

/* line 1, app/styles/_chartist.scss */
.ct-series-c .ct-bar, .ct-series-c .ct-line, .ct-series-c .ct-point, .ct-series-c .ct-slice-donut {
  stroke: #f4c63d; }

/* line 1, app/styles/_chartist.scss */
.ct-series-c .ct-area, .ct-series-c .ct-slice-pie {
  fill: #f4c63d; }

/* line 1, app/styles/_chartist.scss */
.ct-series-d .ct-bar, .ct-series-d .ct-line, .ct-series-d .ct-point, .ct-series-d .ct-slice-donut {
  stroke: #d17905; }

/* line 1, app/styles/_chartist.scss */
.ct-series-d .ct-area, .ct-series-d .ct-slice-pie {
  fill: #d17905; }

/* line 1, app/styles/_chartist.scss */
.ct-series-e .ct-bar, .ct-series-e .ct-line, .ct-series-e .ct-point, .ct-series-e .ct-slice-donut {
  stroke: #453d3f; }

/* line 1, app/styles/_chartist.scss */
.ct-series-e .ct-area, .ct-series-e .ct-slice-pie {
  fill: #453d3f; }

/* line 1, app/styles/_chartist.scss */
.ct-series-f .ct-bar, .ct-series-f .ct-line, .ct-series-f .ct-point, .ct-series-f .ct-slice-donut {
  stroke: #59922b; }

/* line 1, app/styles/_chartist.scss */
.ct-series-f .ct-area, .ct-series-f .ct-slice-pie {
  fill: #59922b; }

/* line 1, app/styles/_chartist.scss */
.ct-series-g .ct-bar, .ct-series-g .ct-line, .ct-series-g .ct-point, .ct-series-g .ct-slice-donut {
  stroke: #0544d3; }

/* line 1, app/styles/_chartist.scss */
.ct-series-g .ct-area, .ct-series-g .ct-slice-pie {
  fill: #0544d3; }

/* line 1, app/styles/_chartist.scss */
.ct-series-h .ct-bar, .ct-series-h .ct-line, .ct-series-h .ct-point, .ct-series-h .ct-slice-donut {
  stroke: #6b0392; }

/* line 1, app/styles/_chartist.scss */
.ct-series-h .ct-area, .ct-series-h .ct-slice-pie {
  fill: #6b0392; }

/* line 1, app/styles/_chartist.scss */
.ct-series-i .ct-bar, .ct-series-i .ct-line, .ct-series-i .ct-point, .ct-series-i .ct-slice-donut {
  stroke: #f05b4f; }

/* line 1, app/styles/_chartist.scss */
.ct-series-i .ct-area, .ct-series-i .ct-slice-pie {
  fill: #f05b4f; }

/* line 1, app/styles/_chartist.scss */
.ct-series-j .ct-bar, .ct-series-j .ct-line, .ct-series-j .ct-point, .ct-series-j .ct-slice-donut {
  stroke: #dda458; }

/* line 1, app/styles/_chartist.scss */
.ct-series-j .ct-area, .ct-series-j .ct-slice-pie {
  fill: #dda458; }

/* line 1, app/styles/_chartist.scss */
.ct-series-k .ct-bar, .ct-series-k .ct-line, .ct-series-k .ct-point, .ct-series-k .ct-slice-donut {
  stroke: #eacf7d; }

/* line 1, app/styles/_chartist.scss */
.ct-series-k .ct-area, .ct-series-k .ct-slice-pie {
  fill: #eacf7d; }

/* line 1, app/styles/_chartist.scss */
.ct-series-l .ct-bar, .ct-series-l .ct-line, .ct-series-l .ct-point, .ct-series-l .ct-slice-donut {
  stroke: #86797d; }

/* line 1, app/styles/_chartist.scss */
.ct-series-l .ct-area, .ct-series-l .ct-slice-pie {
  fill: #86797d; }

/* line 1, app/styles/_chartist.scss */
.ct-series-m .ct-bar, .ct-series-m .ct-line, .ct-series-m .ct-point, .ct-series-m .ct-slice-donut {
  stroke: #b2c326; }

/* line 1, app/styles/_chartist.scss */
.ct-series-m .ct-area, .ct-series-m .ct-slice-pie {
  fill: #b2c326; }

/* line 1, app/styles/_chartist.scss */
.ct-series-n .ct-bar, .ct-series-n .ct-line, .ct-series-n .ct-point, .ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

/* line 1, app/styles/_chartist.scss */
.ct-series-n .ct-area, .ct-series-n .ct-slice-pie {
  fill: #6188e2; }

/* line 1, app/styles/_chartist.scss */
.ct-series-o .ct-bar, .ct-series-o .ct-line, .ct-series-o .ct-point, .ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

/* line 1, app/styles/_chartist.scss */
.ct-series-o .ct-area, .ct-series-o .ct-slice-pie {
  fill: #a748ca; }

/* line 1, app/styles/_chartist.scss */
.ct-square {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-square:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-second:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-second:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-third:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-third:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fourth:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fifth:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-sixth:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%; }

/* line 1, app/styles/_chartist.scss */
.ct-golden-section:after {
  content: "";
  display: table;
  clear: both; }

/* line 1, app/styles/_chartist.scss */
.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-sixth:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-seventh:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-seventh:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-octave {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%; }

/* line 1, app/styles/_chartist.scss */
.ct-octave:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-tenth:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-eleventh:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%; }

/* line 1, app/styles/_chartist.scss */
.ct-major-twelfth:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/_chartist.scss */
.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }

/* line 1, app/styles/_chartist.scss */
.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%; }

/* line 1, app/styles/_chartist.scss */
.ct-double-octave:after {
  display: table; }

/* line 1, app/styles/_chartist.scss */
.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/* line 1, app/styles/datepicker.scss */
.datepicker a,
[datepicker] a,
datepicker a {
  color: inherit;
  text-decoration: none; }

/* line 8, app/styles/datepicker.scss */
.datepicker a:hover,
[datepicker] a:hover,
datepicker a:hover {
  text-decoration: none; }

/* line 14, app/styles/datepicker.scss */
.datepicker select,
.datepicker select:focus,
.datepicker select:hover,
[datepicker] select,
[datepicker] select:focus,
[datepicker] select:hover,
datepicker select,
datepicker select:focus,
datepicker select:hover {
  width: 100%;
  overflow: hidden;
  background: 0 0;
  color: #fff;
  background-color: #138efa;
  border-radius: 2px;
  border: 0;
  margin-top: 5px; }

/* line 33, app/styles/datepicker.scss */
._720kb-datepicker-calendar-body,
._720kb-datepicker-calendar-days-header,
._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-years-pagination-pages,
.datepicker,
[datepicker],
datepicker {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 13.5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  float: left;
  clear: right;
  position: relative; }

/* line 53, app/styles/datepicker.scss */
._720kb-datepicker-calendar {
  background: #fff;
  color: #333;
  position: absolute;
  z-index: 999;
  min-width: 220px;
  margin: 0 auto;
  width: 101%;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  visibility: hidden;
  overflow: hidden;
  margin-left: -0.5%;
  padding: 0 0 2% 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

/* line 73, app/styles/datepicker.scss */
._720kb-datepicker-calendar._720kb-datepicker-forced-to-open,
._720kb-datepicker-calendar._720kb-datepicker-open {
  visibility: visible; }

/* line 78, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header {
  text-align: center;
  font-size: 15px;
  line-height: 40px; }

/* line 84, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header:nth-child(odd) {
  background: #138efa; }

/* line 88, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header:nth-child(even) {
  background: #7bc6fc; }

/* line 92, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header-left,
._720kb-datepicker-calendar-header-middle,
._720kb-datepicker-calendar-header-right {
  width: 15%;
  float: left; }

/* line 99, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header-middle {
  width: 70%; }

/* line 103, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header-closed-pagination::after {
  content: " \25BE"; }

/* line 107, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header-opened-pagination::after {
  content: " \25BE";
  margin-left: 4px;
  position: relative;
  bottom: -3px;
  display: inline-block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

/* line 120, app/styles/datepicker.scss */
._720kb-datepicker-calendar-body {
  width: 96%;
  margin: 2%;
  text-align: center; }

/* line 126, app/styles/datepicker.scss */
._720kb-datepicker-calendar-day {
  cursor: pointer;
  font-size: 12.5px;
  width: 12.2%;
  margin: 5px 1%;
  padding: 1.5% 0;
  float: left;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

/* line 138, app/styles/datepicker.scss */
._720kb-datepicker-calendar-day._720kb-datepicker-active,
._720kb-datepicker-calendar-day:hover {
  background: rgba(0, 0, 0, 0.03); }

/* line 143, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header a,
._720kb-datepicker-calendar-header a:hover {
  text-decoration: none;
  padding: 3% 9% 4% 9%;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.55);
  font-weight: 700;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

/* line 155, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header a:hover {
  color: rgba(0, 0, 0, 0.9);
  background: rgba(255, 255, 255, 0.45); }

/* line 160, app/styles/datepicker.scss */
._720kb-datepicker-calendar-month {
  color: #fff; }

/* line 164, app/styles/datepicker.scss */
._720kb-datepicker-calendar-month span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4); }

/* line 169, app/styles/datepicker.scss */
._720kb-datepicker-calendar-month a span i {
  font-style: normal;
  font-size: 15px; }

/* line 174, app/styles/datepicker.scss */
._720kb-datepicker-calendar-month a,
._720kb-datepicker-calendar-month a:hover {
  padding: 3px;
  margin-left: 1%; }

/* line 180, app/styles/datepicker.scss */
._720kb-datepicker-calendar-years-pagination {
  padding: 2% 0 0 0;
  float: left;
  clear: right;
  width: 100%; }

/* line 187, app/styles/datepicker.scss */
._720kb-datepicker-calendar-years-pagination a,
._720kb-datepicker-calendar-years-pagination a:hover {
  font-size: 12px;
  padding: 0 7px;
  font-weight: 400;
  margin: 3px 1% 0 1%;
  line-height: 20px;
  display: inline-block; }

/* line 197, app/styles/datepicker.scss */
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  background: rgba(255, 255, 255, 0.45); }

/* line 203, app/styles/datepicker.scss */
._720kb-datepicker-calendar-years-pagination-pages a,
._720kb-datepicker-calendar-years-pagination-pages a:hover {
  padding: 5px 10px; }

/* line 208, app/styles/datepicker.scss */
._720kb-datepicker-calendar-days-header {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2% 0 2%;
  background: rgba(19, 142, 250, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.02); }

/* line 216, app/styles/datepicker.scss */
._720kb-datepicker-calendar-days-header div {
  width: 14.18%;
  font-weight: 500;
  font-size: 11.5px;
  padding: 10px 0;
  float: left;
  text-align: center;
  color: rgba(0, 0, 0, 0.7); }

/* line 226, app/styles/datepicker.scss */
._720kb-datepicker-calendar-days ._720kb-datepicker-default-button {
  font-size: 18.5px;
  position: relative;
  bottom: -0.5px; }

/* line 232, app/styles/datepicker.scss */
._720kb-datepicker-default-button {
  padding: 0 4.5px; }

/* line 236, app/styles/datepicker.scss */
._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item {
  width: 95%;
  float: none;
  margin: 0 auto; }

/* line 242, app/styles/datepicker.scss */
._720kb-datepicker-item-hidden {
  visibility: hidden; }

/* line 246, app/styles/datepicker.scss */
._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover {
  color: rgba(0, 0, 0, 0.2);
  background: rgba(25, 2, 0, 0.02);
  cursor: default; }

/* line 7, app/styles/user_list.scss */
.userMain .userBlock {
  float: left;
  width: 100%;
  box-shadow: 0px 0px 23px -3px #ccc;
  padding-bottom: 12px;
  margin-bottom: 30px;
  overflow: hidden;
  background: #fff;
  margin-top: 5px; }

/* line 18, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg {
  float: left;
  overflow: hidden;
  height: 77px;
  width: 100%;
  background: #af322a; }

/* line 26, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg.yellow {
  background: linear-gradient(#e6ba29 0%, #a7871e 100%); }

/* line 30, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg.blue {
  background: linear-gradient(#2989d8 0%, #1e5799 100%); }

/* line 34, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg.green {
  background: linear-gradient(#9bc90d 0%, #79a70a 100%); }

/* line 38, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg.greendark {
  background: linear-gradient(#4a9e44 0%, #226301 100%); }

/* line 42, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg.purple {
  background: linear-gradient(#e65e95 0%, #91103f 100%); }

/* line 46, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg.red {
  background: #af322a !important; }

/* line 50, app/styles/user_list.scss */
.userMain .userBlock .backgrounImg img {
  width: auto; }

/* line 54, app/styles/user_list.scss */
.userMain .userBlock .userImg {
  text-align: center; }

/* line 58, app/styles/user_list.scss */
.userMain .userBlock .userImg img {
  width: 105px;
  height: 80px;
  margin-top: -39px;
  border-radius: 11px;
  border: 5px solid #fff;
  background: aliceblue; }

/* line 67, app/styles/user_list.scss */
.userMain .userBlock .userDescription > div:first-child {
  text-align: center;
  height: 57px; }

/* line 72, app/styles/user_list.scss */
.userMain .userBlock .userDescription div h5 {
  margin-bottom: 2px;
  font-weight: 600; }

/* line 76, app/styles/user_list.scss */
.userMain .userBlock .userDescription div h5 a div {
  margin-top: 8px; }

/* line 80, app/styles/user_list.scss */
.userMain .userBlock .userDescription div p {
  margin-bottom: 5px;
  min-height: 29px; }

/* line 85, app/styles/user_list.scss */
.userMain .userBlock .userDescription .btn {
  padding: 0px 23px 0px 23px;
  height: 22px;
  border-radius: 0;
  font-size: 12px;
  background: #0198dd;
  color: #fff; }

/* line 94, app/styles/user_list.scss */
.userMain .userBlock .userDescription .btn:hover {
  opacity: 0.7; }

/* line 98, app/styles/user_list.scss */
.userMain .userBlock .followrs {
  display: inline-flex;
  margin-right: 10px;
  border-right: 1px solid #ccc;
  padding-right: 10px; }

/* line 105, app/styles/user_list.scss */
.userMain .userBlock .followrs .number {
  font-size: 15px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -1px; }

/* line 112, app/styles/user_list.scss */
.shape {
  border-style: solid;
  border-width: 0 85px 50px 0;
  height: 0px;
  width: 0px;
  position: absolute;
  right: 8px;
  -ms-transform: rotate(360deg);
  /* IE 9 */
  -o-transform: rotate(360deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(360deg);
  /* Safari and Chrome */
  transform: rotate(360deg); }

/* line 128, app/styles/user_list.scss */
.userBlock {
  border: 1px solid; }

/* line 132, app/styles/user_list.scss */
.userBlock.actif {
  border-color: #428bca; }

/* line 136, app/styles/user_list.scss */
.userBlock.inactif {
  border-color: #d9534f; }

/* line 140, app/styles/user_list.scss */
.userBlock .shape.actif {
  border-color: transparent #428bca transparent transparent;
  border-color: rgba(255, 255, 255, 0) #428bca rgba(255, 255, 255, 0) rgba(255, 255, 255, 0); }

/* line 145, app/styles/user_list.scss */
.userBlock .shape.inactif {
  border-color: transparent #d9534f transparent transparent;
  border-color: rgba(255, 255, 255, 0) #d9534f rgba(255, 255, 255, 0) rgba(255, 255, 255, 0); }

/* line 150, app/styles/user_list.scss */
.shape-text {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  right: -45px;
  top: 0px;
  white-space: nowrap;
  -ms-transform: rotate(30deg);
  /* IE 9 */
  -o-transform: rotate(360deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(30deg);
  /* Safari and Chrome */
  transform: rotate(30deg); }

/* line 167, app/styles/user_list.scss */
.ribbon {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right; }

/* line 178, app/styles/user_list.scss */
.ribbon > span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 20px;
  left: -19px; }

/* line 195, app/styles/user_list.scss */
.ribbon > span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent; }

/* line 205, app/styles/user_list.scss */
.ribbon > span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent; }

/* line 215, app/styles/user_list.scss */
.ribbon.green > span {
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%); }

/* line 220, app/styles/user_list.scss */
.ribbon.green > span::before,
.ribbon.green > span::after {
  border-left: 3px solid #79a70a;
  border-top: 3px solid #79a70a; }

/* line 226, app/styles/user_list.scss */
.ribbon.yellow > span {
  background: #a7871e;
  background: linear-gradient(#e6ba29 0%, #a7871e 100%); }

/* line 231, app/styles/user_list.scss */
.ribbon.yellow > span::before,
.ribbon.yellow > span::after {
  border-left: 3px solid #a7871e;
  border-top: 3px solid #a7871e; }

/* line 237, app/styles/user_list.scss */
.ribbon.blue > span {
  background: #1e5799;
  background: linear-gradient(#2989d8 0%, #1e5799 100%); }

/* line 242, app/styles/user_list.scss */
.ribbon.blue > span::before,
.ribbon.blue > span::after {
  border-left: 3px solid #1e5799;
  border-top: 3px solid #1e5799; }

/* line 248, app/styles/user_list.scss */
.ribbon.purple > span {
  background: #91103f;
  background: linear-gradient(#e65e95 0%, #91103f 100%); }

/* line 253, app/styles/user_list.scss */
.ribbon.purple > span::before,
.ribbon.purple > span::after {
  border-left: 3px solid #91103f;
  border-top: 3px solid #91103f; }

/*  greendark */
/* line 260, app/styles/user_list.scss */
.ribbon.greendark > span {
  background: #226301;
  background: linear-gradient(#4a9e44 0%, #226301 100%); }

/* line 265, app/styles/user_list.scss */
.ribbon.greendark > span::before,
.ribbon.greendark > span::after {
  border-left: 3px solid #226301;
  border-top: 3px solid #226301; }

/* line 271, app/styles/user_list.scss */
.ratings {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #b1b1b1;
  overflow: hidden;
  margin-top: -5px; }

/* line 280, app/styles/user_list.scss */
.full-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #fde16d; }

/* line 289, app/styles/user_list.scss */
.empty-stars:before,
.full-stars:before {
  content: "\2605\2605\2605\2605\2605";
  font-size: 14pt; }

/* line 295, app/styles/user_list.scss */
.empty-stars:before {
  -webkit-text-stroke: 1px #848484; }

/* line 299, app/styles/user_list.scss */
.full-stars:before {
  -webkit-text-stroke: 1px orange; }

/* Webkit-text-stroke is not supported on firefox or IE */
/* Firefox */
@-moz-document url-prefix() {
  /* line 306, app/styles/user_list.scss */
  .full-stars {
    color: #ecbe24; } }

/* line 311, app/styles/user_list.scss */
.ml-10 {
  margin-left: 10px; }

/* line 315, app/styles/user_list.scss */
.mr-10 {
  margin-right: 10px; }

/**
 *
 */
/* line 322, app/styles/user_list.scss */
.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 1.5rem;
  background: #fff; }

/* line 330, app/styles/user_list.scss */
.profile-img {
  text-align: center; }

/* line 334, app/styles/user_list.scss */
.profile-img img {
  width: 70%;
  height: 100%; }

/* line 339, app/styles/user_list.scss */
.profile-img .file {
  position: relative;
  overflow: hidden;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  cursor: pointer !important;
  background: rgba(51, 51, 51, 0.658); }

/* line 350, app/styles/user_list.scss */
.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer !important; }

/* line 361, app/styles/user_list.scss */
.profile-head div div h3 {
  color: #333; }

/* line 365, app/styles/user_list.scss */
.profile-head div div h4 {
  color: #0062cc; }

/* line 369, app/styles/user_list.scss */
.btn_menu_success {
  position: relative;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#05ac50+0,21dd72+50,05c44e+51,05ac50+71,05ac50+100 */
  background: #05ac50;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#05ac50', endColorstr='#05ac50', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  background-size: 400% 400%;
  border: 1px solid transparent;
  margin: 6px 3px;
  min-width: 95px; }

/* line 394, app/styles/user_list.scss */
.btn_menu_success > span.badge_actif {
  position: absolute;
  right: 0;
  top: -15px; }

/* line 400, app/styles/user_list.scss */
.btn_menu_success > span.badge_inactif {
  position: absolute;
  right: 29px;
  top: -15px; }

/* line 406, app/styles/user_list.scss */
.btn_menu_primary {
  position: relative;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5a7ce2+0,8283e8+50,5c5de8+51,565bd8+71,575cdb+100 */
  background: #5a7ce2;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5a7ce2', endColorstr='#575cdb', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  background-size: 400% 400%;
  border: 1px solid transparent;
  margin: 6px 3px;
  min-width: 110px; }

/* line 431, app/styles/user_list.scss */
.btn_menu_primary > span.actif:first-child {
  position: absolute;
  right: 0;
  top: -15px; }

/* line 437, app/styles/user_list.scss */
.btn_menu_primary > span.inactif:nth-child(2) {
  position: absolute;
  right: 29px;
  top: -15px; }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 31%; }
  50% {
    background-position: 100% 70%; }
  100% {
    background-position: 0% 31%; } }

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 31%; }
  50% {
    background-position: 100% 70%; }
  100% {
    background-position: 0% 31%; } }

@keyframes AnimationName {
  0% {
    background-position: 0% 31%; }
  50% {
    background-position: 100% 70%; }
  100% {
    background-position: 0% 31%; } }

/* line 485, app/styles/user_list.scss */
html,
body {
  height: 100%;
  width: 100%;
  margin: 0; }

/* line 492, app/styles/user_list.scss */
.custom-radios div {
  display: inline-block; }

/* line 496, app/styles/user_list.scss */
.custom-radios input[type="radio"] {
  display: none; }

/* line 500, app/styles/user_list.scss */
.custom-radios input[type="radio"] + label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px; }

/* line 506, app/styles/user_list.scss */
.custom-radios input[type="radio"] + label span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px; }

/* line 522, app/styles/user_list.scss */
.custom-radios input[type="radio"] + label span img {
  opacity: 0;
  transition: all 0.3s ease; }

/* line 527, app/styles/user_list.scss */
.custom-radios input[type="radio"]#color-1 + label span {
  background-color: #2ecc71; }

/* line 531, app/styles/user_list.scss */
.custom-radios input[type="radio"]#color-2 + label span {
  background-color: #3498db; }

/* line 535, app/styles/user_list.scss */
.custom-radios input[type="radio"]#color-3 + label span {
  background-color: #f1c40f; }

/* line 539, app/styles/user_list.scss */
.custom-radios input[type="radio"]#color-4 + label span {
  background-color: #e74c3c; }

/* line 543, app/styles/user_list.scss */
.custom-radios input[type="radio"]:checked + label span {
  opacity: 1;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg) center center no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block; }

/* form starting stylings ------------------------------- */
/* line 551, app/styles/user_list.scss */
.new_group {
  position: absolute;
  right: 0;
  top: -32px; }

/* line 556, app/styles/user_list.scss */
.new_group > input {
  font-size: 18px;
  /* padding: 10px 10px 10px 5px; */
  padding: 5px 5px 5px 5px;
  display: block;
  width: 300px;
  border: none;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px; }

/* line 566, app/styles/user_list.scss */
.new_group > input:focus {
  outline: none; }

/* LABEL ======================================= */
/* line 570, app/styles/user_list.scss */
.new_group > label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

/* active state */
/* line 583, app/styles/user_list.scss */
.new_group > input:focus ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264ae; }

/* BOTTOM BARS ================================= */
/* line 589, app/styles/user_list.scss */
.new_group > .bar {
  position: relative;
  display: block;
  width: 300px; }

/* line 594, app/styles/user_list.scss */
.new_group > .bar:before,
.new_group > .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

/* line 606, app/styles/user_list.scss */
.new_group > .bar:before {
  left: 50%; }

/* line 609, app/styles/user_list.scss */
.new_group > .bar:after {
  right: 50%; }

/* active state */
/* line 613, app/styles/user_list.scss */
.new_group > input:focus ~ .bar:before,
.new_group > input:focus ~ .bar:after {
  width: 50%; }

/* HIGHLIGHTER ================================== */
/* line 618, app/styles/user_list.scss */
.new_group > .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5; }

/* active state */
/* line 628, app/styles/user_list.scss */
.new_group > input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease; }

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae; }
  to {
    width: 0;
    background: transparent; } }

@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae; }
  to {
    width: 0;
    background: transparent; } }

@keyframes inputHighlighter {
  from {
    background: #5264ae; }
  to {
    width: 0;
    background: transparent; } }

/* line 668, app/styles/user_list.scss */
.fa_search_user {
  position: absolute;
  right: 17px;
  top: 11px;
  font-size: 18px;
  color: blue;
  font-weight: bold; }

/**
 * Modal design for user profile
 */
/* line 680, app/styles/user_list.scss */
.dev_nav_center {
  display: flex;
  flex-direction: row;
  justify-content: start;
  border-bottom: 1px solid #ddd; }

/* line 687, app/styles/user_list.scss */
.dev_nav_center > li > a {
  font-size: 15px;
  color: #495057;
  font-weight: 600;
  padding: 2px 10px 3px 0px;
  margin-right: 10px;
  margin-left: 10px;
  border: none !important; }

/* line 697, app/styles/user_list.scss */
.dev_nav_center > li > a {
  text-decoration: none;
  padding-bottom: 5px; }

/* line 702, app/styles/user_list.scss */
.dev_nav_center > li.active {
  border-bottom: 3px solid blue; }

/* form starting stylings ------------------------------- */
/* line 708, app/styles/user_list.scss */
.input-2 {
  position: relative;
  width: 100%; }

/* line 712, app/styles/user_list.scss */
.input-2 > input {
  font-size: 15px;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  display: block;
  width: 100%;
  border: none; }

/* line 720, app/styles/user_list.scss */
.input-2 > input:focus {
  outline: none; }

/* LABEL ======================================= */
/* line 724, app/styles/user_list.scss */
.input-2 > label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

/* active state */
/* line 737, app/styles/user_list.scss */
.input-2 > input:focus ~ label,
.input-2 > input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264ae; }

/* BOTTOM BARS ================================= */
/* line 744, app/styles/user_list.scss */
.input-2 > .bar {
  position: relative;
  display: block;
  width: 100%; }

/* line 749, app/styles/user_list.scss */
.input-2 > .bar:before,
.input-2 > .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

/* line 761, app/styles/user_list.scss */
.input-2 > .bar:before {
  left: 50%; }

/* line 764, app/styles/user_list.scss */
.input-2 > .bar:after {
  right: 50%; }

/* active state */
/* line 768, app/styles/user_list.scss */
.input-2 > input:focus ~ .bar:before,
.input-2 > input:focus ~ .bar:after,
.input-2 > input:valid ~ .bar:before,
.input-2 > input:valid ~ .bar:after {
  width: 50%; }

/* HIGHLIGHTER ================================== */
/* line 775, app/styles/user_list.scss */
.input-2 > .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5; }

/* active state */
/* line 785, app/styles/user_list.scss */
.input-2 > input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease; }

/* line 790, app/styles/user_list.scss */
.input-2 > .fa.fa_input {
  position: absolute;
  right: 5px;
  top: 9px;
  font-size: 17px;
  color: blue;
  font-weight: bold; }

/* line 798, app/styles/user_list.scss */
.input-2 > input {
  background: aliceblue !important; }

/* line 801, app/styles/user_list.scss */
.input-2 {
  position: relative;
  display: block;
  width: 100%; }

/* line 807, app/styles/user_list.scss */
.select-2 {
  background: aliceblue !important;
  border: none; }

/* line 812, app/styles/user_list.scss */
.select-2:focus,
.select-2:valid {
  border: none;
  border-bottom: 2px solid #5163ae; }

/* line 817, app/styles/user_list.scss */
.select-2:before,
.select-2:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

/* line 829, app/styles/user_list.scss */
.select-2:before {
  left: 50%; }

/* line 832, app/styles/user_list.scss */
.select-2:after {
  right: 50%; }

/* line 836, app/styles/user_list.scss */
.label_select-2 {
  left: 10px;
  position: absolute;
  top: -19px;
  color: #5163ae; }

/* line 843, app/styles/user_list.scss */
.info-header {
  border-bottom: 2px solid blue;
  margin-bottom: 11px;
  min-height: 72px;
  border-radius: 20px;
  margin-right: 15px; }

/* line 851, app/styles/user_list.scss */
.info-equipement > div {
  font-size: 14px;
  line-height: 32px; }

/* line 856, app/styles/user_list.scss */
.info-equipement > h5 {
  margin-top: 22px;
  color: blue; }

/* line 861, app/styles/user_list.scss */
.btn-gamme {
  width: 100%;
  margin-top: 30px; }

/* line 865, app/styles/user_list.scss */
.sans-depot {
  border: 1px solid red !important;
  background: rgba(238, 110, 110, 0.61) !important; }

/* line 1, app/styles/styles.scss */
.swal2-container {
  z-index: 9999999 !important; }

/* line 4, app/styles/styles.scss */
.z-index-sec-modal {
  z-index: 999999 !important; }

/* line 7, app/styles/styles.scss */
.succ-green {
  background: #65f1c3 !important; }

/* line 11, app/styles/styles.scss */
.dang-red {
  background: #f3b4b4 !important; }

/* line 15, app/styles/styles.scss */
.c-blue {
  color: blue; }

/* line 18, app/styles/styles.scss */
.c-red {
  color: #d11414; }

/* line 22, app/styles/styles.scss */
.c-green {
  color: #22baa0; }

/* line 25, app/styles/styles.scss */
.bg-red {
  background: #d11414 !important; }

/* line 28, app/styles/styles.scss */
.bg-gris {
  background: #f5f4f4; }

/* line 31, app/styles/styles.scss */
.pointer {
  cursor: pointer; }

/* line 34, app/styles/styles.scss */
.kpi-refresh {
  color: #d11414;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer; }
  /* line 39, app/styles/styles.scss */
  .kpi-refresh:hover {
    color: #08db8a; }

/* line 44, app/styles/styles.scss */
.relative {
  position: relative; }

/**
 * switcher button checkbox
 */
/* line 52, app/styles/styles.scss */
.onoffswitch3 {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

/* line 60, app/styles/styles.scss */
.onoffswitch3-checkbox {
  display: none; }

/* line 64, app/styles/styles.scss */
.onoffswitch3-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0px solid #999999;
  border-radius: 0px; }

/* line 72, app/styles/styles.scss */
.onoffswitch3-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s; }

/* line 82, app/styles/styles.scss */
.onoffswitch3-inner > span {
  display: block;
  float: left;
  position: relative;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* line 99, app/styles/styles.scss */
.onoffswitch3-inner .onoffswitch3-active {
  padding-left: 10px;
  background-color: #eeeeee;
  color: #ffffff; }

/* line 105, app/styles/styles.scss */
.onoffswitch3-inner .onoffswitch3-inactive {
  padding-right: 10px;
  background-color: #eeeeee;
  color: #ffffff;
  text-align: right; }

/* line 112, app/styles/styles.scss */
.onoffswitch3-switch {
  display: block;
  width: 40px;
  margin: 0px;
  text-align: center;
  border: 0px solid #999999;
  border-radius: 0px;
  position: absolute;
  top: 0;
  bottom: 0; }

/* line 123, app/styles/styles.scss */
.onoffswitch3-active .onoffswitch3-switch {
  background: #06bb57;
  left: 0; }

/* line 127, app/styles/styles.scss */
.onoffswitch3-inactive .onoffswitch3-switch {
  background: #e43b3b;
  right: 0; }

/* line 132, app/styles/styles.scss */
.onoffswitch3-active .onoffswitch3-switch:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 40px;
  border-style: solid;
  border-color: #06bb57 transparent transparent #06bb57;
  border-width: 15px 9px; }

/* line 142, app/styles/styles.scss */
.onoffswitch3-inactive .onoffswitch3-switch:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 40px;
  border-style: solid;
  border-color: transparent #e43b3b #e43b3b transparent;
  border-width: 15px 9px; }

/* line 152, app/styles/styles.scss */
.onoffswitch3-checkbox:checked + .onoffswitch3-label .onoffswitch3-inner {
  margin-left: 0; }

/**
 * end switcher button checkbox
 */
/* line 159, app/styles/styles.scss */
.m-t-7 {
  margin-top: 7px; }

/* line 163, app/styles/styles.scss */
.absolute {
  position: absolute; }

/* line 166, app/styles/styles.scss */
.ftr-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/* line 172, app/styles/styles.scss */
.l-h-30 {
  line-height: 30px; }

/* line 175, app/styles/styles.scss */
i.check_ass {
  color: #41a735;
  font-size: 30px; }

/* line 180, app/styles/styles.scss */
div[uib-modal-window="modal-window"] {
  background: #000000ad; }

/* line 183, app/styles/styles.scss */
.swal2-shown {
  padding-right: 0px !important; }

/* line 187, app/styles/styles.scss */
.header-add-bl-credit {
  width: 90%;
  left: 5%;
  top: 5px;
  display: flex; }

/* line 193, app/styles/styles.scss */
.header-add-bl-credit > div {
  width: 32%;
  text-align: center;
  float: left;
  font-size: 15px; }

/* line 199, app/styles/styles.scss */
.header-add-bl-credit > div > span {
  font-size: 19px;
  margin-left: 10px;
  float: right; }

/* line 205, app/styles/styles.scss */
.bar-for-bl-credit {
  border-right: 2px solid;
  padding-right: 45px; }

/**
 *
 */
/* line 213, app/styles/styles.scss */
.plafont_credit_caion {
  width: 90%;
  left: 5%;
  top: 25px;
  display: flex; }

/* line 219, app/styles/styles.scss */
.plafont_credit_caion > div {
  width: 32%;
  text-align: center;
  float: left;
  font-size: 15px; }

/* line 225, app/styles/styles.scss */
.plafont_credit_caion > div > span {
  font-size: 19px;
  margin-left: 10px;
  float: right; }

/* line 231, app/styles/styles.scss */
.m-l-45 {
  margin-left: 45px; }

/* line 234, app/styles/styles.scss */
.p-t-11 {
  padding-top: 11px; }

/* line 238, app/styles/styles.scss */
.sticky_top0 {
  position: sticky;
  top: 0; }

/* line 243, app/styles/styles.scss */
.sticky_top29 {
  position: sticky;
  top: 29px; }

/* line 248, app/styles/styles.scss */
.antiquewhite {
  background: antiquewhite; }

/* line 251, app/styles/styles.scss */
.z-999 {
  z-index: 999; }

/* line 255, app/styles/styles.scss */
._720kb-datepicker-open {
  z-index: 99999 !important; }

/* line 259, app/styles/styles.scss */
.btn_global_filter {
  margin-right: 9.5%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; }

/* line 264, app/styles/styles.scss */
.global_filter {
  width: 75%;
  min-height: 200px;
  background: #fff;
  margin-left: 12.5%;
  border-right: 3px solid #337ab7;
  border-left: 3px solid #337ab7;
  border-bottom: 3px solid #337ab7;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 5px 8px 11px 0px rgba(0, 0, 0, 0.4); }

@keyframes transition2 {
  from {
    height: 0;
    width: 0;
    opacity: 0.5; }
  to {
    opacity: 1;
    width: auto;
    height: auto; } }

/* line 292, app/styles/styles.scss */
.bg_traite {
  background: #40e6bd; }

/* line 296, app/styles/styles.scss */
.bg_non_traite {
  background: #ffd5d3; }

/* line 300, app/styles/styles.scss */
.panel_gratuite {
  height: 300px;
  margin: 5px;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4); }

/* line 308, app/styles/styles.scss */
.img_prod_bl {
  width: 100%;
  height: 175px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px; }

/* line 315, app/styles/styles.scss */
.lg-95 > .modal-lg {
  width: 90% !important; }

/* line 318, app/styles/styles.scss */
.prod_grat_selected {
  border: 10px solid #32c137 !important; }

/* line 321, app/styles/styles.scss */
.prod_grat_selected_hors_stock {
  border: 10px solid #fcb2c2 !important; }

/* line 324, app/styles/styles.scss */
.hors_stock_gratuite {
  background: repeating-linear-gradient(45deg, #fbe3e4, #fbe3e4 20px, #ffced9 20px, #ffced9 40px) !important; }

/* line 328, app/styles/styles.scss */
.checked_prod {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 26px;
  background: #32c137; }

/* line 338, app/styles/styles.scss */
.checked_prod_hors_stock {
  background: #fcb2c2 !important; }

/* line 341, app/styles/styles.scss */
.checked_prod > i {
  font-size: 25px;
  color: #fff; }

/* line 346, app/styles/styles.scss */
.left_21 {
  left: 21%; }

/* line 349, app/styles/styles.scss */
.left_40 {
  left: 40%; }

/* line 353, app/styles/styles.scss */
.cart_add_gratuite {
  font-size: 31px;
  position: absolute;
  right: -2px;
  top: 0px;
  background: #4646453b;
  padding: 5px 17px 5px 17px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer; }

/* line 365, app/styles/styles.scss */
.cart_remove_gratuite {
  position: absolute;
  right: 0px;
  top: 0px;
  color: red !important;
  cursor: pointer; }

/* line 373, app/styles/styles.scss */
.transtext {
  height: 50px;
  overflow: hidden;
  position: relative; }

/* line 378, app/styles/styles.scss */
.transtext h4 {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: transtext 15s linear infinite;
  -webkit-animation: transtext 15s linear infinite;
  animation: transtext 15s linear infinite; }

/* Move it (define the animation) */
@-moz-keyframes transtext {
  0% {
    -moz-transform: translateX(100%); }
  100% {
    -moz-transform: translateX(-100%); } }

@-webkit-keyframes transtext {
  0% {
    -webkit-transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(-100%); } }

@keyframes transtext {
  0% {
    -moz-transform: translateX(100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(100%);
    /* Firefox bug fix */
    transform: translateX(100%); }
  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%); } }

/* line 1, app/styles/analyse.scss */
#menu-analyses > li {
  margin-left: 20px;
  margin-bottom: 20px; }

/* line 6, app/styles/analyse.scss */
#menu-analyses > li > a {
  width: 283px;
  height: 72px;
  padding: 0;
  border-radius: 14px !important;
  position: relative;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4); }

/* line 18, app/styles/analyse.scss */
#menu-analyses > li > a > div:first-child {
  /* width: 31px; */
  height: 70px;
  /* float: left; */
  position: absolute;
  top: 10px;
  left: 5px;
  font-size: 36px;
  color: #fff; }

/* line 29, app/styles/analyse.scss */
#menu-analyses > li > a > div:nth-child(2) {
  background-image: url(../images/bg-analyse-menu1.png);
  background-repeat: no-repeat;
  width: 241px;
  height: 70px;
  float: right;
  line-height: 70px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 13px; }
  /* line 38, app/styles/analyse.scss */
  #menu-analyses > li > a > div:nth-child(2) div {
    margin-left: 30px;
    font-size: 12px;
    color: #337ab7;
    font-weight: bold;
    width: 170px;
    float: left; }
  /* line 46, app/styles/analyse.scss */
  #menu-analyses > li > a > div:nth-child(2) i {
    font-size: 28px;
    line-height: 70px; }

/* line 52, app/styles/analyse.scss */
.analyse-goback {
  font-size: 36px;
  color: #22baa0;
  border: 5px solid;
  border-radius: 43px;
  width: 47px;
  padding-left: 2px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: -77px; }

/* line 1, app/styles/clients_lists.scss */
.block_list_cl {
  border: 1px solid #ddd;
  height: 150px;
  background-image: linear-gradient(white, #f5f1ed);
  box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
  margin-bottom: 12px; }

/* line 9, app/styles/clients_lists.scss */
.bloqued_client {
  color: white;
  background: repeating-linear-gradient(45deg, #fbe3e4, #fbe3e4 20px, #ffced9 20px, #ffced9 40px); }

/* line 14, app/styles/clients_lists.scss */
.btn_load_more {
  background: #ececec;
  padding: 10px 0px 10px 0px;
  font-size: 17px;
  border: 1px solid #ddd;
  box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-weight: 500; }

/* line 24, app/styles/clients_lists.scss */
.credit_positive {
  color: blue; }

/* line 27, app/styles/clients_lists.scss */
.credit_negative {
  color: red; }

/* line 30, app/styles/clients_lists.scss */
.m-t-0 {
  margin-top: 0px !important; }

/* line 34, app/styles/clients_lists.scss */
.m-b-0 {
  margin-bottom: 0px !important; }

/* line 38, app/styles/clients_lists.scss */
.container-info-cl {
  display: flex;
  justify-content: space-around; }

/* line 42, app/styles/clients_lists.scss */
.container-info-cl > div {
  width: 20%;
  text-align: center; }

/* line 5, app/styles/objectifs.scss */
.abj_annuel_table > thead > tr > th {
  border: 1px solid #000; }

/* line 9, app/styles/objectifs.scss */
.abj_annuel_table > tbody > tr > td {
  padding: 0px !important;
  border: 1px solid blue;
  margin: 0px !important; }

/* line 15, app/styles/objectifs.scss */
.abj_annuel_table > tbody > tr > td.inputs {
  width: 65px !important;
  padding: 2px !important;
  position: relative; }

/* line 21, app/styles/objectifs.scss */
.abj_annuel_table > tbody > tr > td > div > input {
  /* width: 100% !important;
                                                            padding: 0px;
                                                            margin: 0px;
                                                            background: #fff;
                                                            border: none;
                                                            height: 25px;
                                                            text-align: center; */
  width: 100% !important;
  padding: 0px;
  margin: 0px;
  background: transparent;
  border: none;
  height: 11px;
  float: left; }

/* line 38, app/styles/objectifs.scss */
.active_td,
.active_td > div > input {
  background: #2296ba29 !important; }

/* line 43, app/styles/objectifs.scss */
.red_line {
  background: #ee646499 !important; }

/* line 47, app/styles/objectifs.scss */
.absolute {
  position: absolute; }

/* line 51, app/styles/objectifs.scss */
.arrow {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 0px; }

/* line 58, app/styles/objectifs.scss */
.arrowdown {
  color: red; }

/* line 62, app/styles/objectifs.scss */
.arrowup {
  color: green; }

/* line 66, app/styles/objectifs.scss */
.mar_15 {
  margin-top: 15px; }

/* line 70, app/styles/objectifs.scss */
.input_coef {
  border: none;
  width: 60px;
  min-height: 34px !important;
  background: #dceee6;
  padding: 10px; }

/* line 83, app/styles/objectifs.scss */
.label-checks {
  position: relative;
  cursor: pointer;
  color: #666;
  font-size: 14px; }

/* line 90, app/styles/objectifs.scss */
input[type="checkbox"].check_objs {
  position: absolute;
  right: 9000px; }

/*Check box*/
/* line 96, app/styles/objectifs.scss */
input[type="checkbox"] + .label-text:before {
  content: "\f0c8";
  font-family: "FontAwesome";
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 5px;
  color: #c4c4c4; }

/* line 113, app/styles/objectifs.scss */
input[type="checkbox"]:checked + .label-text:before {
  content: "\f14a";
  color: #2980b9;
  animation: effect 250ms ease-in;
  font-weight: 900; }

@keyframes effect {
  0% {
    transform: scale(0); }
  25% {
    transform: scale(1.3); }
  75% {
    transform: scale(1.4); }
  100% {
    transform: scale(1); } }

/* line 131, app/styles/objectifs.scss */
.flex_checkers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  /* line 135, app/styles/objectifs.scss */
  .flex_checkers div {
    width: 50%; }

/* line 30, app/styles/main.scss */
.row {
  margin-right: -15px;
  margin-left: -15px; }

/* line 35, app/styles/main.scss */
.puces {
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

/* line 41, app/styles/main.scss */
.puce {
  margin-top: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block; }

/* line 49, app/styles/main.scss */
.carre {
  height: 15px;
  width: 15px;
  border-radius: 4px;
  margin-bottom: 2px; }

/* line 56, app/styles/main.scss */
.text-pie {
  margin-left: -6px;
  font-size: 9px;
  float: left;
  padding-right: 6px; }

/* line 63, app/styles/main.scss */
.ct-series .ct-slice-donut {
  stroke-width: 40px !important; }

/* line 67, app/styles/main.scss */
.table-chart {
  position: absolute !important;
  top: 50%;
  left: 36%; }
  /* line 71, app/styles/main.scss */
  .table-chart td {
    text-align: center; }

/* line 77, app/styles/main.scss */
.ct-series-a .ct-slice-donut {
  stroke: #2c844a !important; }

/* line 83, app/styles/main.scss */
.ct-series-b .ct-slice-donut {
  stroke: #68bbf3 !important; }

/* line 89, app/styles/main.scss */
.ct-series-c .ct-slice-donut {
  stroke: #e6922d !important; }

/* line 95, app/styles/main.scss */
.ct-series-d .ct-slice-donut {
  stroke: #d17905 !important; }

/* line 101, app/styles/main.scss */
.ct-series-e .ct-slice-donut {
  stroke: #453d3f !important; }

/* line 106, app/styles/main.scss */
.color-0 {
  background: #2c844a; }

/* line 110, app/styles/main.scss */
.color-1 {
  background: #68bbf3; }

/* line 114, app/styles/main.scss */
.color-2 {
  background: #e6922d; }

/* line 118, app/styles/main.scss */
.color-3 {
  background: #d17905; }

/* line 122, app/styles/main.scss */
.color-4 {
  background: #453d3f; }

/* line 126, app/styles/main.scss */
.color-5 {
  background: #59922b; }

/* line 130, app/styles/main.scss */
.color-6 {
  background: #0544d3; }

/* line 134, app/styles/main.scss */
.color-7 {
  background: #6b0392; }

/* line 138, app/styles/main.scss */
.color-8 {
  background: #f05b4f; }

/* line 142, app/styles/main.scss */
.color-9 {
  background: #dda458; }

/* line 146, app/styles/main.scss */
.color-10 {
  background: #eacf7d; }

/* line 150, app/styles/main.scss */
.color-11 {
  background: #86797d; }

/* line 154, app/styles/main.scss */
.color-12 {
  background: #b2c326; }

/* line 158, app/styles/main.scss */
.color-13 {
  background: #6188e2; }

/* line 162, app/styles/main.scss */
.color-14 {
  background: #a748ca; }

/* line 166, app/styles/main.scss */
.bg-yellow {
  background: yellow; }

/* line 170, app/styles/main.scss */
.bg-blue {
  background: blue; }

/* line 175, app/styles/main.scss */
.rootings td {
  border: 1px dotted #ccc; }

/* line 182, app/styles/main.scss */
.rootings .table_routings tbody tr > td,
.rootings .table_routings tbody tr > th,
.rootings .table_routings tfoot tr > td,
.rootings .table_routings tfoot tr > th {
  padding: 0 !important; }
  /* line 185, app/styles/main.scss */
  .rootings .table_routings tbody tr > td input,
  .rootings .table_routings tbody tr > th input,
  .rootings .table_routings tfoot tr > td input,
  .rootings .table_routings tfoot tr > th input {
    margin-left: auto;
    margin-right: auto;
    width: 12px; }

/* line 194, app/styles/main.scss */
.rootings input[type=checkbox],
.rootings input[type=radio] {
  margin-top: 2px;
  height: 13px; }

/* line 201, app/styles/main.scss */
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-right: 4px;
  padding-left: 4px; }

/* line 253, app/styles/main.scss */
.panel {
  margin-bottom: 7px;
  border-radius: 8px !important; }
  /* line 256, app/styles/main.scss */
  .panel .panel-heading {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }

/* line 262, app/styles/main.scss */
.panel-info {
  background: #12afcb; }
  /* line 264, app/styles/main.scss */
  .panel-info .info-box-title {
    color: white !important; }
  /* line 267, app/styles/main.scss */
  .panel-info .info-box-stats p {
    color: white;
    font-weight: bold; }
  /* line 271, app/styles/main.scss */
  .panel-info i {
    color: black !important; }

/* line 276, app/styles/main.scss */
.panel-success {
  background: #1fe27b;
  color: white; }

/* line 281, app/styles/main.scss */
.panel-danger {
  background: #e21f3f;
  color: white; }

/* line 286, app/styles/main.scss */
.table-analyses {
  width: 100%; }
  /* line 289, app/styles/main.scss */
  .table-analyses thead th {
    text-align: center;
    border: 1px solid white; }
  /* line 294, app/styles/main.scss */
  .table-analyses tr {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 3px;
    position: relative; }
    /* line 300, app/styles/main.scss */
    .table-analyses tr td {
      background: #f1f4f9;
      padding: 0 5px;
      border: 2px solid white;
      border-radius: 4px;
      text-align: center; }
      /* line 306, app/styles/main.scss */
      .table-analyses tr td:first-child {
        font-weight: bold;
        text-align: left; }
  /* line 317, app/styles/main.scss */
  .table-analyses.table-kpi thead tr:first-child th:nth-child(2), .table-analyses.table-kpi thead tr:first-child th:nth-child(3) {
    background: #f1f4f9; }
  /* line 321, app/styles/main.scss */
  .table-analyses.table-kpi thead tr:first-child th:nth-child(3) {
    color: white;
    background: #b1b6bd; }
  /* line 328, app/styles/main.scss */
  .table-analyses.table-kpi thead th:nth-child(2), .table-analyses.table-kpi thead th:nth-child(3), .table-analyses.table-kpi thead th:nth-child(4), .table-analyses.table-kpi thead th:nth-child(5), .table-analyses.table-kpi thead th:nth-child(6), .table-analyses.table-kpi thead th:nth-child(7), .table-analyses.table-kpi thead th:nth-child(8), .table-analyses.table-kpi thead th:nth-child(9), .table-analyses.table-kpi thead th:nth-child(18), .table-analyses.table-kpi thead th:nth-child(19), .table-analyses.table-kpi thead th:nth-child(20), .table-analyses.table-kpi thead th:nth-child(21), .table-analyses.table-kpi thead th:nth-child(22), .table-analyses.table-kpi thead th:nth-child(23) {
    background: #f1f4f9; }
  /* line 345, app/styles/main.scss */
  .table-analyses.table-kpi thead th:nth-child(10), .table-analyses.table-kpi thead th:nth-child(11), .table-analyses.table-kpi thead th:nth-child(12), .table-analyses.table-kpi thead th:nth-child(13), .table-analyses.table-kpi thead th:nth-child(14), .table-analyses.table-kpi thead th:nth-child(15), .table-analyses.table-kpi thead th:nth-child(16), .table-analyses.table-kpi thead th:nth-child(17) {
    color: white !important;
    background: #b1b6bd !important; }
  /* line 360, app/styles/main.scss */
  .table-analyses.table-kpi tr td:nth-child(10), .table-analyses.table-kpi tr td:nth-child(11), .table-analyses.table-kpi tr td:nth-child(12), .table-analyses.table-kpi tr td:nth-child(13), .table-analyses.table-kpi tr td:nth-child(14), .table-analyses.table-kpi tr td:nth-child(15), .table-analyses.table-kpi tr td:nth-child(16), .table-analyses.table-kpi tr td:nth-child(17) {
    color: white;
    background: #b1b6bd; }
  /* line 380, app/styles/main.scss */
  .table-analyses.table-kpi-analyse-4 thead tr:first-child th:nth-child(2), .table-analyses.table-kpi-analyse-4 thead tr:first-child th:nth-child(3) {
    background: #f1f4f9; }
  /* line 384, app/styles/main.scss */
  .table-analyses.table-kpi-analyse-4 thead tr:first-child th:nth-child(3) {
    color: white;
    background: #b1b6bd; }
  /* line 391, app/styles/main.scss */
  .table-analyses.table-kpi-analyse-4 thead th:nth-child(2), .table-analyses.table-kpi-analyse-4 thead th:nth-child(3), .table-analyses.table-kpi-analyse-4 thead th:nth-child(4), .table-analyses.table-kpi-analyse-4 thead th:nth-child(5), .table-analyses.table-kpi-analyse-4 thead th:nth-child(6), .table-analyses.table-kpi-analyse-4 thead th:nth-child(7), .table-analyses.table-kpi-analyse-4 thead th:nth-child(8), .table-analyses.table-kpi-analyse-4 thead th:nth-child(9), .table-analyses.table-kpi-analyse-4 thead th:nth-child(15), .table-analyses.table-kpi-analyse-4 thead th:nth-child(16), .table-analyses.table-kpi-analyse-4 thead th:nth-child(17), .table-analyses.table-kpi-analyse-4 thead th:nth-child(18), .table-analyses.table-kpi-analyse-4 thead th:nth-child(19), .table-analyses.table-kpi-analyse-4 thead th:nth-child(20) {
    background: #f1f4f9; }
  /* line 411, app/styles/main.scss */
  .table-analyses.table-kpi-analyse-4 thead th:nth-child(10), .table-analyses.table-kpi-analyse-4 thead th:nth-child(11), .table-analyses.table-kpi-analyse-4 thead th:nth-child(12), .table-analyses.table-kpi-analyse-4 thead th:nth-child(13), .table-analyses.table-kpi-analyse-4 thead th:nth-child(14) {
    color: white !important;
    background: #b1b6bd !important; }
  /* line 423, app/styles/main.scss */
  .table-analyses.table-kpi-analyse-4 tr td:nth-child(10), .table-analyses.table-kpi-analyse-4 tr td:nth-child(11), .table-analyses.table-kpi-analyse-4 tr td:nth-child(12), .table-analyses.table-kpi-analyse-4 tr td:nth-child(13), .table-analyses.table-kpi-analyse-4 tr td:nth-child(14) {
    color: white;
    background: #b1b6bd; }
  /* line 443, app/styles/main.scss */
  .table-analyses.table-kpi-commande thead tr:first-child th:nth-child(2), .table-analyses.table-kpi-commande thead tr:first-child th:nth-child(3) {
    background: #f1f4f9; }
  /* line 447, app/styles/main.scss */
  .table-analyses.table-kpi-commande thead tr:first-child th:nth-child(3) {
    color: white;
    background: #b1b6bd; }
  /* line 454, app/styles/main.scss */
  .table-analyses.table-kpi-commande thead th:nth-child(2), .table-analyses.table-kpi-commande thead th:nth-child(3), .table-analyses.table-kpi-commande thead th:nth-child(4), .table-analyses.table-kpi-commande thead th:nth-child(5), .table-analyses.table-kpi-commande thead th:nth-child(6), .table-analyses.table-kpi-commande thead th:nth-child(7), .table-analyses.table-kpi-commande thead th:nth-child(8), .table-analyses.table-kpi-commande thead th:nth-child(9), .table-analyses.table-kpi-commande thead th:nth-child(10), .table-analyses.table-kpi-commande thead th:nth-child(18), .table-analyses.table-kpi-commande thead th:nth-child(19), .table-analyses.table-kpi-commande thead th:nth-child(20), .table-analyses.table-kpi-commande thead th:nth-child(21), .table-analyses.table-kpi-commande thead th:nth-child(22), .table-analyses.table-kpi-commande thead th:nth-child(23), .table-analyses.table-kpi-commande thead th:nth-child(24), .table-analyses.table-kpi-commande thead th:nth-child(25) {
    background: #f1f4f9; }
  /* line 473, app/styles/main.scss */
  .table-analyses.table-kpi-commande thead th:nth-child(11), .table-analyses.table-kpi-commande thead th:nth-child(12), .table-analyses.table-kpi-commande thead th:nth-child(13), .table-analyses.table-kpi-commande thead th:nth-child(14), .table-analyses.table-kpi-commande thead th:nth-child(15), .table-analyses.table-kpi-commande thead th:nth-child(16), .table-analyses.table-kpi-commande thead th:nth-child(17) {
    color: white;
    background: #b1b6bd; }
  /* line 487, app/styles/main.scss */
  .table-analyses.table-kpi-commande tr td:nth-child(11), .table-analyses.table-kpi-commande tr td:nth-child(12), .table-analyses.table-kpi-commande tr td:nth-child(13), .table-analyses.table-kpi-commande tr td:nth-child(14), .table-analyses.table-kpi-commande tr td:nth-child(15), .table-analyses.table-kpi-commande tr td:nth-child(16), .table-analyses.table-kpi-commande tr td:nth-child(17) {
    color: white;
    background: #b1b6bd; }
  /* line 506, app/styles/main.scss */
  .table-analyses.table-kpi-commande-rapport thead tr:first-child th:nth-child(2), .table-analyses.table-kpi-commande-rapport thead tr:first-child th:nth-child(3) {
    background: #f1f4f9; }
  /* line 510, app/styles/main.scss */
  .table-analyses.table-kpi-commande-rapport thead tr:first-child th:nth-child(3) {
    color: white;
    background: #b1b6bd; }
  /* line 517, app/styles/main.scss */
  .table-analyses.table-kpi-commande-rapport thead th:nth-child(2), .table-analyses.table-kpi-commande-rapport thead th:nth-child(3), .table-analyses.table-kpi-commande-rapport thead th:nth-child(4), .table-analyses.table-kpi-commande-rapport thead th:nth-child(5), .table-analyses.table-kpi-commande-rapport thead th:nth-child(6), .table-analyses.table-kpi-commande-rapport thead th:nth-child(7), .table-analyses.table-kpi-commande-rapport thead th:nth-child(8), .table-analyses.table-kpi-commande-rapport thead th:nth-child(15), .table-analyses.table-kpi-commande-rapport thead th:nth-child(16), .table-analyses.table-kpi-commande-rapport thead th:nth-child(17), .table-analyses.table-kpi-commande-rapport thead th:nth-child(18), .table-analyses.table-kpi-commande-rapport thead th:nth-child(19), .table-analyses.table-kpi-commande-rapport thead th:nth-child(20), .table-analyses.table-kpi-commande-rapport thead th:nth-child(21), .table-analyses.table-kpi-commande-rapport thead th:nth-child(22), .table-analyses.table-kpi-commande-rapport thead th:nth-child(23), .table-analyses.table-kpi-commande-rapport thead th:nth-child(24), .table-analyses.table-kpi-commande-rapport thead th:nth-child(25) {
    background: #f1f4f9; }
  /* line 538, app/styles/main.scss */
  .table-analyses.table-kpi-commande-rapport thead th:nth-child(9), .table-analyses.table-kpi-commande-rapport thead th:nth-child(10), .table-analyses.table-kpi-commande-rapport thead th:nth-child(11), .table-analyses.table-kpi-commande-rapport thead th:nth-child(12), .table-analyses.table-kpi-commande-rapport thead th:nth-child(13), .table-analyses.table-kpi-commande-rapport thead th:nth-child(14) {
    color: white;
    background: #b1b6bd; }
  /* line 552, app/styles/main.scss */
  .table-analyses.table-kpi-commande-rapport tr td:nth-child(9), .table-analyses.table-kpi-commande-rapport tr td:nth-child(10), .table-analyses.table-kpi-commande-rapport tr td:nth-child(11), .table-analyses.table-kpi-commande-rapport tr td:nth-child(12), .table-analyses.table-kpi-commande-rapport tr td:nth-child(13), .table-analyses.table-kpi-commande-rapport tr td:nth-child(14) {
    color: white;
    background: #b1b6bd; }
  /* line 570, app/styles/main.scss */
  .table-analyses.table-kpi-gouvernorate thead tr:first-child th:nth-child(2), .table-analyses.table-kpi-gouvernorate thead tr:first-child th:nth-child(4) {
    background: #f1f4f9; }
  /* line 574, app/styles/main.scss */
  .table-analyses.table-kpi-gouvernorate thead tr:first-child th:nth-child(3) {
    color: white;
    background: #b1b6bd; }
  /* line 581, app/styles/main.scss */
  .table-analyses.table-kpi-gouvernorate thead th:nth-child(2), .table-analyses.table-kpi-gouvernorate thead th:nth-child(3), .table-analyses.table-kpi-gouvernorate thead th:nth-child(4), .table-analyses.table-kpi-gouvernorate thead th:nth-child(5), .table-analyses.table-kpi-gouvernorate thead th:nth-child(6), .table-analyses.table-kpi-gouvernorate thead th:nth-child(12), .table-analyses.table-kpi-gouvernorate thead th:nth-child(13), .table-analyses.table-kpi-gouvernorate thead th:nth-child(14), .table-analyses.table-kpi-gouvernorate thead th:nth-child(15), .table-analyses.table-kpi-gouvernorate thead th:nth-child(16) {
    background: #f1f4f9; }
  /* line 593, app/styles/main.scss */
  .table-analyses.table-kpi-gouvernorate thead th:nth-child(5), .table-analyses.table-kpi-gouvernorate thead th:nth-child(6), .table-analyses.table-kpi-gouvernorate thead th:nth-child(7), .table-analyses.table-kpi-gouvernorate thead th:nth-child(8), .table-analyses.table-kpi-gouvernorate thead th:nth-child(9), .table-analyses.table-kpi-gouvernorate thead th:nth-child(10), .table-analyses.table-kpi-gouvernorate thead th:nth-child(11) {
    color: white;
    background: #b1b6bd; }
  /* line 607, app/styles/main.scss */
  .table-analyses.table-kpi-gouvernorate tr td:nth-child(5), .table-analyses.table-kpi-gouvernorate tr td:nth-child(6), .table-analyses.table-kpi-gouvernorate tr td:nth-child(7), .table-analyses.table-kpi-gouvernorate tr td:nth-child(8), .table-analyses.table-kpi-gouvernorate tr td:nth-child(9), .table-analyses.table-kpi-gouvernorate tr td:nth-child(10), .table-analyses.table-kpi-gouvernorate tr td:nth-child(11) {
    color: white;
    background: #b1b6bd; }
  /* line 625, app/styles/main.scss */
  .table-analyses.table-kpi-total-visite thead tr:first-child th:nth-child(2), .table-analyses.table-kpi-total-visite thead tr:first-child th:nth-child(4) {
    background: #f1f4f9; }
  /* line 629, app/styles/main.scss */
  .table-analyses.table-kpi-total-visite thead tr:first-child th:nth-child(3) {
    background: #f1f4f9; }
  /* line 635, app/styles/main.scss */
  .table-analyses.table-kpi-total-visite thead th:nth-child(2), .table-analyses.table-kpi-total-visite thead th:nth-child(3), .table-analyses.table-kpi-total-visite thead th:nth-child(4), .table-analyses.table-kpi-total-visite thead th:nth-child(5), .table-analyses.table-kpi-total-visite thead th:nth-child(6), .table-analyses.table-kpi-total-visite thead th:nth-child(12), .table-analyses.table-kpi-total-visite thead th:nth-child(13), .table-analyses.table-kpi-total-visite thead th:nth-child(14), .table-analyses.table-kpi-total-visite thead th:nth-child(15), .table-analyses.table-kpi-total-visite thead th:nth-child(16) {
    background: #f1f4f9; }
  /* line 647, app/styles/main.scss */
  .table-analyses.table-kpi-total-visite thead th:nth-child(5), .table-analyses.table-kpi-total-visite thead th:nth-child(6), .table-analyses.table-kpi-total-visite thead th:nth-child(7), .table-analyses.table-kpi-total-visite thead th:nth-child(8), .table-analyses.table-kpi-total-visite thead th:nth-child(9), .table-analyses.table-kpi-total-visite thead th:nth-child(10), .table-analyses.table-kpi-total-visite thead th:nth-child(11) {
    background: #f1f4f9; }
  /* line 660, app/styles/main.scss */
  .table-analyses.table-kpi-total-visite tr td:nth-child(5), .table-analyses.table-kpi-total-visite tr td:nth-child(6), .table-analyses.table-kpi-total-visite tr td:nth-child(7), .table-analyses.table-kpi-total-visite tr td:nth-child(8), .table-analyses.table-kpi-total-visite tr td:nth-child(9), .table-analyses.table-kpi-total-visite tr td:nth-child(10), .table-analyses.table-kpi-total-visite tr td:nth-child(11) {
    background: #f1f4f9; }

/* line 674, app/styles/main.scss */
.ct-chart-line .ct-horizontal {
  display: none; }
  /* line 676, app/styles/main.scss */
  .ct-chart-line .ct-horizontal.ct-end {
    display: block; }

/* line 681, app/styles/main.scss */
.ct-chart-line .ct-line {
  stroke-width: 1px; }

/* line 685, app/styles/main.scss */
.ct-chart-line .ct-point {
  stroke-width: 5px; }

/* line 689, app/styles/main.scss */
.pos-bottom-right {
  position: absolute;
  bottom: 0;
  right: 10px; }

/* line 695, app/styles/main.scss */
.pos-bottom-left {
  position: absolute;
  bottom: 0;
  left: 10px; }

/* line 701, app/styles/main.scss */
.text-xs {
  font-size: 11px !important; }

/* line 705, app/styles/main.scss */
.navbar {
  border: none !important;
  border-radius: 0 !important; }

/* line 710, app/styles/main.scss */
.no-padder {
  padding: 0 !important; }

/* line 714, app/styles/main.scss */
.ct-chart .ct-label {
  font-size: 1.2rem;
  fill: white; }

/* line 719, app/styles/main.scss */
.jvectormap-container {
  height: 400px !important;
  background: #a3ccff !important; }

/* line 725, app/styles/main.scss */
.dashboard .jvectormap-container {
  height: 800px !important;
  background: #f1f4f9 !important; }
  /* line 728, app/styles/main.scss */
  .dashboard .jvectormap-container path {
    stroke-width: 1px;
    stroke: #2c363e; }

/* line 735, app/styles/main.scss */
.box-client {
  position: absolute;
  z-index: 99;
  padding: 40px 15px 15px 15px;
  background: rgba(255, 255, 255, 0.9);
  color: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0px;
  overflow: scroll; }
  /* line 746, app/styles/main.scss */
  .box-client .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background: red;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: 1.5em;
    color: white;
    cursor: pointer; }

/* line 763, app/styles/main.scss */
.jqvmap-label {
  position: absolute;
  display: none;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
  pointer-events: none; }

/* line 775, app/styles/main.scss */
.jqvmap-pin {
  pointer-events: none; }

/* line 779, app/styles/main.scss */
.jvectormap-zoomin,
.jvectormap-zoomout {
  display: none; }

/* line 784, app/styles/main.scss */
.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 10px;
  height: 10px;
  cursor: pointer;
  line-height: 10px;
  text-align: center; }

/* line 799, app/styles/main.scss */
.jqvmap-zoomin {
  top: 10px; }

/* line 803, app/styles/main.scss */
.jqvmap-zoomout {
  top: 30px; }

/* line 807, app/styles/main.scss */
.jqvmap-region {
  cursor: pointer; }

/* line 811, app/styles/main.scss */
.jqvmap-ajax_response {
  width: 100%;
  height: 500px; }

/* line 816, app/styles/main.scss */
.slimscroll {
  overflow: scroll; }

/*::-webkit-scrollbar {
    width: 2px;
}*/
/* line 825, app/styles/main.scss */
.m {
  margin: 10px; }

/* line 829, app/styles/main.scss */
.w-md {
  width: 130px; }

/* line 833, app/styles/main.scss */
.page-inner {
  min-height: 100vh; }

/* line 838, app/styles/main.scss */
.sidebar-profile-image img {
  background: white; }

/* line 843, app/styles/main.scss */
.m-t-n-md {
  margin-top: -8px; }

/* line 847, app/styles/main.scss */
.m-b-md {
  margin-bottom: 15px; }

/* line 851, app/styles/main.scss */
.modal {
  text-align: center;
  padding: 0 !important; }

/* line 856, app/styles/main.scss */
.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; }

/* line 864, app/styles/main.scss */
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

/* line 870, app/styles/main.scss */
.modal-backdrop {
  z-index: 0 !important; }

/* line 876, app/styles/main.scss */
.pin .pin {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  background: #12afcb;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px; }
  /* line 886, app/styles/main.scss */
  .pin .pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 6px 0 0 5px;
    background: #343f47;
    position: absolute;
    border-radius: 50%; }

/* line 896, app/styles/main.scss */
.pin.selected {
  background: #4caf50; }

/* line 899, app/styles/main.scss */
.pin.active {
  background: red; }

/* line 906, app/styles/main.scss */
.pinred .pinred {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  background: #d23429;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px; }
  /* line 916, app/styles/main.scss */
  .pinred .pinred:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 6px 0 0 5px;
    background: #343f47;
    position: absolute;
    border-radius: 50%; }

/* line 926, app/styles/main.scss */
.pinred.selected {
  background: #4caf50; }

/* line 929, app/styles/main.scss */
.pinred.active {
  background: red; }

/* line 935, app/styles/main.scss */
tr.selected {
  background: #4caf50 !important;
  color: white; }

/* line 940, app/styles/main.scss */
.isactif {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #21cc21;
  margin-left: 19px; }

/* line 948, app/styles/main.scss */
.notactif {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #b82017;
  margin-left: 19px; }

/* line 957, app/styles/main.scss */
.markerRed {
  background-color: #f5b2b9 !important; }

/* line 961, app/styles/main.scss */
.markerGreen {
  background-color: #baf7e3 !important; }

/* line 965, app/styles/main.scss */
.animbounce {
  background: #35844a !important;
  background-color: #35844a !important;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s; }

@-moz-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg); }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg); }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg); } }

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg); }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg); }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg); } }

@-o-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg); }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg); }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg); } }

@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg); }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg); }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg); } }

/**
 * code mirror
 */
/* BASICS */
/* line 1130, app/styles/main.scss */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
  font-size: 15px; }

/* PADDING */
/* line 1141, app/styles/main.scss */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */ }

/* line 1144, app/styles/main.scss */
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
  /* Horizontal padding of content */ }

/* line 1149, app/styles/main.scss */
.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */ }

/* GUTTER */
/* line 1155, app/styles/main.scss */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap; }

/* line 1161, app/styles/main.scss */
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap; }

/* line 1169, app/styles/main.scss */
.CodeMirror-guttermarker {
  color: black; }

/* line 1170, app/styles/main.scss */
.CodeMirror-guttermarker-subtle {
  color: #999; }

/* CURSOR */
/* line 1174, app/styles/main.scss */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0; }

/* Shown when moving in bi-directional text */
/* line 1180, app/styles/main.scss */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver; }

/* line 1183, app/styles/main.scss */
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7; }

/* line 1188, app/styles/main.scss */
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1; }

/* line 1191, app/styles/main.scss */
.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite; }

/* line 1197, app/styles/main.scss */
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7; }

@-moz-keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }

@-webkit-keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }

@keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }

/* Can style cursor different in overwrite (non-insert) mode */
/* line 1224, app/styles/main.scss */
.cm-tab {
  display: inline-block;
  text-decoration: inherit; }

/* line 1226, app/styles/main.scss */
.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden; }

/* line 1231, app/styles/main.scss */
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute; }

/* DEFAULT THEME */
/* line 1239, app/styles/main.scss */
.cm-s-default .cm-header {
  color: blue; }

/* line 1240, app/styles/main.scss */
.cm-s-default .cm-quote {
  color: #090; }

/* line 1241, app/styles/main.scss */
.cm-negative {
  color: #d44; }

/* line 1242, app/styles/main.scss */
.cm-positive {
  color: #292; }

/* line 1243, app/styles/main.scss */
.cm-header, .cm-strong {
  font-weight: bold; }

/* line 1244, app/styles/main.scss */
.cm-em {
  font-style: italic; }

/* line 1245, app/styles/main.scss */
.cm-link {
  text-decoration: underline; }

/* line 1246, app/styles/main.scss */
.cm-strikethrough {
  text-decoration: line-through; }

/* line 1248, app/styles/main.scss */
.cm-s-default .cm-keyword {
  color: #708; }

/* line 1249, app/styles/main.scss */
.cm-s-default .cm-atom {
  color: #219; }

/* line 1250, app/styles/main.scss */
.cm-s-default .cm-number {
  color: #164; }

/* line 1251, app/styles/main.scss */
.cm-s-default .cm-def {
  color: #00f; }

/* line 1256, app/styles/main.scss */
.cm-s-default .cm-variable-2 {
  color: #05a; }

/* line 1257, app/styles/main.scss */
.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085; }

/* line 1258, app/styles/main.scss */
.cm-s-default .cm-comment {
  color: #a50; }

/* line 1259, app/styles/main.scss */
.cm-s-default .cm-string {
  color: #a11; }

/* line 1260, app/styles/main.scss */
.cm-s-default .cm-string-2 {
  color: #f50; }

/* line 1261, app/styles/main.scss */
.cm-s-default .cm-meta {
  color: #555; }

/* line 1262, app/styles/main.scss */
.cm-s-default .cm-qualifier {
  color: #555; }

/* line 1263, app/styles/main.scss */
.cm-s-default .cm-builtin {
  color: #30a; }

/* line 1264, app/styles/main.scss */
.cm-s-default .cm-bracket {
  color: #997; }

/* line 1265, app/styles/main.scss */
.cm-s-default .cm-tag {
  color: #170; }

/* line 1266, app/styles/main.scss */
.cm-s-default .cm-attribute {
  color: #00c; }

/* line 1267, app/styles/main.scss */
.cm-s-default .cm-hr {
  color: #999; }

/* line 1268, app/styles/main.scss */
.cm-s-default .cm-link {
  color: #00c; }

/* line 1270, app/styles/main.scss */
.cm-s-default .cm-error {
  color: #f00; }

/* line 1271, app/styles/main.scss */
.cm-invalidchar {
  color: #f00; }

/* line 1273, app/styles/main.scss */
.CodeMirror-composing {
  border-bottom: 2px solid; }

/* Default styles for common addons */
/* line 1277, app/styles/main.scss */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0; }

/* line 1278, app/styles/main.scss */
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22; }

/* line 1279, app/styles/main.scss */
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3); }

/* line 1280, app/styles/main.scss */
.CodeMirror-activeline-background {
  background: #e8f2ff; }

/* STOP */
/* The rest of this file contains styles related to the mechanics of
     the editor. You probably shouldn't touch them. */
/* line 1287, app/styles/main.scss */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white; }

/* line 1293, app/styles/main.scss */
.CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative; }

/* line 1303, app/styles/main.scss */
.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent; }

/* The fake, visible scrollbars. Used to force redraw during scrolling
     before actual scrolling happens, thus preventing shaking and
     flickering artifacts. */
/* line 1311, app/styles/main.scss */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none; }

/* line 1316, app/styles/main.scss */
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll; }

/* line 1321, app/styles/main.scss */
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll; }

/* line 1326, app/styles/main.scss */
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0; }

/* line 1329, app/styles/main.scss */
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0; }

/* line 1333, app/styles/main.scss */
.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3; }

/* line 1338, app/styles/main.scss */
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px; }

/* line 1345, app/styles/main.scss */
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important; }

/* line 1351, app/styles/main.scss */
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4; }

/* line 1356, app/styles/main.scss */
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4; }

/* line 1361, app/styles/main.scss */
.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent; }

/* line 1362, app/styles/main.scss */
.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent; }

/* line 1364, app/styles/main.scss */
.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */ }

/* line 1368, app/styles/main.scss */
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual; }

/* line 1388, app/styles/main.scss */
.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal; }

/* line 1395, app/styles/main.scss */
.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

/* line 1401, app/styles/main.scss */
.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px;
  /* Force widget margins to stay inside of the container */ }

/* line 1409, app/styles/main.scss */
.CodeMirror-rtl pre {
  direction: rtl; }

/* line 1411, app/styles/main.scss */
.CodeMirror-code {
  outline: none; }

/* Force content-box sizing for the elements where we expect it */
/* line 1416, app/styles/main.scss */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

/* line 1425, app/styles/main.scss */
.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

/* line 1433, app/styles/main.scss */
.CodeMirror-cursor {
  position: absolute;
  pointer-events: none; }

/* line 1437, app/styles/main.scss */
.CodeMirror-measure pre {
  position: static; }

/* line 1439, app/styles/main.scss */
div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3; }

/* line 1444, app/styles/main.scss */
div.CodeMirror-dragcursors {
  visibility: visible; }

/* line 1448, app/styles/main.scss */
.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible; }

/* line 1452, app/styles/main.scss */
.CodeMirror-selected {
  background: #d9d9d9; }

/* line 1453, app/styles/main.scss */
.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0; }

/* line 1454, app/styles/main.scss */
.CodeMirror-crosshair {
  cursor: crosshair; }

/* line 1455, app/styles/main.scss */
.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0; }

/* line 1456, app/styles/main.scss */
.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0; }

/* line 1458, app/styles/main.scss */
.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4); }

/* Used to force a border model for a node */
/* line 1464, app/styles/main.scss */
.cm-force-border {
  padding-right: .1px; }

@media print {
  /* Hide the cursor when printing */
  /* line 1468, app/styles/main.scss */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden; } }

/* See issue #2901 */
/* line 1474, app/styles/main.scss */
.cm-tab-wrap-hack:after {
  content: ''; }

/* Help users use markselection to safely style text background */
/* line 1477, app/styles/main.scss */
span.CodeMirror-selectedtext {
  background: none; }

/**
  *
  */
/* line 1488, app/styles/main.scss */
.cm-s-twilight.CodeMirror {
  background: #141414;
  color: #f7f7f7; }

/**/
/* line 1489, app/styles/main.scss */
.cm-s-twilight div.CodeMirror-selected {
  background: #323232; }

/**/
/* line 1490, app/styles/main.scss */
.cm-s-twilight .CodeMirror-line::selection, .cm-s-twilight .CodeMirror-line > span::selection, .cm-s-twilight .CodeMirror-line > span > span::selection {
  background: rgba(50, 50, 50, 0.99); }

/* line 1491, app/styles/main.scss */
.cm-s-twilight .CodeMirror-line::-moz-selection, .cm-s-twilight .CodeMirror-line > span::-moz-selection, .cm-s-twilight .CodeMirror-line > span > span::-moz-selection {
  background: rgba(50, 50, 50, 0.99); }

/* line 1493, app/styles/main.scss */
.cm-s-twilight .CodeMirror-gutters {
  background: #222;
  border-right: 1px solid #aaa; }

/* line 1494, app/styles/main.scss */
.cm-s-twilight .CodeMirror-guttermarker {
  color: white; }

/* line 1495, app/styles/main.scss */
.cm-s-twilight .CodeMirror-guttermarker-subtle {
  color: #aaa; }

/* line 1496, app/styles/main.scss */
.cm-s-twilight .CodeMirror-linenumber {
  color: #aaa; }

/* line 1497, app/styles/main.scss */
.cm-s-twilight .CodeMirror-cursor {
  border-left: 1px solid white; }

/* line 1499, app/styles/main.scss */
.cm-s-twilight .cm-keyword {
  color: #f9ee98; }

/**/
/* line 1500, app/styles/main.scss */
.cm-s-twilight .cm-atom {
  color: #FC0; }

/* line 1501, app/styles/main.scss */
.cm-s-twilight .cm-number {
  color: #ca7841; }

/**/
/* line 1502, app/styles/main.scss */
.cm-s-twilight .cm-def {
  color: #8DA6CE; }

/* line 1503, app/styles/main.scss */
.cm-s-twilight span.cm-variable-2, .cm-s-twilight span.cm-tag {
  color: #607392; }

/**/
/* line 1504, app/styles/main.scss */
.cm-s-twilight span.cm-variable-3, .cm-s-twilight span.cm-def, .cm-s-twilight span.cm-type {
  color: #607392; }

/**/
/* line 1505, app/styles/main.scss */
.cm-s-twilight .cm-operator {
  color: #cda869; }

/**/
/* line 1506, app/styles/main.scss */
.cm-s-twilight .cm-comment {
  color: #777;
  font-style: italic;
  font-weight: normal; }

/**/
/* line 1507, app/styles/main.scss */
.cm-s-twilight .cm-string {
  color: #8f9d6a;
  font-style: italic; }

/**/
/* line 1508, app/styles/main.scss */
.cm-s-twilight .cm-string-2 {
  color: #bd6b18; }

/*?*/
/* line 1509, app/styles/main.scss */
.cm-s-twilight .cm-meta {
  background-color: #141414;
  color: #f7f7f7; }

/*?*/
/* line 1510, app/styles/main.scss */
.cm-s-twilight .cm-builtin {
  color: #cda869; }

/*?*/
/* line 1511, app/styles/main.scss */
.cm-s-twilight .cm-tag {
  color: #997643; }

/**/
/* line 1512, app/styles/main.scss */
.cm-s-twilight .cm-attribute {
  color: #d6bb6d; }

/*?*/
/* line 1513, app/styles/main.scss */
.cm-s-twilight .cm-header {
  color: #FF6400; }

/* line 1514, app/styles/main.scss */
.cm-s-twilight .cm-hr {
  color: #AEAEAE; }

/* line 1515, app/styles/main.scss */
.cm-s-twilight .cm-link {
  color: #ad9361;
  font-style: italic;
  text-decoration: none; }

/**/
/* line 1516, app/styles/main.scss */
.cm-s-twilight .cm-error {
  border-bottom: 1px solid red; }

/* line 1518, app/styles/main.scss */
.cm-s-twilight .CodeMirror-activeline-background {
  background: #27282E; }

/* line 1519, app/styles/main.scss */
.cm-s-twilight .CodeMirror-matchingbracket {
  outline: 1px solid grey;
  color: white !important; }

/**
 * dracula
 */
/*

    Name:       dracula
    Author:     Michael Kaminsky (http://github.com/mkaminsky11)

    Original dracula color scheme by Zeno Rocha (https://github.com/zenorocha/dracula-theme)

*/
/* line 1536, app/styles/main.scss */
.cm-s-dracula.CodeMirror, .cm-s-dracula .CodeMirror-gutters {
  background-color: #282a36 !important;
  color: #f8f8f2 !important;
  border: none; }

/* line 1541, app/styles/main.scss */
.cm-s-dracula .CodeMirror-gutters {
  color: #282a36; }

/* line 1542, app/styles/main.scss */
.cm-s-dracula .CodeMirror-cursor {
  border-left: solid thin #f8f8f0; }

/* line 1543, app/styles/main.scss */
.cm-s-dracula .CodeMirror-linenumber {
  color: #6D8A88; }

/* line 1544, app/styles/main.scss */
.cm-s-dracula .CodeMirror-selected {
  background: rgba(255, 255, 255, 0.1); }

/* line 1545, app/styles/main.scss */
.cm-s-dracula .CodeMirror-line::selection, .cm-s-dracula .CodeMirror-line > span::selection, .cm-s-dracula .CodeMirror-line > span > span::selection {
  background: rgba(255, 255, 255, 0.1); }

/* line 1546, app/styles/main.scss */
.cm-s-dracula .CodeMirror-line::-moz-selection, .cm-s-dracula .CodeMirror-line > span::-moz-selection, .cm-s-dracula .CodeMirror-line > span > span::-moz-selection {
  background: rgba(255, 255, 255, 0.1); }

/* line 1547, app/styles/main.scss */
.cm-s-dracula span.cm-comment {
  color: #6272a4; }

/* line 1548, app/styles/main.scss */
.cm-s-dracula span.cm-string, .cm-s-dracula span.cm-string-2 {
  color: #f1fa8c; }

/* line 1549, app/styles/main.scss */
.cm-s-dracula span.cm-number {
  color: #bd93f9; }

/* line 1550, app/styles/main.scss */
.cm-s-dracula span.cm-variable {
  color: #50fa7b; }

/* line 1551, app/styles/main.scss */
.cm-s-dracula span.cm-variable-2 {
  color: white; }

/* line 1552, app/styles/main.scss */
.cm-s-dracula span.cm-def {
  color: #50fa7b; }

/* line 1553, app/styles/main.scss */
.cm-s-dracula span.cm-operator {
  color: #ff79c6; }

/* line 1554, app/styles/main.scss */
.cm-s-dracula span.cm-keyword {
  color: #ff79c6; }

/* line 1555, app/styles/main.scss */
.cm-s-dracula span.cm-atom {
  color: #bd93f9; }

/* line 1556, app/styles/main.scss */
.cm-s-dracula span.cm-meta {
  color: #f8f8f2; }

/* line 1557, app/styles/main.scss */
.cm-s-dracula span.cm-tag {
  color: #ff79c6; }

/* line 1558, app/styles/main.scss */
.cm-s-dracula span.cm-attribute {
  color: #50fa7b; }

/* line 1559, app/styles/main.scss */
.cm-s-dracula span.cm-qualifier {
  color: #50fa7b; }

/* line 1560, app/styles/main.scss */
.cm-s-dracula span.cm-property {
  color: #66d9ef; }

/* line 1561, app/styles/main.scss */
.cm-s-dracula span.cm-builtin {
  color: #50fa7b; }

/* line 1562, app/styles/main.scss */
.cm-s-dracula span.cm-variable-3, .cm-s-dracula span.cm-type {
  color: #ffb86c; }

/* line 1564, app/styles/main.scss */
.cm-s-dracula .CodeMirror-activeline-background {
  background: rgba(255, 255, 255, 0.1); }

/* line 1565, app/styles/main.scss */
.cm-s-dracula .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important; }
